import React, {useEffect, useRef, useState} from "react"
import {connect, useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {wrapRequest} from "../../helpers/auth";
import AdditionalAnalyzesAPI from "../../api/AdditionalAnalyzesAPI";
import {LoadableComponent} from "../common/ui/LoadableComponent";
import NotificationAlert from "../common/ui/NotificationAlert";
import moment from "moment/moment";
import Pagination from "@vlsergey/react-bootstrap-pagination";
import {Modal} from "react-bootstrap";
import {formatMessage} from "../../helpers/methods";
import {Button} from "../common/ui/Button";
import {toast} from "react-toastify";

const AdditionalAnalyzes = ({profile}) => {
    const effectRan = useRef(false);
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const [analyzes, setAnalyzes] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [totalResults, setTotalResults] = useState(0);
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const [selectedId, setSelectedId] = useState()
    const [comments, setComments] = useState()
    const [errors, setErrors] = useState([])

    const getAnalyzes = (page, size) => {
        setLoading(true)
        return wrapRequest(dispatch, AdditionalAnalyzesAPI.getAll(page, size))
            .then(response => {
                setLoading(false)
                if (response.status === 200) {
                    setAnalyzes(response.data.content)
                    setTotalPages(response.data.totalPages)
                    setCurrentPage(response.data.number)
                    setTotalResults(response.data.totalElements)
                }
            })
    }

    const getFile = (id) => {
        return wrapRequest(dispatch, AdditionalAnalyzesAPI.getFile(id))
            .then(response => {
                if (response.status === 200) {
                    window.location.href = response.data.url
                }
            })
    }

    useEffect(() => {
        if (!effectRan.current) {
            getAnalyzes(0, 30)
        }
        return () => effectRan.current = true;
    }, []);

    const onPageChange = ({target}) => {
        const page = target.value;
        navigate({
            pathname: "/additional-analyzes",
            search: `?page=${page}`,
        });
        getAnalyzes(page, 30);
    }

    const getPatientGender = (patient) => {
        switch (patient.gender) {
            case "M":
                return "Homme"
            case "F":
                return "Femme"
            case "U":
                return "Non renseigné"
        }
    }

    const getStatus = (status) => {
        switch (status) {
            case "RECEIVED" :
                return <span className="badge badge-success fs-8">Nouveau</span>
            case "PROCESSED":
                return <span className="badge badge-secondary fs-8">Traité</span>
        }
    }

    const getAction = (status, id) => {
        switch (status) {
            case "RECEIVED" :
                return <button type="button" className="btn btn-secondary btn-sm"
                               onClick={() => {
                                   setSelectedId(id)
                                   setOpen(true)
                               }}>Valider</button>
            case "PROCESSED":
                return <span className="badge badge-secondary fs-8">Pris en charge</span>
        }
    }

    const onSend = (e) => {
        e.preventDefault()
        wrapRequest(dispatch, AdditionalAnalyzesAPI.accept(selectedId, comments))
            .then(response => {
                setLoading(false)
                if (response?.status === 204) {
                    toast.success("Analyses validées");
                    getAnalyzes(0,30)
                    setOpen(false)
                } else if (response?.status === 400) {
                    toast.error("Une erreur s'est produite");
                    setErrors(response.data.errors)
                }
            })
    }
    return <div className="card">
        <Modal show={open} size="lg">
            <Modal.Header
                closeButton={true}
                onHide={() => {
                    setSelectedId(undefined)
                    setOpen(false)
                }}
            >
                <Modal.Title>Valider la prise en charge de l'ordonnance</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="fs-4 mb-5 card-label fw-bold text-dark">Commentaires</div>
                <div className="mb-5 fv-row fv-plugins-icon-container">
                        <textarea id="content" name="content"
                                  onChange={(e) => setComments(e.target.value)}
                                  placeholder={"Commentaires"}
                                  value={comments}
                                  autoComplete="new-password" style={{resize: "none", minHeight: 150}}
                                  className="form-control form-control-lg form-control-solid"
                        />
                    {(errors?.filter(e => e.fieldName === "comments").shift()?.message) &&
                        <div
                            className="fv-plugins-message-container invalid-feedback">{t("errors:" + formatMessage(errors?.filter(e => e.fieldName === "comments").shift()?.message))}</div>
                    }
                </div>
                <div className="d-flex flex-end">
                    <Button onClick={() => setOpen(false)} loading={loading}
                            buttonClass="btn btn-secondary"
                            wrapperClass=""
                            label={"Fermer"}
                    />

                    <Button onClick={onSend} loading={loading}
                            wrapperClass="ms-5"
                            label={"Valider"}
                    />
                </div>
            </Modal.Body>
        </Modal>
        <div className="card-header border-0 pt-6">
            <div className="card-title">Liste des analyses complémentaires
                ({totalResults} trouvé{totalResults > 1 ? "s" : ""})
            </div>
        </div>
        <div className="card-body pt-0">
            <div className="dataTables_wrapper dt-bootstrap4 no-footer mt-8">
                <LoadableComponent loading={loading} component={<>{analyzes?.length === 0 ? <NotificationAlert
                        title={"Aucune analyse complémentaire demandée"}
                        message={"Vous trouverez ici toutes les analyses complémentaires envoyées ainsi que l'ordonnance du prescripteur"}
                    /> :
                    <div className="table-responsive">
                        <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
                            <thead className="border-gray-200 fs-5 fw-semibold bg-lighten">
                            <tr>
                                <th className="min-w-40px sorting">Date d'ajout</th>
                                <th className="min-w-125px sorting">Nom du patient</th>
                                <th className="min-w-75px sorting">Sexe</th>
                                <th className="min-w-60px sorting">Date de naissance</th>
                                <th className="min-w-125px sorting">Nom du prescripteur</th>
                                <th className="min-w-75px sorting">Status</th>
                                <th className="min-w-75px sorting">Ordonnance</th>
                                {(profile?.roles?.includes("ROLE_MANAGER") || profile?.roles?.includes("ROLE_ADMIN"))
                                    && <th className="min-w-75px sorting">Actions</th>
                                }
                            </tr>
                            </thead>
                            <tbody className="fw-semibold text-gray-600">
                            {analyzes?.map((analyze, idx) => <tr key={idx}
                                                                 className={idx % 2 === 0 ? "even" : "odd"}>
                                    <td>{moment.utc(analyze?.createdDate).local().format(t("common:dateTimeFormat"))}</td>
                                    <td>{analyze.patient.lastName} {analyze.patient.firstName}</td>
                                    <td>{getPatientGender(analyze.patient)}</td>
                                    <td>{moment.utc(analyze.patient?.birthDate).local().format(t("common:dateFormat"))}</td>
                                    <td>{analyze.doctorFullName}</td>
                                    <td>{getStatus(analyze.status)}</td>
                                    <td><i onClick={() => getFile(analyze.id)}
                                           className="cursor-pointer text-danger fa-solid fa-file-pdf fa-2x"></i></td>
                                    {(profile?.roles?.includes("ROLE_MANAGER") || profile?.roles?.includes("ROLE_ADMIN"))
                                        && <td>{getAction(analyze.status, analyze.id)}</td>
                                    }
                                </tr>
                            )}
                            </tbody>
                        </table>
                        <div className="mt-8">
                            <Pagination
                                aroundCurrent={2}
                                showFirstLast={true}
                                showPrevNext={true}
                                atBeginEnd={0}
                                onChange={onPageChange}
                                value={currentPage}
                                totalPages={totalPages}
                            /></div>
                    </div>}
                </>}/>
            </div>

        </div>
    </div>
}
const mapStateToProps = state => ({
    profile: state.profile
});

export default connect(mapStateToProps, null)(AdditionalAnalyzes);
