import * as types from "./actionTypes";
import sessionAPI from "../../api/SessionAPI";
import {wrapRequest} from "../../helpers/auth";

export function loginSuccess(tokens) {
    return {type: types.LOG_IN_SUCCESS, tokens};
}

export function loginBadCredentialsError(error) {
    return {type: types.LOG_IN_BAD_CREDENTIALS, error};
}

export function loginValidationError(error) {
    return {type: types.LOG_IN_VALIDATION_ERROR, error};
}

export function logout(cause) {
    return {type: types.LOG_OUT, cause};
}

export function loadAccountSuccess(profile) {
    return {type: types.LOAD_ACCOUNT_SUCCESS, profile};
}

export function startLoadingSuccess() {
    return {type: types.START_LOADING};
}

export function stopLoadingSuccess() {
    return {type: types.STOP_LOADING};
}

export function startLoading() {
    return function (dispatch) {
        dispatch(startLoadingSuccess());
    };
}

export function stopLoading() {
    return function (dispatch) {
        dispatch(stopLoadingSuccess());
    };
}

export function logInUser(credentials, t) {
    return function (dispatch) {
        dispatch(startLoading());
        return sessionAPI
            .login(credentials)
            .then((response) => {
                dispatch(stopLoading());
                if (response.status === 401) {
                    dispatch(loginBadCredentialsError({message: t(`errors:${response.data.message}`)}));
                }
                if (response.status === 400) {
                    dispatch(loginValidationError(response.data.errors));
                }
                if (response.status === 200) {
                    dispatch(
                        loginSuccess({
                            accessToken: response.data.accessToken,
                            refreshToken: response.data.refreshToken
                        })
                    ).then(() => {
                        return credentials;
                    });
                }
            })
            .catch((error) => {
                return error;
            });
    };
}

export function loadAccount() {
    return function (dispatch) {
        return wrapRequest(dispatch, sessionAPI.findMe())
            .then((response) => {
                dispatch(loadAccountSuccess(response?.status === 200
                    ? response?.data
                    : {}));
            })
    };
}
