import initialState from "./initialState";
import * as types from "../actions/actionTypes";

export default function profileReducer(state = initialState.profile, action) {
    switch (action.type) {
        case types.LOAD_ACCOUNT_SUCCESS:
            return action.profile
        default:
            return state
    }
}
