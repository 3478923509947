import axios from "axios";
import {requestHeaders, requestHeadersForUpload} from "../helpers/constants";

class DoctorAPI {
    static getDoctors(page, size) {
        return axios({
            method: 'get',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/doctors?page=${page}&size=${size}`,
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }
    static create(doctor) {
        return axios({
            method: 'post',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/doctors`,
            data: doctor
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }
    static update(doctor, id) {
        return axios({
            method: 'put',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/doctors/${id}`,
            data: doctor
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }

    static uploadSignature(file) {
        const data = new FormData()
        data.append('file', file)
        return axios({
            method: 'put',
            headers: requestHeadersForUpload(),
            url: `${process.env.API_BASE_URL}/doctors/signature`,
            data: data
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }

    static getSignature() {
        return axios({
            method: 'get',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/doctors/signature`
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }

    static get(id) {
        return axios({
            method: 'get',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/doctors/${id}`
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }
}
export default DoctorAPI
