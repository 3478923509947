import React, {useEffect, useRef, useState} from "react"
import {useDispatch} from "react-redux";
import {wrapRequest} from "../../helpers/auth";
import AdditionalAnalyzesAPI from "../../api/AdditionalAnalyzesAPI";
import NotificationAlert from "../common/ui/NotificationAlert";
import {LoadableComponent} from "../common/ui/LoadableComponent";
import moment from "moment";
import {useTranslation} from "react-i18next";
import {Button} from "../common/ui/Button";

const AdditionalAnalysisDetails = ({additionalAnalysisId, setOpen}) => {

    const {t} = useTranslation()

    const effectRan = useRef(false);
    const dispatch = useDispatch()
    const [additionalAnalysis, setAdditionalAnalysis] = useState({})
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (!effectRan.current) {
            if (additionalAnalysis) {
                setLoading(true)
                wrapRequest(dispatch, AdditionalAnalyzesAPI.get(additionalAnalysisId))
                    .then(response => {
                        setLoading(false)
                        if (response.status === 200) {
                            setAdditionalAnalysis(response.data)
                        }
                    })
            }
        }
        return () => effectRan.current = true;
    }, []);

    const getFile = (id) => {
        return wrapRequest(dispatch, AdditionalAnalyzesAPI.getFile(id))
            .then(response => {
                if (response.status === 200) {
                    window.location.href = response.data.url
                }
            })
    }

    return <><LoadableComponent loading={loading} component={additionalAnalysisId === undefined ? <NotificationAlert
        title={"Aucune analyse complémentaire disponible"}
        message={"Veuillez contacter le médecin prescripteur"}/> : <>
        <div className="d-flex justify-content-between flex-column flex-sm-row mb-8">
            <div><h4 className="fw-bolder text-gray-800 fs-2qx pe-5 pb-2">Ordonnance de biologie médicale</h4>
                <div className="fw-semibold fs-5">Etablie par le
                    Dr. {additionalAnalysis?.doctorFullName} le {moment.utc(additionalAnalysis?.createadDate).local().format(t("common:dateFormat"))}</div>
            </div>
            <div className="text-sm-end">
                <div className="text-sm-end fw-semibold fs-5 text-muted">
                    <a href="#" onClick={() => getFile(additionalAnalysisId)}
                       className="btn btn-icon-white btn-primary mb-5">
                        <i className="fa-solid fa-file-pdf fs-1"></i>Télécharger l'ordonnance</a>
                    <div>Patient: {additionalAnalysis?.patient?.lastName} {additionalAnalysis?.patient?.firstName}</div>
                    <div>Date de naissance
                        : {moment.utc(additionalAnalysis?.patient?.birthDate).local().format(t("common:dateFormat"))} Sexe
                        : {additionalAnalysis?.patient?.gender}</div>
                </div>
            </div>

        </div>

        <div className="border-bottom fs-6 fw-bold text-muted text-uppercase">
            <div className="min-w-175px pb-3">Symptômes</div>
        </div>
        <div className="d-flex align-items-center fw-bold text-gray-700 fs-5 pt-5">
            {additionalAnalysis?.symptoms?.map(s => s.label).join(", ")}
        </div>
        <div className="table-responsive border-bottom mb-5 mt-5">
            <table className="table">
                <thead>
                <tr className="border-bottom fs-6 fw-bold text-muted text-uppercase">
                    <th className="min-w-175px pb-5">Analyses complémentaires</th>
                </tr>
                </thead>
                <tbody>
                {additionalAnalysis?.analysisLines?.map(line => <tr key={line.code.value}
                                                                    className="fw-bold text-gray-700 fs-5 text-end">
                    <td className="d-flex align-items-center pt-5">
                        <i className="fa fa-genderless text-danger fs-1 me-2"></i>{line.label}
                    </td>
                </tr>)}
                </tbody>
            </table>
        </div>
    </>}/>
        <div className="d-flex flex-end">
            <Button onClick={() => setOpen(false)}
                    buttonClass="btn btn-secondary"
                    wrapperClass=""
                    label={"Fermer"}
            /></div>
    </>
}
export default AdditionalAnalysisDetails
