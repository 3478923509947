import axios from "axios";
import {requestHeaders} from "../helpers/constants";

class DeviceAPI {

    static create(device) {
        return axios({
            method: 'post',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/devices`,
            data: device
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }

    static update(device, id) {
        return axios({
            method: 'put',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/devices/${id}`,
            data: device
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }

    static get(id) {
        return axios({
            method: 'get',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/devices/${id}`
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }

    static remove(id) {
        return axios({
            method: 'delete',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/devices/${id}`
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }

    static getAll(page, size) {
        return axios({
            method: 'get',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/devices?page=${page}&size=${size}`
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }
}

export default DeviceAPI
