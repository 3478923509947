import axios from "axios";
import {requestHeaders, requestHeadersForUpload} from "../helpers/constants";

class RecordAPI {
    static getRecords(page, size, searchRequest) {
        let url = `${process.env.API_BASE_URL}/records?page=${page}&size=${size}`
        if (searchRequest?.organizationId) {
            url += `&organizationId=${searchRequest.organizationId}`
        }
        if (searchRequest?.laboratories && searchRequest?.laboratories?.length !== 0) {
            let laboratories = searchRequest?.laboratories?.join(",")
            url += `&laboratories=${laboratories}`
        }
        if (searchRequest?.alertStatuses && searchRequest?.alertStatuses?.length !== 0) {
            let alertStatuses = searchRequest?.alertStatuses?.join(",")
            url += `&alertStatuses=${alertStatuses}`
        }
        if (searchRequest?.readStatuses && searchRequest?.readStatuses?.length !== 0) {
            let readStatuses = searchRequest?.readStatuses?.join(",")
            url += `&readStatuses=${readStatuses}`
        }
        if (searchRequest?.supportStatuses && searchRequest?.supportStatuses?.length !== 0) {
            let supportStatuses = searchRequest?.supportStatuses?.join(",")
            url += `&supportStatuses=${supportStatuses}`
        }
        if (searchRequest?.biologicalValidationStatuses && searchRequest?.biologicalValidationStatuses?.length !== 0) {
            let biologicalValidationStatuses = searchRequest?.biologicalValidationStatuses?.join(",")
            url += `&biologicalValidationStatuses=${biologicalValidationStatuses}`
        }
        if (searchRequest?.patientOrDoctorSearch) {
            let patientOrDoctorSearch = searchRequest?.patientOrDoctorSearch
            url += `&patientOrDoctorSearch=${patientOrDoctorSearch}`
        }
        if (searchRequest?.startDatetime) {
            let startDatetime = searchRequest?.startDatetime
            url += `&startDatetime=${startDatetime}`
        }
        if (searchRequest?.endDatetime) {
            let endDatetime = searchRequest?.endDatetime
            url += `&endDatetime=${endDatetime}`
        }
        if (searchRequest?.sortBy) {
            let sortBy = searchRequest?.sortBy
            url += `&sortBy=${sortBy}`
        }
        if (searchRequest?.sortDirection) {
            let sortDirection = searchRequest?.sortDirection
            url += `&sortDirection=${sortDirection}`
        }

        return axios({
            method: 'get',
            headers: requestHeaders(),
            url: url,
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }

    static simulate(file, organizationId) {
        const formData = new FormData();
        formData.append("file", file, 'file');
        let url = `${process.env.API_BASE_URL}/records?organizationId=${organizationId}`
        return axios.post(url, formData, {
            headers: requestHeadersForUpload()
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }

    static createSupport(support, id) {
        return axios({
            method: 'post',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/records/${id}/support`,
            data: support
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }

    static validateResult(validationRequest, id) {
        return axios({
            method: 'put',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/records/${id}/validate`,
            data: validationRequest
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }

    static read(id) {
        return axios({
            method: 'put',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/records/${id}/read`
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }

    static assignPatient(id, patientId) {
        return axios({
            method: 'put',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/records/${id}/patient`,
            data: {patientId: patientId}
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }

    static get(id, organizationId) {
        let url = `${process.env.API_BASE_URL}/records/${id}`
        if (organizationId) {
            url += `?organizationId=${organizationId}`
        }
        return axios({
            method: 'get',
            headers: requestHeaders(),
            url: url
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }

    static getRecordCounts() {
        let url = `${process.env.API_BASE_URL}/records/counts`
        return axios({
            method: 'get',
            headers: requestHeaders(),
            url: url
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }



}

export default RecordAPI
