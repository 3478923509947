import {connect} from "react-redux";
import React from "react"
import OrganizationDetails from "./OrganizationDetails";

const SelfOrganization = ({profile}) => {
    return <OrganizationDetails id={profile.organizationId}/>
}

const mapStateToProps = state => ({
    profile: state.profile
});

export default connect(mapStateToProps, null)(SelfOrganization);
