import React, {useEffect, useRef, useState} from "react"
import NotificationAlert from "../common/ui/NotificationAlert";
import {useNavigate} from "react-router-dom";
import Pagination from "@vlsergey/react-bootstrap-pagination";
import {useDispatch} from "react-redux";
import {wrapRequest} from "../../helpers/auth";
import UserAPI from "../../api/UserAPI";
import {Modal} from "react-bootstrap";
import UserEdit from "./UserEdit";
import {useTranslation} from "react-i18next";
import {LoadableComponent} from "../common/ui/LoadableComponent";
import {normalizeText} from "../../helpers/textUtils";

export const Users = ({organizationId}) => {
    const {t} = useTranslation()
    const effectRan = useRef(false);
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)
    const [users, setUsers] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [totalResults, setTotalResults] = useState(0);
    const [selectedId, setSelectedId] = useState()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const onPageChange = ({target}) => {
        const page = target.value;
        navigate({
            pathname: `/organizations/${organizationId}?tab=users`,
            search: `&page=${page}`,
        });
        getUsers(page, 10);
    }

    const getUsers = (page, size) => {
        setLoading(true)
        return wrapRequest(dispatch, UserAPI.getUsers(page, size, organizationId))
            .then(response => {
                setLoading(false)
                if (response?.status === 200) {
                    setUsers(response.data.content)
                    setTotalPages(response.data.totalPages)
                    setCurrentPage(response.data.number)
                    setTotalResults(response.data.totalElements)
                }
            })
    }

    const onUpdate = (e) => {
        e.preventDefault()
        const id = e.target.id
        setSelectedId(id)
        setOpen(true)
    }

    useEffect(() => {
        if (!effectRan.current) {
            getUsers(0, 10)
        }
        return () => effectRan.current = true;
    }, []);

    const getRoleBadge = (user) => {
        switch (user?.roles[0]) {
            case 'ROLE_USER':
                return <span
                    className="badge badge-light-info fw-bolder fs-8 px-2 py-1 ms-2">{t("users:role_user")}</span>
            case 'ROLE_MANAGER':
                return <span
                    className="badge badge-light-danger fw-bolder fs-8 px-2 py-1 ms-2">{t("users:role_manager")}</span>
            case 'ROLE_ADMIN':
            case 'ROLE_SUPER_ADMIN':
                return <span
                    className="badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2">{t("users:role_admin")}</span>
        }
    }

    return <div className="card">
        <Modal show={open} size="lg">
            <Modal.Header
                closeButton={true}
                onHide={() => {
                    setSelectedId(undefined)
                    setOpen(false)
                }}
            >
                <Modal.Title>{selectedId ? "Mettre à jour un utilisateur" : "Ajouter un nouvel utilisateur"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <UserEdit setOpen={setOpen}
                          onSuccess={getUsers}
                          selectedId={selectedId}
                          organizationId={organizationId}
                />
            </Modal.Body>
        </Modal>
        <div className="card-header border-0 pt-6">
            <div className="card-title">Utilisateurs ({totalResults} trouvé{totalResults > 1 ? "s" : ""})</div>
            <div className="card-toolbar">
                <div className="d-flex justify-content-end" data-kt-customer-table-toolbar="base">
                    <button type="button" className="btn btn-primary"
                            onClick={(e) => {
                                e.preventDefault()
                                setSelectedId(undefined)
                                setOpen(true)
                            }}>Ajouter un nouvel utilisateur
                    </button>
                </div>
            </div>
        </div>

        <div className="card-body pt-0">
            <div id="kt_customers_table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer mt-8">
                <LoadableComponent loading={loading} component={<>{users?.length === 0 ? <NotificationAlert
                        title={"Aucun utilisateur trouvé"}
                        message={"Veuillez créer un utilisateur"}
                    /> :
                    <div className="table-responsive">
                        <table
                            className="table table-hover align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
                            <thead className="border-gray-200 fs-5 fw-semibold bg-lighten">
                            <tr>
                                <th width="20%" className="min-w-125px sorting">Nom</th>
                                <th width="20%" className="min-w-125px sorting">Email</th>
                                <th width="15%" className="min-w-125px sorting">Numéro de téléphone</th>
                                <th width="15%" className="min-w-125px sorting">Role</th>
                                <th width="30%" className="min-w-125px sorting">Poste</th>
                            </tr>
                            </thead>
                            <tbody className="fw-semibold text-gray-600">
                            {users?.map((user, idx) => <tr key={idx}
                                                           onClick={() => navigate(`/organizations/${organizationId}/users/${user.id}`)}
                                                           className={" cursor-pointer " + (idx % 2 === 0 ? "even" : "odd")}>
                                    <td>{normalizeText(user?.lastName, 50, "capitalize")} {normalizeText(user?.firstName, 50, "capitalize")}</td>
                                    <td>{user?.email}</td>
                                    <td>{user?.phoneNumber || "Non renseigné"}</td>
                                    <td>{getRoleBadge(user, t)}</td>
                                    <td>{normalizeText(user?.job, 50, "capitalize") || "Non renseigné"}</td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                        <Pagination
                            aroundCurrent={2}
                            showFirstLast={true}
                            showPrevNext={true}
                            atBeginEnd={0}
                            onChange={onPageChange}
                            value={currentPage}
                            totalPages={totalPages}
                        />
                    </div>}
                </>}/>
            </div>
        </div>
    </div>
}
