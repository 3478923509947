import axios from "axios";
import {requestHeaders} from "../helpers/constants";

class DoctorAssociationAPI {
    static getAssociations(page, size, organizationId) {
        return axios({
            method: 'get',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/organizations/${organizationId}/doctor-associations?page=${page}&size=${size}`,
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }

    static create(association, organizationId) {
        return axios({
            method: 'post',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/organizations/${organizationId}/doctor-associations`,
            data: association
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }

    static update(association, id, organizationId) {
        return axios({
            method: 'put',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/organizations/${organizationId}/doctor-associations/${id}`,
            data: association
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }

    static remove(id, organizationId) {
        return axios({
            method: 'delete',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/organizations/${organizationId}/doctor-associations/${id}`
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }

    static get(id, organizationId) {
        return axios({
            method: 'get',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/organizations/${organizationId}/doctor-associations/${id}`
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }
}

export default DoctorAssociationAPI
