import React from "react"
import FilteredResultPage from "./FilteredResultPage";

const Validations = ({}) => {
    return <FilteredResultPage searchRequest={{
        alertStatuses: ["URGENT", "ABNORMAL"],
        biologicalValidationStatuses: ["NOT_STARTED"]
    }}/>
}
export default Validations

