import axios from "axios";
import {requestHeaders} from "../helpers/constants";

class LogAPI {

    static getLogs(page, size, recordId) {
        let url = `${process.env.API_BASE_URL}/logs?page=${page}&size=${size}`
        if (recordId) {
            url += `&recordId=${recordId}`
        }
        return axios({
            method: 'get',
            headers: requestHeaders(),
            url: url,
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }

    static create(log) {
        return axios({
            method: 'post',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/logs`,
            data: log
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }


}

export default LogAPI
