import React, {useEffect, useRef, useState} from "react"
import {connect, useDispatch} from "react-redux";
import AdditionalAnalysisDetails from "./AdditionalAnalysisDetails";
import {wrapRequest} from "../../helpers/auth";
import AdditionalAnalyzesAPI from "../../api/AdditionalAnalyzesAPI";
import CustomSelect from "../common/ui/CustomSelect";
import {formatMessage} from "../../helpers/methods";
import OrganizationAPI from "../../api/OrganizationAPI";
import {Button} from "../common/ui/Button";
import {toast} from "react-toastify";
import NotificationAlert from "../common/ui/NotificationAlert";

const AdditionalAnalysisForm = ({
                                    profile,
                                    recordId,
                                    additionalAnalysisId,
                                    generated = false,
                                    hasPatient = false,
                                    setOpen
                                }) => {
    const effectRan = useRef(false);
    const dispatch = useDispatch()
    const [additionalAnalysis, setAdditionalAnalysis] = useState({comments: "Cher confrère je me permets de vous adresser ce patient pour des analyses complémentaires."})
    const [templates, setTemplates] = useState([])
    const [loading, setLoading] = useState(false)
    const [totalResults, setTotalResults] = useState(0);
    const [errors, setErrors] = useState([])
    const [selectedTemplate, setSelectedTemplate] = useState(false)
    const [organizations, setOrganizations] = useState([])

    const getTemplates = (page, size) => {
        setLoading(true)
        return wrapRequest(dispatch, AdditionalAnalyzesAPI.getTemplates(page, size))
            .then(response => {
                setLoading(false)
                if (response.status === 200) {
                    setTemplates(response.data.content.map(element => {
                        return {
                            value: element.id,
                            label: `${element.templateName}`,
                            fullElement: element
                        }
                    }))
                    setTotalResults(response.data.totalElements)
                }
            })
    }
    const loadElement = (value) => {
        const template = templates.filter(t => t.value === value)[0]
        setAdditionalAnalysis({
            ...additionalAnalysis,
            symptoms: template.fullElement.symptoms,
            analysisLines: template.fullElement.analysisLines
        })
        setSelectedTemplate(true)
    }

    const onRemoveSymptom = (code) => {
        let symptoms = additionalAnalysis.symptoms
        symptoms = symptoms.filter(e => e.code.value !== code)
        setAdditionalAnalysis({...additionalAnalysis, symptoms: symptoms})
    }

    const onRemoveAnalysisLines = (code) => {
        let lines = additionalAnalysis.analysisLines
        lines = lines.filter(e => e.code.value !== code)
        setAdditionalAnalysis({...additionalAnalysis, analysisLines: lines})
    }

    const onSend = (e) => {
        e.preventDefault()
        setLoading(true)
        return wrapRequest(dispatch, AdditionalAnalyzesAPI.create({...additionalAnalysis, recordId: recordId}))
            .then(response => {
                setLoading(false)
                if (response?.status === 201) {
                    toast.success("Analyses complémentaires envoyées");
                    setOpen(false)
                } else if (response?.status === 400) {
                    toast.error("Une erreur s'est produite");
                    setErrors(response.data.errors)
                }
            })
    }

    const loadOrganizations = () => {
        return wrapRequest(dispatch, OrganizationAPI.getOrganizations(0, 500))
            .then(response => {
                    if (response.status === 200) {
                        setOrganizations(response.data.content.map(element => {
                            return {
                                value: element.id,
                                label: `${element.name} `
                            }
                        }))
                    }
                }
            )
    }

    useEffect(() => {
        if (!effectRan.current
            && profile?.roles.includes("ROLE_DOCTOR")
            && !generated) {
            loadOrganizations()
            getTemplates(0, 100)
        }
        return () => effectRan.current = true;
    }, []);


    if (profile?.roles.includes("ROLE_DOCTOR") && !generated) {
        if (!profile.signature) {
            return <NotificationAlert
                title="La signature est obligatoire"
                level="warning"
                message={"Veuillez vous rendre dans le menu configuration pour ajouter une signature"}/>
        }
        if (!hasPatient) {
            return <NotificationAlert
                title="Aucun patient assigné"
                level="warning"
                message={"Veuillez assigner un patient à ce résultat"}/>
        }
        return <>
            <CustomSelect
                wrapperClass="col-lg-12 fv-plugins-icon-container mb-5"
                label="Sélectionner le destinataire"
                name="organizationId"
                labelClassName="required fs-4 card-label fw-bold text-dark mb-5"
                className="form-control form-control-solid"
                onChange={(data) => {
                    setAdditionalAnalysis({...additionalAnalysis, targetOrganizationId: data.value})
                }}
                options={organizations}
                isSearchable={true}
                isLoading={false}
                placeholder={"Veuillez sélectionner un client à qui adresser le patient"}
                value={organizations.filter((x) =>
                    additionalAnalysis.targetOrganizationId === x.value
                )}
                errors={errors}
            />
            <CustomSelect
                wrapperClass="col-lg-12 fv-plugins-icon-container mb-5"
                label="Choisissez un template d'analyses"
                name="template"
                labelClassName="fs-4 card-label fw-bold text-dark mb-5"
                className="form-control form-control-solid"
                onChange={(data) => {
                    loadElement(data.value)
                }}
                options={templates}
                isSearchable={true}
                isLoading={false}
                placeholder={"Choisissez un template d'analyses"}
                errors={errors}
            />
            {selectedTemplate &&
                <>
                    <div className="fs-4 mb-5 card-label fw-bold text-dark">Phrase d'introduction (optionnelle)</div>
                    <div className="mb-5 fv-row fv-plugins-icon-container">
                        <textarea id="content" name="content"
                                  onChange={(e) => setAdditionalAnalysis({
                                      ...additionalAnalysis,
                                      comments: e.target.value
                                  })}
                                  placeholder={"Commentaires"}
                                  value={additionalAnalysis.comments}
                                  autoComplete="new-password" style={{resize: "none", minHeight: 150}}
                                  className="form-control form-control-lg form-control-solid"
                        />
                        {(errors?.filter(e => e.fieldName === "comments").shift()?.message) &&
                            <div
                                className="fv-plugins-message-container invalid-feedback">{t("errors:" + formatMessage(errors?.filter(e => e.fieldName === "comments").shift()?.message))}</div>
                        }
                    </div>

                    <div className="fs-4 mb-5 card-label fw-bold text-dark">Liste des symptômes</div>

                    {additionalAnalysis?.symptoms?.map(symptom =>
                        <span key={symptom.code.value}
                              className="badge badge-lg badge-light-secondary me-3 mb-3 text-gray-700">
                            {symptom.label} (code {symptom.code.value})  <a href="#"
                                                                            onClick={() => onRemoveSymptom(symptom.code.value)}
                                                                            className="badge badge-light fw-bold my-2">×</a>
                        </span>
                    )}
                    <div className="fs-4 mt-5 mb-5 card-label fw-bold text-dark">Liste des analyses complémentaires à
                        effectuer (référenciel NABM)
                    </div>
                    {additionalAnalysis?.analysisLines?.map(analysisLine =>
                        <span key={analysisLine.code.value}
                              className="badge badge-lg badge-light-secondary me-3 mb-3 text-gray-700">
                            {analysisLine.label} (code {analysisLine.code.value})  <a href="#"
                                                                                      onClick={() => onRemoveSymptom(analysisLine.code.value)}
                                                                                      className="badge badge-light fw-bold my-2">×</a>
                        </span>
                    )}
                    <div className="fs-4 mb-5 mt-5 card-label fw-bold text-dark">Saisie libre d'analyses non présentes
                        ci-dessus.
                    </div>
                    <div className="mb-10 fv-row fv-plugins-icon-container">
                        <textarea id="content" name="content"
                                  onChange={(e) => setAdditionalAnalysis({
                                      ...additionalAnalysis,
                                      lines: e.target.value
                                  })}
                                  placeholder={"Autres analyses à effectuer"}
                                  value={additionalAnalysis.lines}
                                  autoComplete="new-password" style={{resize: "none", minHeight: 150}}
                                  className="form-control form-control-lg form-control-solid"
                        />
                        {(errors?.filter(e => e.fieldName === "lines").shift()?.message) &&
                            <div
                                className="fv-plugins-message-container invalid-feedback">{t("errors:" + formatMessage(errors?.filter(e => e.fieldName === "lines").shift()?.message))}</div>
                        }
                    </div>
                </>
            }
            <div className="d-flex flex-end">
                {errors?.length > 0 &&
                    <span className="text-danger align-self-center me-10 fs-5">Une erreur s'est produite. Veuillez vérifier le formulaire.</span>}
                <Button onClick={() => setOpen(false)} loading={loading}
                        buttonClass="btn btn-secondary"
                        wrapperClass=""
                        label={"Fermer"}
                />
                {selectedTemplate &&
                    <Button onClick={onSend} loading={loading}
                            wrapperClass="ms-5"
                            label={"Envoyer les analyses complémentaires"}
                    />}
            </div>
        </>
    }
    return <AdditionalAnalysisDetails additionalAnalysisId={additionalAnalysisId} setOpen={setOpen}/>
}

const mapStateToProps = state => ({
    profile: state.profile
});

export default connect(mapStateToProps, null)(AdditionalAnalysisForm);
