import * as types from "../actions/actionTypes";
import initialState from "./initialState";
import {toast} from "react-toastify";
import {useTransition} from "react";
import {useTranslation} from "react-i18next";

export default function errorReducer(state = initialState.errors, action) {
    switch (action.type) {
        case types.RESET_ERRORS:
            return initialState.errors
        case types.LOG_IN_VALIDATION_ERROR:
            return action.error
        case types.LOG_IN_BAD_CREDENTIALS:
            toast.error(action.error.message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            return [
                Object.assign({}, action.error)
            ]
        default:
            return state
    }
}
