import React, {useEffect, useRef, useState} from "react"
import {Link, useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {wrapRequest} from "../../../helpers/auth";
import LaboratoryAPI from "../../../api/LaboratoryAPI";
import ContactAPI from "../../../api/ContactAPI";
import {LoadableComponent} from "../../common/ui/LoadableComponent";
import NotificationAlert from "../../common/ui/NotificationAlert";
import Pagination from "@vlsergey/react-bootstrap-pagination";
import {Modal} from "react-bootstrap";
import ContactModal from "./ContactModal";
import Swal from "sweetalert2";
import LabelAPI from "../../../api/LabelAPI";
import {toast} from "react-toastify";

const Contacts = ({organizationId, laboratoryId}) => {
    const [loading, setLoading] = useState(false)
    const effectRan = useRef(false);
    const dispatch = useDispatch()
    const params = useParams()
    const definitiveLaboratoryId = laboratoryId ? laboratoryId : params.laboratoryId
    const [laboratory, setLaboratory] = useState({})
    const [contacts, setContacts] = useState([])
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [totalResults, setTotalResults] = useState(0);
    const [open, setOpen] = useState(false)
    const [selectedId, setSelectedId] = useState()


    const getLaboratory = () => {
        return wrapRequest(dispatch, LaboratoryAPI.get(definitiveLaboratoryId))
            .then(response => {
                if (response?.status === 200) {
                    setLaboratory(response.data)
                    return response
                }
            })
    }

    const getContacts = () => {
        return wrapRequest(dispatch, ContactAPI.getContacts(0, 10, definitiveLaboratoryId))
            .then(response => {
                if (response?.status === 200) {
                    setContacts(response.data.content)
                    setTotalPages(response.data.totalPages)
                    setCurrentPage(response.data.number)
                    setTotalResults(response.data.totalElements)
                    return response
                }
            })
    }

    const onUpdate = (e) => {
        e.preventDefault()
        const id = e.target.id
        setSelectedId(id)
        setOpen(true)
    }

    const onDelete = (e) => {
        e.preventDefault()
        const id = e.target.id
        Swal.fire({
            title: "Voulez-vous supprimer ce contact?",
            text: "Attention ! Cette action est irréversible",
            showCancelButton: true,
            confirmButtonText: "Oui",
            cancelButtonText: `Non`,
            icon: "warning"
        }).then((result) => {
            if (result.isConfirmed) {
                return wrapRequest(dispatch, ContactAPI.remove(definitiveLaboratoryId, id))
                    .then(response => {
                        if (response.status === 204) {
                            toast.success("Contact supprimé")
                            getContacts(0, 10)
                        }
                    })
            }
        });
    }

    useEffect(() => {
        if (!effectRan.current) {
            setLoading(true)
            getLaboratory()
                .then(() => getContacts())
                .finally(() => {
                    setLoading(false)
                })
        }
        return () => effectRan.current = true;
    }, []);

    const onPageChange = ({target}) => {
        const page = target.value;
        getContacts(page, 10);
    }


    return <div className="card">
        <Modal show={open} size="lg">
            <Modal.Header
                closeButton={true}
                onHide={() => {
                    setSelectedId(undefined)
                    setOpen(false)
                }}
            >
                <Modal.Title>{selectedId ? "Mettre à jour un contact" : "Ajouter un nouveau contact"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ContactModal laboratoryId={definitiveLaboratoryId} onSuccess={getContacts} setOpen={setOpen}
                                 selectedId={selectedId}/>
            </Modal.Body>
        </Modal>
        <div className="card-header border-0 pt-6">
            <div className="card-title">Contacts du
                laboratoire {laboratory?.name} ({totalResults} trouvé{totalResults > 1 ? "s" : ""})
            </div>
            <div className="card-toolbar">
                <div className="d-flex justify-content-end" data-kt-customer-table-toolbar="base">
                    <button type="button" className="btn btn-primary"
                            onClick={(e) => {
                                e.preventDefault()
                                setSelectedId(undefined)
                                setOpen(true)
                            }}>Ajouter un nouveau contact
                    </button>
                </div>
            </div>
        </div>
        <div className="card-body pt-0">
            <div id="kt_customers_table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer mt-8">
                <LoadableComponent loading={loading} component={<>{contacts?.length === 0 ? <NotificationAlert
                        title={"Aucun contact trouvé"}
                        message={"Veuillez créer un nouveau contact"}
                    /> :
                    <div className="table-responsive">
                        <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
                            <thead className="border-gray-200 fs-5 fw-semibold bg-lighten">
                            <tr>
                                <th className="min-w-125px sorting">Nom</th>
                                <th className="min-w-125px sorting">Prénom</th>
                                <th className="min-w-125px sorting">Email</th>
                                <th className="min-w-125px sorting">Numéro de téléphone</th>
                                <th className="min-w-125px sorting">Poste</th>
                                <th className="min-w-125px sorting">Actions</th>
                            </tr>
                            </thead>
                            <tbody className="fw-semibold text-gray-600">
                            {contacts?.map((contact, idx) => <tr key={idx}
                                                                 className={idx % 2 === 0 ? "even" : "odd"}>
                                    <td>{contact?.firstName || "Non renseigné"}</td>
                                    <td>{contact?.lastName || "Non renseigné"}</td>
                                    <td>{contact?.email || "Non renseigné"}</td>
                                    <td>{contact?.phoneNumber || "Non renseigné"}</td>
                                    <td>{contact?.job || "Non renseigné"}</td>
                                    <td>
                                        <button
                                            id={contact.id}
                                            onClick={onUpdate}
                                            className="btn btn-icon btn-bg-light btn-sm me-1">
                                        <span className="svg-icon svg-icon-3" id={contact.id}>
																			<i className="fa-solid fa-pen"
                                                                               id={contact.id}></i>
                                        </span></button>
                                        <button
                                            id={contact.id}
                                            onClick={onDelete} className="btn btn-icon btn-bg-light btn-sm me-1">
                                        <span className="svg-icon svg-icon-3" id={contact.id}>
																			<i className="fa-solid fa-trash"
                                                                               id={contact.id}
                                                                            ></i>
                                        </span>
                                        </button>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                        <Pagination
                            aroundCurrent={2}
                            showFirstLast={true}
                            showPrevNext={true}
                            atBeginEnd={0}
                            onChange={onPageChange}
                            value={currentPage}
                            totalPages={totalPages}
                        />
                    </div>}
                </>}/>
            </div>
        </div>

    </div>
}

export default Contacts
