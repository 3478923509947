import React, {useState} from "react"
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {Users} from "../users/Users";
import AlertConfigurations from "../alertConfigurations/AlertConfigurations";
import Subscriptions from "../subscriptions/Subscriptions";
import DoctorAssociation from "../doctors/DoctorAssociation";
import {Integrations} from "../integrations/Integrations";
import {connect} from "react-redux";
import {OrganizationEdit} from "./OrganizationEdit";
import {Laboratories} from "../laboratories/Laboratories";

const OrganizationDetails = ({profile, id}) => {
    const params = useParams();
    const definitiveId = id ? id : params.id
    const [searchParams, setSearchParams] = useSearchParams();
    const [currentTab, setCurrentTab] = useState(searchParams.get("tab") || "edition");
    const navigate = useNavigate()
    const getCurrentTab = () => {
        switch (currentTab) {
            case "users":
                return <Users organizationId={definitiveId}/>
            case "doctors":
                return <DoctorAssociation organizationId={definitiveId}/>
            case "config":
                return <AlertConfigurations organizationId={definitiveId}/>
            case "subscription":
                return <Subscriptions organizationId={definitiveId}/>
            case "integrations":
                return <Integrations organizationId={definitiveId}/>
            case "labs":
                return <Laboratories organizationId={definitiveId}/>
            case "edition":
            default:
                return <div className="card">
                    <div className="card-header border-0 pt-6">
                        <div className="card-title">Coordonnées</div>
                    </div>
                    <div className="card-body pt-0">
                        <OrganizationEdit selectedId={definitiveId}
                                          setOpen={() => {
                                          }}
                                          onSuccess={()=> {}}
                        />
                    </div>
                </div>
        }
    }
    const selectTab = (value) => {
        setCurrentTab(value)
        navigate({
            pathname: `/organizations/${definitiveId}`,
            search: `?tab=${value}`,
        });
    }
    return <>
        <div className="card mb-5">
            <div className="card-body pt-0 pb-0">
                <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
                    <li className="nav-item mt-2 cursor-pointer">
                        <a className={`nav-link text-active-primary ms-0 me-10 py-5 ${currentTab === "edition" ? "active" : ""}`}
                           onClick={e => selectTab("edition")}
                        >Coordonnées</a>
                    </li>
                    <li className="nav-item mt-2 cursor-pointer">
                        <a className={`nav-link text-active-primary ms-0 me-10 py-5 ${currentTab === "labs" ? "active" : ""}`}
                           onClick={e => selectTab("labs")}
                        >Sites</a>
                    </li>
                    <li className="nav-item mt-2 cursor-pointer">
                        <a className={`nav-link text-active-primary ms-0 me-10 py-5 ${currentTab === "users" ? "active" : ""}`}
                           onClick={e => selectTab("users")}
                        >Utilisateurs</a>
                    </li>
                    <li className="nav-item mt-2 cursor-pointer">
                        <a className={`nav-link text-active-primary ms-0 me-10 py-5 ${currentTab === "config" ? "active" : ""}`}
                           onClick={e => selectTab("config")}
                        >Paramétrage des analyses</a>
                    </li>
                    <li className="nav-item mt-2 cursor-pointer">
                        <a className={`nav-link text-active-primary ms-0 me-10 py-5 ${currentTab === "doctors" ? "active" : ""}`}
                           onClick={e => selectTab("doctors")}
                        >Prescripteurs</a>
                    </li>
                    <li className="nav-item mt-2 cursor-pointer">
                        <a className={`nav-link text-active-primary ms-0 me-10 py-5 ${currentTab === "integrations" ? "active" : ""}`}
                           onClick={e => selectTab("integrations")}
                        >Intégrations</a>
                    </li>
                    <li className="nav-item mt-2 cursor-pointer">
                        <a className={`nav-link text-active-primary ms-0 me-10 py-5 ${currentTab === "subscription" ? "active" : ""}`}
                           onClick={e => selectTab("subscription")}
                        >Abonnement</a>
                    </li>
                </ul>
            </div>


        </div>
        {getCurrentTab()}
    </>
}

const mapStateToProps = state => ({
    profile: state.profile
});

export default connect(mapStateToProps, null)(OrganizationDetails);
