import React, {useState} from "react"
import {connect, useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {wrapRequest} from "../../helpers/auth";
import RecordAPI from "../../api/RecordAPI";
import {Modal} from "react-bootstrap";
import {ImportRecordModal} from "./ImportRecordModal";
import $ from "jquery"
import {RecordTable} from "./RecordTable";
import ResultNavbar from "./ResultNavbar";

const Records = ({profile}) => {
    const dispatch = useDispatch()
    const [importModalOpen, setImportModalOpen] = useState(false)
    const [filterOpen, setFilterOpen] = useState(false)
    const [records, setRecords] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [totalResults, setTotalResults] = useState(0);
    const [loading, setLoading] = useState(false)
    const [currentTab, setCurrentTab] = useState("all")

    const [patientOrDoctorSearch, setPatientOrDoctorSearch] = useState(undefined)

    const navigate = useNavigate()
    const onPageChange = ({target}) => {
        const page = target.value;
        navigate({
            pathname: `/records`,
            search: `?tab=${currentTab}&page=${page}`,
        });
        getRecords(page, 10);
    }

    function getFilters() {
        switch (currentTab) {
            case "unread":
                return {
                    readStatuses: ["UNREAD"],
                    supportStatuses: ["PENDING"]
                }
            case "to_validate":
                if (profile?.roles?.includes("ROLE_DOCTOR")) {
                    return {
                        alertStatuses: ["URGENT", "ABNORMAL"],
                        biologicalValidationStatuses: ["IN_PROGRESS"]
                    }
                }
                return {
                    alertStatuses: ["URGENT", "ABNORMAL"],
                    biologicalValidationStatuses: ["NOT_STARTED"]
                }
            case "all":
            default:
                return localStorage.getItem("search") ? JSON.parse(localStorage.getItem("search")) : {}
        }
    }


    const getRecords = (page, size, withLoading = true) => {
        if (withLoading) {
            setLoading(true)
        }
        let searchRequest = getFilters(currentTab)
        return wrapRequest(dispatch, RecordAPI.getRecords(page, size, {
            ...searchRequest,
            patientOrDoctorSearch: patientOrDoctorSearch ? patientOrDoctorSearch : $("#patientOrDoctorSearch").val()
        }))
            .then(response => {
                setLoading(false)
                if (response?.status === 200) {
                    setRecords(response.data.content)
                    setTotalPages(response.data.totalPages)
                    setCurrentPage(response.data.number)
                    setTotalResults(response.data.totalElements)
                }
            })
    }

    const selectTab = (value) => {
        setCurrentTab(value)
        navigate({
            pathname: `/records`,
            search: `?tab=${value}`,
        });
    }

    return <>
        <ResultNavbar currentTab={currentTab} selectTab={selectTab}
                      profile={profile} getRecords={getRecords}
                      setPatientOrDoctorSearch={setPatientOrDoctorSearch}
                      filterOpen={filterOpen} setFilterOpen={setFilterOpen}
                      setImportModalOpen={setImportModalOpen}
                      totalResults={totalResults}/>
        <div className="card">
            <Modal show={importModalOpen} size="lg">
                <Modal.Header
                    closeButton={true}
                    onHide={() => {
                        setImportModalOpen(false)
                    }}
                >
                    <Modal.Title>{"Importer un fichier de simulation"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ImportRecordModal onSuccess={getRecords} setOpen={setImportModalOpen}/>
                </Modal.Body>
            </Modal>
            <RecordTable profile={profile}
                         currentPage={currentPage}
                         totalPages={totalPages}
                         records={records}
                         loading={loading}
                         onPageChange={onPageChange}
                         getRecords={getRecords}
                         currentTab={currentTab}/>
        </div>
    </>
}
const mapStateToProps = state => ({
    profile: state.profile
});

export default connect(mapStateToProps, null)(Records);
