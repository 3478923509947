import axios from "axios";
import {requestHeaders} from "../helpers/constants";

class LabelAPI {
    static create(label) {
        return axios({
            method: 'post',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/labels`,
            data: label
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }

    static update(label, id) {
        return axios({
            method: 'put',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/labels/${id}`,
            data: label
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }

    static remove(id) {
        return axios({
            method: 'delete',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/labels/${id}`,
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }

    static get(id) {
        return axios({
            method: 'get',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/labels/${id}`,
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }

    static getLabels(type, page, size) {
        return axios({
            method: 'get',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/labels?type=${type}&page=${page}&size=${size}`,
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }



}
export default LabelAPI
