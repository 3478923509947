import {normalizeText} from "../../helpers/textUtils";
import React, {useState} from "react";
import {Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {call} from "../../helpers/methods";
import {useDispatch} from "react-redux";
import moment from "moment";
import PatientAssignation from "./PatientAssignation";

export const PatientCell = ({patient, record, getRecords}) => {
    const {t} = useTranslation()
    const [openDetails, setOpenDetails] = useState(false)
    const [openEdit, setOpenEdit] = useState(false)
    const dispatch = useDispatch()

    const contentCell = () => {
        if (patient) {
            return <td onClick={() => setOpenDetails(true)}>
                <div className="btn btn-outline btn-outline-dashed btn-outline-dark w-100">
                    {normalizeText(patient?.lastName, 15, "capitalize")} {normalizeText(patient?.firstName, 15, "capitalize")} ({patient?.gender})
                </div>
            </td>
        } else {
            return <td onClick={() => setOpenEdit(true)}>
                <div className="btn btn-outline btn-outline-dashed btn-outline-dark w-100"><><i
                    className="fas fa-plus-circle fs-2 ms-2 text-success"></i> Associer un patient
                </>
                </div>
            </td>
        }
    }

    return <>
        <Modal show={openDetails} size="md">
            <Modal.Header
                closeButton={true}
                onHide={() => {
                    setOpenDetails(false)
                }}
            >
                <Modal.Title>{`Patient : ${normalizeText(patient?.lastName, 30, "capitalize")} ${normalizeText(patient?.firstName, 30, "capitalize")}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <div className="fs-6">
                        <div className="d-flex flex-stack">
                            <div>
                                <div className="fw-bold">Date de naissance</div>
                                <div
                                    className="text-gray-600">{moment.utc(patient?.birthDate).local().format(t("common:dateFormat"))}</div>
                            </div>
                            <div><a href="#" onClick={() => call(dispatch, record, patient?.phoneNumber)}
                                    className="btn btn-light-primary btn-sm">
                                <span className="svg-icon svg-icon-3"><i className="fa-solid fa-phone"></i></span>
                                Appeler le {patient?.phoneNumber}</a></div>
                        </div>
                        <div className="fw-bold mt-5">Adresse</div>
                        <div className="text-gray-600">{patient?.address}</div>
                        <div className="fw-bold mt-5">Genre</div>
                        <div className="text-gray-600">{patient?.gender}</div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
        <Modal show={openEdit} size="lg">
            <Modal.Header
                closeButton={true}
                onHide={() => {
                    setOpenEdit(false)
                }}
            >
                <Modal.Title>Affecter le résultat au patient</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <PatientAssignation recordId={record?.id} getRecords={getRecords}/>
            </Modal.Body>
        </Modal>

        {contentCell()}


    </>
}
