import React, {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {wrapRequest} from "../../helpers/auth";
import {toast} from "react-toastify";
import DoctorAssociationAPI from "../../api/DoctorAssociationAPI";
import DoctorAPI from "../../api/DoctorAPI";
import CustomSelect from "../common/ui/CustomSelect";
import {Input} from "../common/ui/Input";
import {Button} from "../common/ui/Button";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import {useNavigate} from "react-router-dom";

export const DoctorAssociationEdit = ({selectedId, setOpen, onSuccess, organizationId, setDoctorFullName}) => {
    const effectRan = useRef(false);
    const dispatch = useDispatch()
    const [association, setAssociation] = useState({})
    const [loading] = useState(false)
    const [errors, setErrors] = useState([])
    const [doctors, setDoctors] = useState([])
    const navigate = useNavigate()

    const loadOptions = () => {
        wrapRequest(dispatch, DoctorAPI.getDoctors(0, 500))
            .then(response => {
                    if (response.status === 200) {
                        setDoctors(response.data.content.map(element => {
                            return {
                                value: element.id,
                                label: `${element.lastName} ${element.firstName} : ${element.rppsNumber}`
                            }
                        }))
                    }
                }
            )
    }
    const onSend = (e) => {
        e.preventDefault()
        return wrapRequest(dispatch, selectedId ?
            DoctorAssociationAPI.update(association, selectedId, organizationId) :
            DoctorAssociationAPI.create(association, organizationId))
            .then(response => {
                if (response?.status === 201) {
                    setOpen(false)
                    toast.success("Nouvelle association créée");
                    onSuccess(0, 10)
                }
                if (response?.status === 204) {
                    setOpen(false)
                    toast.success("Nouvelle mis à jour");
                    onSuccess(0, 10)
                }
                else if (response?.status === 409) {
                    setOpen(false)
                    toast.warn("Une association avec ce prescripteur existe déjà");
                    onSuccess(0, 10)
                } else if (response?.status === 400) {
                    setErrors(response.data.errors)
                } else {
                    setOpen(false)
                }
            })
    }

    const getAssociationToUpdate = async () => {
        return wrapRequest(dispatch, DoctorAssociationAPI.get(selectedId, organizationId))
    }

    useEffect(() => {
        if (!effectRan.current) {
            loadOptions();
            if (selectedId) {
                getAssociationToUpdate().then(response => {
                    if (response.status === 200) {
                        setAssociation({
                            ...association,
                            doctorId: response.data.doctorId,
                            internalCode: response.data.internalCode
                        })
                        setDoctorFullName(response.data.doctorFullName)
                    }
                })
            }
        }
        return () => effectRan.current = true;
    }, []);

    const onDelete = (e) => {
        e.preventDefault()
        Swal.fire({
            icon: "warning",
            title: "Voulez-vous supprimer ce partenaire prescripteur?",
            text: "Attention ! Cette action est irréversible",
            showCancelButton: true,
            confirmButtonText: "Oui",
            cancelButtonText: `Non`
        }).then((result) => {
            if (result.isConfirmed) {
                return wrapRequest(dispatch, DoctorAssociationAPI.remove(selectedId, organizationId))
                    .then(response => {
                        if (response.status === 204) {
                            toast.success("Partenaire prescripteur supprimé")
                            navigate(`/organizations/${organizationId}?tab=doctors`)
                        }
                    })
            }
        });
    }

    return <>
        <div className="row">
        <CustomSelect
            disabled={selectedId !== undefined}
            wrapperClass="col-lg-6 mb-7 fv-plugins-icon-container"
            label="Prescripteur"
            name="doctorId"
            labelClassName="required fs-6 fw-semibold mb-2"
            className="form-control form-control-solid"
            onChange={(data) => setAssociation({...association, doctorId: data.value})}
            options={doctors}
            isSearchable={true}
            isLoading={false}
            placeholder={"Veuillez sélectionner un prescripteur"}
            value={doctors.filter((x) =>
                association.doctorId === x.value
            )}
            errors={errors}
        />
        <Input
            wrapperClass="col-lg-6 mb-7 fv-plugins-icon-container"
            label="Code interne"
            labelClassName="fs-6 fw-semibold mb-2"
            className="form-control form-control-solid"
            name="internalCode"
            value={association?.internalCode || ""}
            onChange={(e) => setAssociation({...association, internalCode: e.target.value})}
            errors={errors}
        /></div>
        <div className="d-flex flex-end">
            {selectedId !== undefined && <Button onClick={onDelete} loading={loading}
                    wrapperClass="me-5"
                    buttonClass="btn btn-danger"
                    label={"Supprimer"}
            />}
            <Button onClick={onSend} loading={loading}
                    wrapperClass=""
                    label={"Enregistrer"}
            />
        </div>
    </>

}

DoctorAssociationEdit.propTypes = {
    selectedId: PropTypes.string,
    setOpen : PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired ,
    organizationId: PropTypes.string.isRequired
};
