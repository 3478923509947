import React, {useEffect, useRef, useState} from "react"
import PropTypes from "prop-types";
import {wrapRequest} from "../../helpers/auth";
import {toast} from "react-toastify";
import {useDispatch} from "react-redux";
import WebhookAPI from "../../api/WebhookAPI";
import {Input} from "../common/ui/Input";
import {Checkbox} from "../common/ui/Checkbox";
import {Button} from "../common/ui/Button";

const WebhookModal = ({onSuccess, setOpen, selectedId, organizationId}) => {
    const effectRan = useRef(false);
    const [webhook, setWebhook] = useState({enabled: true})
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState([])
    const getWebhookToUpdate = async () => {
        return wrapRequest(dispatch, WebhookAPI.get(selectedId))
    }
    const onSend = (e) => {
        setLoading(true)
        e.preventDefault()
        return wrapRequest(dispatch, selectedId ?
            WebhookAPI.update(webhook, selectedId) :
            WebhookAPI.create({...webhook, organizationId: organizationId}))
            .then(response => {
                setLoading(false)
                if (response?.status === 201) {
                    setOpen(false)
                    toast.success("Nouveau webhook créé");
                    onSuccess(0, 10)
                } else if (response?.status === 204) {
                    setOpen(false)
                    toast.success("Webhook mis à jour");
                    onSuccess(0, 10)
                } else if (response?.status === 400) {
                    setErrors(response.data.errors)
                } else {
                    setOpen(false)
                }
            })
    }

    useEffect(() => {
        if (!effectRan.current) {
            if (selectedId) {
                getWebhookToUpdate().then(response => {
                    if (response?.status === 200) {
                        setWebhook({
                            ...webhook,
                            email: response.data.email,
                            url: response.data.url,
                            enabled: response.data.enabled
                        })
                    }
                })
            }
        }
        return () => effectRan.current = true;
    }, []);

    return <>
        <Input
            wrapperClass="fv-row mb-7 fv-plugins-icon-container"
            label="Url à appeler"
            labelClassName="required fs-6 fw-semibold mb-2"
            className="form-control form-control-solid"
            name="url"
            value={webhook.url || ""}
            onChange={(e) => setWebhook({...webhook, url: e.target.value})}
            errors={errors}
        />
        <Input
            wrapperClass="fv-row mb-7 fv-plugins-icon-container"
            label="Email en cas d'erreur"
            labelClassName="fs-6 fw-semibold mb-2"
            className="form-control form-control-solid"
            name="email"
            value={webhook.email || ""}
            onChange={(e) => setWebhook({...webhook, email: e.target.value})}
            errors={errors}
        />
        <Checkbox
            label={"Activation"}
            checked={webhook?.enabled}
            onChange={() => setWebhook({...webhook, enabled: !webhook.enabled})}
        />
        <div className="d-flex flex-end">
            <Button onClick={onSend} loading={loading}
                    wrapperClass=""
                    label={"Enregistrer"}
            />
        </div>
    </>
}

WebhookModal.propTypes = {
    setOpen : PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    selectedId: PropTypes.string
};

export default WebhookModal
