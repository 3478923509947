import React, {useEffect, useState} from "react"
import {Helmet, HelmetProvider} from "react-helmet-async";
import {formatMessage} from "../../helpers/methods";
import {useTranslation} from "react-i18next";
import {useSearchParams} from "react-router-dom";
import SessionAPI from "../../api/SessionAPI";
import Swal from "sweetalert2";
import {toast} from "react-toastify";

const InitDoctorAccount = ({}) => {

    const {t} = useTranslation()
    const [ta, setTa] = useState()
    const [searchParams] = useSearchParams()
    const uuid = searchParams.get("ta")
    const helmetContext = {};
    const [errors, setErrors] = useState([])
    const [loading, setLoading] = useState(false)


    const onSend = (e) => {
        e.preventDefault()
        Swal.fire({
            icon: 'warning',
            title: "Acceptez-vous les conditions générales d'utilisation de la plateforme ?",
            text: "Vous serez automatiquement redirigé vers le dashboard.",
            showCancelButton: true,
            confirmButtonText: "Oui",
            cancelButtonText: `Non`
        }).then((result) => {
            if (result.isConfirmed) {
                setLoading(true)
                SessionAPI.register(ta).then(response => {
                    if (response?.status !== 200) {
                        setLoading(false)
                        setErrors(response.data.errors)
                        if(response?.status === 409){
                            toast.error("Email déjà utilisé par un compte existant");
                        }
                    } else {
                        window.location.href = `/auth/oauth2/redirect?access_token=${response.data.accessToken}&refresh_token=${response.data.refreshToken}`
                    }

                })
            }
        })
    }

    useEffect(() => {
        if (uuid) {
            SessionAPI.getTemporaryAccount(uuid).then(response => {
                if (response.status === 200) {
                    setTa(response.data)
                }
            })
        }
    }, [])

    return <HelmetProvider context={helmetContext}>
        <div className="d-flex flex-column flex-lg-row flex-column-fluid">
            <Helmet>
                <body className="bg-white" id="kt-body"/>
            </Helmet>
            <div
                className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
                <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
                    <div className="left-container">
                        <h1 className="text-black fs-2tx text-center">Lyve Medical Network</h1>
                    </div>
                    <div className="w-lg-550px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
                        <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
                              onSubmit={e => {
                                  e.preventDefault()
                              }}>
                            <div className="text-center mb-10">
                                <h1 className="text-dark mb-3"></h1>
                                <div className="text-gray-400 fw-bold fs-4">Veuillez compléter votre inscription
                                </div>
                            </div>
                            <div className="mb-10 fv-row fv-plugins-icon-container">
                                <div className="mb-1">
                                    <label className="form-label fw-bolder fs-6">Numéro RPPS</label>
                                    <div className="position-relative mb-3">
                                        <input className="form-control form-control-lg form-control-solid disabled"
                                               type="text"
                                               disabled
                                               value={ta?.rpps}
                                               placeholder="" name="rppsNumber" autoComplete="off"/>
                                    </div>
                                </div>
                                <div className="mb-1">
                                    <label className="form-label fw-bolder fs-6">Nom</label>
                                    <div className="position-relative mb-3">
                                        <input className="form-control form-control-lg form-control-solid"
                                               type="lastName"
                                               defaultValue={ta?.lastName}
                                               disabled
                                               placeholder="" name="lastName" autoComplete="off"/>
                                        {(errors?.filter(e => e.fieldName === "lastName").shift()?.message) &&
                                            <div
                                                className="fv-plugins-message-container invalid-feedback">{t("errors:" + formatMessage(errors?.filter(e => e.fieldName === "lastName").shift()?.message))}</div>
                                        }
                                    </div>
                                </div>
                                <div className="mb-1">
                                    <label className="form-label fw-bolder fs-6">Prénom</label>
                                    <div className="position-relative mb-3">
                                        <input className="form-control form-control-lg form-control-solid"
                                               type="firstName"
                                               disabled
                                               defaultValue={ta?.firstName}
                                               placeholder="" name="firstName" autoComplete="off"/>
                                        {(errors?.filter(e => e.fieldName === "firstName").shift()?.message) &&
                                            <div
                                                className="fv-plugins-message-container invalid-feedback">{t("errors:" + formatMessage(errors?.filter(e => e.fieldName === "firstName").shift()?.message))}</div>
                                        }
                                    </div>
                                </div>
                                <div className="mb-1">
                                    <label className="form-label fw-bolder fs-6">Adresse e-mail</label>
                                    <div className="position-relative mb-3">
                                        <input className="form-control form-control-lg form-control-solid"
                                               type="email"
                                               onChange={(e) => setTa({...ta, email: e.target.value})}
                                               placeholder=""
                                               name="email"
                                               autoComplete="off"/>
                                        {(errors?.filter(e => e.fieldName === "email").shift()?.message) &&
                                            <div
                                                className="fv-plugins-message-container invalid-feedback">{t("errors:" + formatMessage(errors?.filter(e => e.fieldName === "email").shift()?.message))}</div>
                                        }
                                    </div>
                                </div>
                                <div className="mb-1">
                                    <label className="form-label fw-bolder fs-6">Mot de passe</label>
                                    <div className="position-relative mb-3">
                                        <input className="form-control form-control-lg form-control-solid"
                                               type="password"
                                               onChange={(e) => setTa({...ta, password: e.target.value})}
                                               placeholder="" name="password" autoComplete="off"/>
                                        {(errors?.filter(e => e.fieldName === "password").shift()?.message) &&
                                            <div
                                                className="fv-plugins-message-container invalid-feedback">{t("errors:" + formatMessage(errors?.filter(e => e.fieldName === "password").shift()?.message))}</div>
                                        }
                                    </div>
                                </div>
                                <div className="mb-1">
                                    <label className="form-label fw-bolder fs-6">Confirmation du mot de passe</label>
                                    <div className="position-relative mb-3">
                                        <input className="form-control form-control-lg form-control-solid"
                                               type="password"
                                               onChange={(e) => setTa({...ta, confirmation: e.target.value})}
                                               placeholder="" name="confirmation" autoComplete="off"/>
                                        {(errors?.filter(e => e.fieldName === "confirmation").shift()?.message) &&
                                            <div
                                                className="fv-plugins-message-container invalid-feedback">{t("errors:" + formatMessage(errors?.filter(e => e.fieldName === "confirmation").shift()?.message))}</div>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="text-center">
                                <button type="button" onClick={onSend}
                                        data-kt-indicator={loading ? "on" : "off"}
                                        disabled={loading}
                                        className="btn btn-lg btn-primary fw-bolder">
                                    <span className="indicator-label">{t("common:submit_button")}</span>
                                    <span className="indicator-progress">{t("common:waiting_button")}
                                        <span
                                            className="spinner-border spinner-border-sm align-middle ms-2"/></span>
                                </button>
                            </div>
                            <div className="text-center mt-3">
                                <a href="/auth/login" className="link-primary fw-bolder">Retour sur à page de
                                    connexion</a></div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </HelmetProvider>
}
export default InitDoctorAccount