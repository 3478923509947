import React, {useEffect, useRef, useState} from "react"
import {Modal} from "react-bootstrap";
import {SpecialityModal} from "./SpecialityModal";
import {useNavigate} from "react-router-dom";
import NotificationAlert from "../common/ui/NotificationAlert";
import LabelAPI from "../../api/LabelAPI";
import {wrapRequest} from "../../helpers/auth";
import {useDispatch} from "react-redux";
import Pagination from "@vlsergey/react-bootstrap-pagination";
import Swal from "sweetalert2";
import {toast} from "react-toastify";
import {LoadableComponent} from "../common/ui/LoadableComponent";

export const Speciality = () => {

    const effectRan = useRef(false);
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)
    const [specialities, setSpecialities] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [totalResults, setTotalResults] = useState(0);
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const [selectedId, setSelectedId] = useState()

    const onPageChange = ({target}) => {
        const page = target.value;
        navigate({
            pathname: "/settings",
            search: `?page=${page}`,
        });
        getSpecialities(page, 10);
    }

    const getSpecialities = (page, size) => {
        setLoading(true)
        return wrapRequest(dispatch, LabelAPI.getLabels("SPECIALITY", page, size))
            .then(response => {
                setLoading(false)
                if (response.status === 200) {
                    setSpecialities(response.data.content)
                    setTotalPages(response.data.totalPages)
                    setCurrentPage(response.data.number)
                    setTotalResults(response.data.totalElements)
                }
            })
    }

    useEffect(() => {
        if (!effectRan.current) {
            getSpecialities(0, 10)
        }
        return () => effectRan.current = true;
    }, []);

    const onUpdate = (e) => {
        e.preventDefault()
        const id = e.target.id
        setSelectedId(id)
        setOpen(true)
    }

    const onDelete = (e) => {
        e.preventDefault()
        const id = e.target.id
        Swal.fire({
            title: "Voulez-vous supprimer cette spécialité?",
            text: "Attention ! Cette action est irréversible",
            showCancelButton: true,
            confirmButtonText: "Oui",
            cancelButtonText: `Non`,
            icon: "warning"
        }).then((result) => {
            if (result.isConfirmed) {
                return wrapRequest(dispatch, LabelAPI.remove(id))
                    .then(response => {
                        if (response.status === 204) {
                            toast.success("Spécialité supprimée")
                            getSpecialities(0, 10)
                        }
                    })
            }
        });
    }


    return <div className="card">
        <Modal show={open} size="lg">
            <Modal.Header
                closeButton={true}
                onHide={() => {
                    setSelectedId(undefined)
                    setOpen(false)
                }}
            >
                <Modal.Title>{selectedId ? "Mettre à jour une spécialité" : "Ajouter une spécialité"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <SpecialityModal setOpen={setOpen}
                                 onSuccess={getSpecialities}
                                 selectedId={selectedId}
                                 setSelectedId={setSelectedId}
                />
            </Modal.Body>
        </Modal>
        <div className="card-header border-0 pt-6">
            <div className="card-title">Spécialités
                ({totalResults} trouvée{totalResults > 1 ? "s" : ""})
            </div>
            <div className="card-toolbar">
                <div className="d-flex justify-content-end" data-kt-customer-table-toolbar="base">
                    <button type="button" className="btn btn-primary"
                            onClick={(e) => {
                                e.preventDefault()
                                setSelectedId(undefined)
                                setOpen(true)
                            }}>Ajouter une spécialité
                    </button>
                </div>
            </div>
        </div>
        <div className="card-body pt-0">
            <div id="kt_customers_table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer mt-8">
                <LoadableComponent loading={loading} component={<>{specialities?.length === 0 ? <NotificationAlert
                        title={"Aucun élément trouvé"}
                        message={"Veuillez ajouter des spécialités"}
                    /> :
                    <div className="table-responsive">
                        <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
                               id="kt_customers_table">
                            <thead className="border-gray-200 fs-5 fw-semibold bg-lighten">
                            <tr>
                                <th width="80%" className="min-w-125px sorting">Nom</th>
                                <th className="min-w-125px sorting">Actions</th>
                            </tr>
                            </thead>
                            <tbody className="fw-semibold text-gray-600">
                            {specialities?.map((speciality, idx) => <tr key={idx}
                                                                        className={idx % 2 === 0 ? "even" : "odd"}>
                                    <td data-order="2020-12-14T20:43:00+01:00">{speciality.name}</td>
                                    <td id={speciality.id}>
                                        <button
                                            id={speciality.id}
                                            onClick={onUpdate}
                                            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                                        <span className="svg-icon svg-icon-3" id={speciality.id}>
																			<i className="fa-solid fa-pen"
                                                                               id={speciality.id}></i>
                                        </span>
                                        </button>
                                        <button className="btn btn-icon btn-bg-light btn-active-color-danger btn-sm"
                                                id={speciality.id}
                                                onClick={onDelete}>
                                            <span className="svg-icon svg-icon-3">
																			<i className="fa-solid fa-trash"
                                                                               id={speciality.id}></i>
                                            </span>
                                        </button>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                        <Pagination
                            aroundCurrent={2}
                            showFirstLast={true}
                            showPrevNext={true}
                            atBeginEnd={0}
                            onChange={onPageChange}
                            value={currentPage}
                            totalPages={totalPages}
                        />
                    </div>}</>}/>
            </div>
        </div>
    </div>
}
