import React, {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {wrapRequest} from "../../helpers/auth";
import AcquisitionConnectorAPI from "../../api/AcquisitionConnectorAPI";
import {LoadableComponent} from "../common/ui/LoadableComponent";
import {Modal} from "react-bootstrap";
import {AcquisitionConnectorModal} from "./AcquisitionConnectorModal";
import {CopyToClipboard} from "react-copy-to-clipboard/src";
import {toast} from "react-toastify";
import Swal from "sweetalert2";

export const AcquisitionConnector = ({organizationId}) => {

    const effectRan = useRef(false);
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)
    const [acquisitionConnectors, setAcquisitionConnectors] = useState([]);
    const [loading, setLoading] = useState(false)

    const getAcquisitionConnectors = (page, size) => {
        setLoading(true)
        return wrapRequest(dispatch, AcquisitionConnectorAPI.getAll(organizationId, page, size))
            .then(response => {
                setLoading(false)
                if (response.status === 200) {
                    setAcquisitionConnectors(response.data.content)
                }
            })
    }

    const onDelete = (e) => {
        e.preventDefault()
        const id = e.target.id
        Swal.fire({
            title: "Voulez-vous supprimer ce connecteur?",
            text: "Attention ! Vos systèmes connectés ne seront plus authentifiés !",
            showCancelButton: true,
            confirmButtonText: "Oui",
            cancelButtonText: `Non`,
            icon: "warning"
        }).then((result) => {
            if (result.isConfirmed) {
                return wrapRequest(dispatch, AcquisitionConnectorAPI.remove(id))
                    .then(response => {
                        if (response.status === 204) {
                            toast.success("Connecteur supprimé")
                            getAcquisitionConnectors(0, 10)
                        }
                    })
            }
        });
    }

    useEffect(() => {
        if (!effectRan.current) {
            getAcquisitionConnectors(0, 10)
        }
        return () => effectRan.current = true;
    }, []);


    return <div className="card mb-5">

        <Modal show={open} size="lg">
            <Modal.Header
                closeButton={true}
                onHide={() => {
                    setOpen(false)
                }}
            >
                <Modal.Title>{"Créer un nouveau connecteur"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <AcquisitionConnectorModal onSuccess={getAcquisitionConnectors} setOpen={setOpen} organizationId={organizationId}/>
            </Modal.Body>
        </Modal>

        <div className="card-header border-0 pt-6">
            <div className="card-title">Dispositifs d'acquisition</div>
        </div>
        <div className="card-body pt-0">
            <LoadableComponent loading={loading} component={
                <>
                    {(!acquisitionConnectors || acquisitionConnectors.length === 0) ? <div className="card-px text-center pt-15 pb-15">
                        <h2 className="fs-2x fw-bold mb-0">Dispositifs connectés</h2>
                        <p className="text-gray-500 fs-4 fw-semibold py-7">
                            Aucun disposition d'acquisition trouvé</p>
                        <a onClick={() => setOpen(true)} className="btn btn-primary er fs-6 px-8 py-4 cursor-pointer">
                            Créer un nouveau connecteur</a>
                    </div> : <div className="table-responsive">
                        <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
                            <thead className="border-gray-200 fs-5 fw-semibold bg-lighten">
                            <tr>
                                <th width="30%" className="min-w-125px sorting">Nom</th>
                                <th width="30%" className="min-w-125px sorting">Type</th>
                                <th width="30%" className="min-w-125px sorting">Value</th>
                                <th width="10%" className="min-w-125px sorting">Actions</th>
                            </tr>
                            </thead>
                            <tbody className="fw-semibold text-gray-600">
                            {acquisitionConnectors?.map((connector, idx) => <tr key={idx}
                                                            className={idx % 2 === 0 ? "even" : "odd"}>
                                    <td>{connector?.name}</td>
                                    <td>{connector?.type}</td>
                                    <td className="">
                                        <div className="input-group input-group-sm w-50">
                                            <input type="password" className="form-control" value={connector?.key}
                                                   aria-describedby="basic-addon2"/>
                                            <CopyToClipboard
                                                key={connector?.key}
                                                onCopy={() =>
                                                    toast.success("Clé copiée dans le presse-papier")
                                                }
                                                text={connector?.key}
                                            >
                                                <span className="input-group-text cursor-pointer">
                                                <i className="fa-regular fa-copy"></i>
                                            </span></CopyToClipboard>
                                        </div>
                                    </td>
                                    <td>
                                        <a href="#"
                                           id={connector.id}
                                           onClick={onDelete}
                                           className="btn btn-icon btn-bg-light btn-sm me-1 cursor-pointer">
                                        <span className="svg-icon svg-icon-3" id={connector.id}>
																			<i className="fa-solid fa-trash"
                                                                               id={connector.id}></i>
                                        </span
                                        ></a>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>}
                </>}/>
        </div>
    </div>
}
