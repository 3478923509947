import React from "react";
import {connect} from "react-redux";
import {LargeMenu as SuperAdminLargeMenu} from "./superAdmin/LargeMenu";
import {LargeMenu as AdminLargeMenu} from "./admin/LargeMenu";
import {LargeMenu as DoctorLargeMenu} from "./doctor/LargeMenu";
import {LargeMenu as UserLargeMenu} from "./user/LargeMenu";

const SideMenu = ({profile, activeLink}) => {
    const getMenu = (profile) => {
        if (profile?.roles?.includes("ROLE_SUPER_ADMIN")) {
            return <SuperAdminLargeMenu activeLink={activeLink}/>
        }
        if (profile?.roles?.includes("ROLE_ADMIN")
            || profile?.roles?.includes("ROLE_MANAGER")) {
            return <AdminLargeMenu activeLink={activeLink}/>
        }
        if (profile?.roles?.includes("ROLE_DOCTOR")) {
            return <DoctorLargeMenu activeLink={activeLink}/>
        }
        if (profile?.roles?.includes("ROLE_USER")) {
            return <UserLargeMenu activeLink={activeLink}/>
        }
    }
    return getMenu(profile)
}
const mapStateToProps = state => ({
    profile: state.profile
});

export default connect(mapStateToProps, null)(SideMenu);
