import React, {useEffect, useRef, useState} from "react"
import {useDispatch} from "react-redux";
import {wrapRequest} from "../../helpers/auth";
import WebhookAPI from "../../api/WebhookAPI";
import {LoadableComponent} from "../common/ui/LoadableComponent";
import {Modal} from "react-bootstrap";
import WebhookModal from "./WebhookModal";
import Swal from "sweetalert2";
import {toast} from "react-toastify";

const Webhook = ({organizationId}) => {

    const effectRan = useRef(false);
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)
    const [webhooks, setWebhooks] = useState([]);
    const [loading, setLoading] = useState(false)
    const [selectedId, setSelectedId] = useState()

    const getWebhooks = (page, size) => {
        setLoading(true)
        return wrapRequest(dispatch, WebhookAPI.getAll(organizationId, page, size))
            .then(response => {
                setLoading(false)
                if (response.status === 200) {
                    setWebhooks(response.data.content)
                }
            })
    }

    const onDelete = (e) => {
        e.preventDefault()
        const id = e.target.id
        Swal.fire({
            title: "Voulez-vous supprimer ce webhook?",
            text: "Attention ! Vous ne serez plus notifié via ce canal !",
            showCancelButton: true,
            confirmButtonText: "Oui",
            cancelButtonText: `Non`,
            icon: "warning"
        }).then((result) => {
            if (result.isConfirmed) {
                return wrapRequest(dispatch, WebhookAPI.remove(id))
                    .then(response => {
                        if (response.status === 204) {
                            toast.success("Webhook supprimé")
                            getWebhooks(0, 1)
                        }
                    })
            }
        });
    }

    const onUpdate = (e) => {
        e.preventDefault()
        const id = e.target.id
        setSelectedId(id)
        setOpen(true)
    }

    useEffect(() => {
        if (!effectRan.current) {
            getWebhooks(0, 1)
        }
        return () => effectRan.current = true;
    }, []);

    return <div className="card mb-5">
        <Modal show={open} size="lg">
            <Modal.Header
                closeButton={true}
                onHide={() => {
                    setOpen(false)
                    setSelectedId(undefined)
                }}
            >
                <Modal.Title>{"Ajouter un nouveau webhook"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <WebhookModal onSuccess={getWebhooks} setOpen={setOpen} selectedId={selectedId} organizationId={organizationId}/>
            </Modal.Body>
        </Modal>
        <div className="card-header border-0 pt-6">
            <div className="card-title">Webhook</div>
        </div>
        <div className="card-body pt-0">

            <LoadableComponent loading={loading} component={
                <>
                    {(!webhooks || webhooks.length === 0) ? <div className="card-px text-center pt-15 pb-15">
                        <h2 className="fs-2x fw-bold mb-0">Webhook</h2>
                        <p className="text-gray-500 fs-4 fw-semibold py-7">
                            Aucun webhook n'a été trouvé pour ce compte</p>
                        <a onClick={() => setOpen(true)} className="btn btn-primary er fs-6 px-8 py-4 cursor-pointer">
                            Créer un nouveau webhook</a>
                    </div> : <div className="table-responsive">
                        <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
                               id="kt_customers_table">
                            <thead className="border-gray-200 fs-5 fw-semibold bg-lighten">
                            <tr>
                                <th width="30%" className="min-w-125px sorting">Url</th>
                                <th width="30%" className="min-w-125px sorting">Status</th>
                                <th width="30%" className="min-w-125px sorting">Email de secours</th>
                                <th width="10%" className="min-w-125px sorting">Actions</th>
                            </tr>
                            </thead>
                            <tbody className="fw-semibold text-gray-600">
                            {webhooks?.map((webhook, idx) => <tr key={idx}
                                                                 className={idx % 2 === 0 ? "even" : "odd"}>
                                    <td>{webhook?.url}</td>
                                    <td>{webhook?.enabled
                                        ? <span
                                            className="badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2">Activé</span>
                                        : <span
                                            className="badge badge-light-secondary fw-bolder fs-8 px-2 py-1 ms-2">Désactivé</span>}</td>
                                    <td>{webhook?.email || "Non renseigné"}</td>
                                    <td>

                                        <button
                                            id={webhook.id}
                                            onClick={onUpdate}
                                            className="btn btn-icon btn-bg-light btn-sm me-1 cursor-pointer">
                                        <span className="svg-icon svg-icon-3" id={webhook.id}>
																			<i className="fa-solid fa-edit"
                                                                               id={webhook.id}></i>
                                        </span
                                        ></button>
                                        <button
                                            id={webhook.id}
                                            onClick={onDelete}
                                            className="btn btn-icon btn-bg-light btn-sm me-1 cursor-pointer">
                                        <span className="svg-icon svg-icon-3" id={webhook.id}>
																			<i className="fa-solid fa-trash"
                                                                               id={webhook.id}></i>
                                        </span
                                        ></button>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>}
                </>}/>

        </div>

    </div>
}

export default Webhook
