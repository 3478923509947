import {normalizeText} from "../../helpers/textUtils";
import React, {useState} from "react";
import {Modal} from "react-bootstrap";
import ConditionalOverlay from "../common/ui/ConditionalOverlay";
import {call} from "../../helpers/methods";
import {useDispatch} from "react-redux";

export const DoctorCell = ({doctor, record}) => {
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)
    return <>
        <Modal show={open} size="md">
            <Modal.Header
                closeButton={true}
                onHide={() => {
                    setOpen(false)
                }}
            >
                <Modal.Title>{`Prescripteur : ${normalizeText(doctor?.lastName, 30, "capitalize")} 
                ${normalizeText(doctor?.firstName, 30, "capitalize")}`}
                    {doctor?.userId && ` (${doctor.code})`}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <div className="fs-6">
                        <div className="d-flex flex-stack">
                            <div>
                                <div className="fw-bold">Adresse</div>
                                <div className="text-gray-600">{doctor?.address}</div>
                            </div>
                            <div><a href="#" onClick={() => call(dispatch, record, doctor?.phoneNumber)}
                                    className="btn btn-light-primary btn-sm">
                                <span className="svg-icon svg-icon-3"><i className="fa-solid fa-phone"></i></span>
                                Appeler le {doctor?.phoneNumber}</a></div>
                        </div>
                        {doctor?.userId &&
                            <>
                                <div className="fw-bold mt-5">Email</div>
                                <div className="text-gray-600">{doctor?.email}</div>
                                <div className="fw-bold mt-5">Spécialité</div>
                                <div
                                    className="text-gray-600">{doctor?.speciality}</div>
                            </>
                        }
                    </div>
                </div>
            </Modal.Body>
        </Modal>
        <td onClick={() => {
            if (doctor) {
                setOpen(true)
            }
        }}>
            <div className="btn btn-outline btn-outline-dashed btn-outline-dark w-100">
                {doctor ? `${normalizeText(doctor?.lastName, 15, "capitalize")} ${normalizeText(doctor?.firstName, 15, "capitalize")}` : "Le prescripteur n'est pas renseigné"}
            </div>
        </td>
    </>
}
