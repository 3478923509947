import axios from "axios";
import {requestHeaders} from "../helpers/constants";

class LaboratoryAPI {

    static create(laboratory) {
        return axios({
            method: 'post',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/laboratories`,
            data: laboratory
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }

    static update(laboratory, id) {
        return axios({
            method: 'put',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/laboratories/${id}`,
            data: laboratory
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }

    static delete(id) {
        return axios({
            method: 'delete',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/laboratories/${id}`
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }

    static get(id) {
        return axios({
            method: 'get',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/laboratories/${id}`
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }

    static getLaboratories(page, size, organizationId) {
        let url = `${process.env.API_BASE_URL}/laboratories?page=${page}&size=${size}`
        if(organizationId){
            url += `&organizationId=${organizationId}`
        }
        return axios({
            method: 'get',
            headers: requestHeaders(),
            url: url,
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }
}

export default LaboratoryAPI;
