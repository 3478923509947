import React, {useEffect, useRef, useState} from "react"
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {wrapRequest} from "../../helpers/auth";
import PatientAPI from "../../api/PatientAPI";
import {LoadableComponent} from "../common/ui/LoadableComponent";
import NotificationAlert from "../common/ui/NotificationAlert";
import Pagination from "@vlsergey/react-bootstrap-pagination";
import moment from "moment";
import {Modal} from "react-bootstrap";
import PatientEdit from "./PatientEdit";

export const Patients = ({}) => {

    const effectRan = useRef(false);
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const [patients, setPatients] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [totalResults, setTotalResults] = useState(0);
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const [selectedId, setSelectedId] = useState()

    const navigate = useNavigate()

    const getPatients = (page, size) => {
        setLoading(true)
        return wrapRequest(dispatch, PatientAPI.getPatients(page, size))
            .then(response => {
                setLoading(false)
                if (response.status === 200) {
                    setPatients(response.data.content)
                    setTotalPages(response.data.totalPages)
                    setCurrentPage(response.data.number)
                    setTotalResults(response.data.totalElements)
                }
            })
    }

    useEffect(() => {
        if (!effectRan.current) {
            getPatients(0, 30)
        }
        return () => effectRan.current = true;
    }, []);

    const onPageChange = ({target}) => {
        const page = target.value;
        navigate({
            pathname: "/patients",
            search: `?page=${page}`,
        });
        getPatients(page, 30);
    }

    const getPatientGender = (patient) => {
        switch (patient.gender){
            case "M":
                return "Homme"
            case "F":
                return "Femme"
            case "U":
                return "Non renseigné"
        }

    }
    return <div className="card">
        <Modal show={open} size="lg">
            <Modal.Header
                closeButton={true}
                onHide={() => {
                    setSelectedId(undefined)
                    setOpen(false)
                }}
            >
                <Modal.Title>{selectedId ? "Mettre à jour un patient" : "Ajouter un nouveau patient"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <PatientEdit setOpen={setOpen} onSuccess={getPatients} selectedId={selectedId}/>
            </Modal.Body>
        </Modal>
        <div className="card-header border-0 pt-6">
            <div className="card-title">Liste des patients ({totalResults} trouvé{totalResults > 1 ? "s" : ""})
            </div>
            <div className="card-toolbar">
                <div className="d-flex justify-content-end" data-kt-customer-table-toolbar="base">
                    <button type="button" className="btn btn-primary"
                            onClick={(e) => {
                                e.preventDefault()
                                setSelectedId(undefined)
                                setOpen(true)
                            }}>Ajouter un nouveau patient
                    </button>
                </div>
            </div>
        </div>

        <div className="card-body pt-0">
            <div className="dataTables_wrapper dt-bootstrap4 no-footer mt-8">
                <LoadableComponent loading={loading} component={<>{patients?.length === 0 ? <NotificationAlert
                        title={"Aucun patient trouvé"}
                        message={"Veuillez ajouter vos premiers patients"}
                    /> :
                    <div className="table-responsive">
                        <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
                            <thead className="border-gray-200 fs-5 fw-semibold bg-lighten">
                            <tr>
                                <th className="min-w-125px sorting">Date d'ajout</th>
                                <th className="min-w-125px sorting">Nom du patient</th>
                                <th className="min-w-125px sorting">Sexe</th>
                                <th className="min-w-125px sorting">Numéro DDN</th>
                                <th className="min-w-125px sorting">Numéro de sécurité sociale</th>
                                <th className="min-w-125px sorting">Date de naissance</th>
                            </tr>
                            </thead>
                            <tbody className="fw-semibold text-gray-600">
                            {patients?.map((patient, idx) => <tr key={idx}
                                                                 onClick={()=> navigate(`/patients/${patient.id}`)}
                                                                 className={"cursor-pointer " + (idx % 2 === 0 ? "even" : "odd")}>
                                    <td>{moment.utc(patient?.createdDate).local().format(t("common:dateTimeFormat"))}</td>
                                    <td>{patient.lastName} {patient.firstName}</td>
                                    <td>{getPatientGender(patient)}</td>
                                    <td>{patient.ddn}</td>
                                    <td>{patient.socialSecurityNumber}</td>
                                    <td>{moment.utc(patient?.birthDate).local().format(t("common:dateFormat"))}</td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                        <div className="mt-8">
                        <Pagination
                            aroundCurrent={2}
                            showFirstLast={true}
                            showPrevNext={true}
                            atBeginEnd={0}
                            onChange={onPageChange}
                            value={currentPage}
                            totalPages={totalPages}
                        /></div>
                    </div>}
                </>}/>
            </div>

        </div>
    </div>
}
