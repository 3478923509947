import React, {useRef, useState} from "react"
import {useDispatch} from "react-redux";
import {wrapRequest} from "../../helpers/auth";
import Swal from "sweetalert2";
import {useTranslation} from "react-i18next";
import getCroppedImg from "../../helpers/cropImage";
import DoctorAPI from "../../api/DoctorAPI";
import {Modal} from "react-bootstrap";
import {Button} from "../common/ui/Button";
import ImageUploader from "../common/ui/ImageUploader";
import NotificationAlert from "../common/ui/NotificationAlert";


const Signature = ({profile}) => {
    const inputRef = useRef(null);
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)
    const [errors, setErrors] = useState([])
    const [signature, setSignature] = useState()

    const [imageRendered, setImageToUpload] = useState(undefined);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(undefined);

    const uploadCall = (blob) => {
        return wrapRequest(
            dispatch,
            DoctorAPI.uploadSignature(blob)
        ).then((response) => {
            setOpen(false);
            if (response.status === 204) {
                Swal.fire({
                    icon: "success",
                    title: "Signature updated",
                    confirmButtonText: t("common:confirm_button"),
                }).then(() => {

                });
            } else {
                setErrors(response.data.errors);
            }
        });
    };

    const getSignature = () => {
        return wrapRequest(
            dispatch,
            DoctorAPI.getSignature()
        ).then((response) => {
            if (response.status === 200) {
                setSignature(response.data)
            } else {
                setErrors(response.data.errors);
            }
        });
    };

    const uploadImage = () => {
        getCroppedImg(imageRendered, croppedAreaPixels, 0)
            .then((data) => {
                const b64Image = data.toDataURL("image/jpeg");
                const u8Image = b64ToUint8Array(b64Image);
                uploadCall(new Blob([u8Image], {type: "image/jpeg"}));
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => setOpen(false));
    };

    const b64ToUint8Array = (b64Image) => {
        const img = atob(b64Image.split(",")[1]);
        const img_buffer = [];
        let i = 0;
        while (i < img.length) {
            img_buffer.push(img.charCodeAt(i));
            i++;
        }
        return new Uint8Array(img_buffer);
    }
    const onSelectFile = () => {
        const file = inputRef.current.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function () {
            setImageToUpload(reader.result);
            setOpen(true);
        };
    };
    return <div className="card mb-5 mt-5 mb-xxl-8">
        <Modal show={open} fullscreen>
            <Modal.Header>
                <Modal.Title>{t("modals:change_profile_image")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ImageUploader
                    setOpen={setOpen}
                    imageRendered={imageRendered}
                    scale={1}
                    setCroppedAreaPixels={setCroppedAreaPixels}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => setOpen(false)} variant="secondary">
                   Annuler
                </Button>
                <Button onClick={uploadImage} variant="primary">
                    Envoyer
                </Button>
            </Modal.Footer>
        </Modal>
        <div className="card-header border-0 pt-6">
            <div className="card-title">Signature
            </div>
            <div className="card-toolbar">
                <div className="d-flex justify-content-end" data-kt-customer-table-toolbar="base">
                    <div className={"btn btn-primary pt-0 pb-0  text-white"}>
                        <label className="p-3 dropzone border-0 bg-primary" htmlFor={"file"}>
                            {profile?.signature ? "Modifier la" : "Ajouter une"} signature
                        </label>
                        <input
                            className={"csv-input"}
                            type="file"
                            id={"file"}
                            name={"file"}
                            accept=".png, .jpg, .jpeg"
                            style={{display: "none"}}
                            onChange={onSelectFile}
                            disabled={false}
                            ref={inputRef}
                        />
                    </div>
                </div>
            </div>
        </div>
        <div className="card-body">
            {!profile.signature ? <NotificationAlert
                    title="Aucune signature pour ce compte"
                    message="Veuillez scanner votre signature sur papier libre et l'importer ici."
                    level="info"
                /> :
                <>
                    <div className="col-lg-12 mb-10">
                        {signature ?
                            <img src={signature?.url} className="mw-100 rounded-3 h-100px w-100px" alt="signature"/>
                            : <img src={"/assets/images/signature.png"} className="mw-100 rounded-3 h-100px w-100px"
                                   alt="signature"/>}
                    </div>
                    {!signature &&
                        <div onClick={getSignature} className={"btn btn-primary"}>Afficher la signature</div>}
                </>}
        </div>
    </div>
}

export default Signature
