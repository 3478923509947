// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Left */

.left-container {
    padding: 3rem 10rem !important;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    backdrop-filter: blur(50px);
    border-radius: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/authentication/Login.css"],"names":[],"mappings":"AAAA,SAAS;;AAET;IACI,8BAA8B;IAC9B,oCAAoC;IACpC,yBAAyB;IACzB,sBAAsB;IACtB,2BAA2B;IAC3B,mBAAmB;AACvB","sourcesContent":["/* Left */\n\n.left-container {\n    padding: 3rem 10rem !important;\n    background: rgba(255, 255, 255, 0.1);\n    border: 1px solid #FFFFFF;\n    box-sizing: border-box;\n    backdrop-filter: blur(50px);\n    border-radius: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
