import React, {useState} from 'react';
import {Helmet, HelmetProvider} from 'react-helmet-async';
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {logInUser} from "../../redux/actions/sessionActions";
import {connect} from "react-redux";
import {formatMessage} from "../../helpers/methods";
import UserAPI from "../../api/UserAPI";


const ResetPassword = ({logInUser}) => {
    const helmetContext = {};
    const {t} = useTranslation();

    const [loading, setLoading] = useState(false)

    const [searchParams] = useSearchParams()

    const email = searchParams.get("email")

    const [errors, setErrors] = useState([])

    const [passwordScore, setPasswordScore] = useState(0)

    const [showPassword, setShowPassword] = useState(false)

    const navigate = useNavigate();

    const [body, setBody] = useState(
        {
            code: searchParams.get("code"),
            accountId: searchParams.get("account")
        })

    const onFormChange = (e) => {
        const field = e.target.name
        const data = body
        data[field] = e.target.value
        setPasswordScore(scorePassword(data?.password))
        setBody(data)
    }

    const onSend = (event) => {
        event.preventDefault()
        setLoading(true)
        return UserAPI.resetPassword(body)
            .then(response => {
                setLoading(false)
                if (response.status === 204) {
                    Swal.fire({
                        icon: 'success',
                        title: "Mot de passe changé avec succès",
                        text: "Vous allez être redirigé...",
                        showCancelButton: false,
                        confirmButtonText: t("common:confirm_button"),
                        cancelButtonText: t("common:button.cancel")
                    }).then(() => {
                        logInUser({email: email, password: body.password}).then(() => navigate({pathname: "/"}))
                    })
                } else {
                    if (response.data.errors) {
                        setErrors(response.data.errors)
                    } else {
                        Swal.fire({
                            icon: 'warning',
                            title: "Lien expiré.",
                            text: "Veuillez renouveler votre demande de réinitialisation"

                        })
                    }
                }
            })
    }

    const scorePassword = (pass) => {
        let score = 0;
        if (!pass)
            return score;
        let letters = {};
        for (let i = 0; i < pass.length; i++) {
            letters[pass[i]] = (letters[pass[i]] || 0) + 1;
            score += 5.0 / letters[pass[i]];
        }

        let variations = {
            digits: /\d/.test(pass),
            lower: /[a-z]/.test(pass),
            upper: /[A-Z]/.test(pass),
            nonWords: /\W/.test(pass),
        }

        let variationCount = 0;
        for (let check in variations) {
            variationCount += (variations[check] === true) ? 1 : 0;
        }
        score += (variationCount - 1) * 10;
        return parseInt(score);
    }

    return (<HelmetProvider context={helmetContext}>
        <div className="d-flex flex-column flex-lg-row flex-column-fluid">
            <Helmet>
                <body className="bg-white" id="kt-body"/>
            </Helmet>
            <div
                className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
                style={{backgroundImage: 'url("/ceres-html-pro/assets/media/illustrations/dozzy-1/14.png")'}}>
                <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
                    <Link to="/auth/login" className="mb-12">
                        <h1 className="text-black fs-2tx text-center">Lyve Medical Network</h1>
                    </Link>
                    <div className="w-lg-550px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
                        <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" noValidate="novalidate"
                              id="kt_new_password_form">
                            <div className="text-center mb-10">
                                <h1 className="text-dark mb-3">Modification de votre mot de passe</h1>
                            </div>
                            {errors?.filter(e => e.status === 404).length > 0 &&
                                <div className="alert alert-danger d-flex align-items-center p-5 mb-10">
                                <span className="svg-icon svg-icon-2hx svg-icon-danger me-4">
														<svg width="24px"
                                                             height="24px" viewBox="0 0 24 24" version="1.1">
															<g stroke="none" strokeWidth="1" fill="none"
                                                               fillRule="evenodd">
																<rect x="0" y="0" width="24" height="24"/>
																<path
                                                                    d="M4,4 L11.6314229,2.5691082 C11.8750185,2.52343403 12.1249815,2.52343403 12.3685771,2.5691082 L20,4 L20,13.2830094 C20,16.2173861 18.4883464,18.9447835 16,20.5 L12.5299989,22.6687507 C12.2057287,22.8714196 11.7942713,22.8714196 11.4700011,22.6687507 L8,20.5 C5.51165358,18.9447835 4,16.2173861 4,13.2830094 L4,4 Z"
                                                                    fill="currentColor" opacity="0.3"/>
                                                                <path
                                                                    d="M11.1750002,14.75 C10.9354169,14.75 10.6958335,14.6541667 10.5041669,14.4625 L8.58750019,12.5458333 C8.20416686,12.1625 8.20416686,11.5875 8.58750019,11.2041667 C8.97083352,10.8208333 9.59375019,10.8208333 9.92916686,11.2041667 L11.1750002,12.45 L14.3375002,9.2875 C14.7208335,8.90416667 15.2958335,8.90416667 15.6791669,9.2875 C16.0625002,9.67083333 16.0625002,10.2458333 15.6791669,10.6291667 L11.8458335,14.4625 C11.6541669,14.6541667 11.4145835,14.75 11.1750002,14.75 Z"
                                                                    fill="currentColor"/>
															</g>
														</svg>
                                </span>
                                    <div className="d-flex flex-column">
                                        <h5 className="mb-1">{t("errors:" + formatMessage(errors?.filter(e => e.status === 404).shift()?.message))}</h5>
                                    </div>
                                </div>
                            }
                            <div className="mb-10 fv-row fv-plugins-icon-container" data-kt-password-meter="true">
                                <div className="mb-1">
                                    <label className="form-label fw-bolder text-dark fs-6">Nouveau mot de passe</label>
                                    <div className="position-relative mb-3">
                                        <input className="form-control form-control-lg form-control-solid"
                                               onChange={onFormChange}
                                               type={showPassword ? "text" : "password"} placeholder="" name="password"
                                               autoComplete="off"/>
                                        <span
                                            className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                                            data-kt-password-meter-control="visibility">
												{showPassword && <i className="bi bi-eye-slash fs-2"
                                                                    onClick={() => setShowPassword(false)}/>}
                                            {!showPassword &&
                                                <i className="bi bi-eye fs-2" onClick={() => setShowPassword(true)}/>}
											</span>
                                    </div>
                                    <div className="d-flex align-items-center mb-3">
                                        <div
                                            className={`flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2 ${passwordScore >= 25 ? "active" : ""}`}/>
                                        <div
                                            className={`flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2 ${passwordScore >= 50 ? "active" : ""}`}/>
                                        <div
                                            className={`flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2 ${passwordScore >= 75 ? "active" : ""}`}/>
                                        <div
                                            className={`flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2 ${passwordScore > 99 ? "active" : ""}`}/>
                                    </div>

                                </div>
                                <div className="text-muted">Veuillez utiliser un mot de passe fort
                                </div>
                                {(errors?.filter(e => e.fieldName === "password").shift()?.message) &&
                                    <div
                                        className="fv-plugins-message-container invalid-feedback">{t("errors:" + formatMessage(errors?.filter(e => e.fieldName === "password").shift()?.message))}</div>
                                }
                            </div>
                            <div className="fv-row mb-10 fv-plugins-icon-container">
                                <label className="form-label fw-bolder text-dark fs-6">Confirmation de votre mot de
                                    passe</label>
                                <input className="form-control form-control-lg form-control-solid" type="password"
                                       onChange={onFormChange}
                                       placeholder="" name="confirmation" autoComplete="off"/>
                                {(errors?.filter(e => e.fieldName === "confirmation").shift()?.message) &&
                                    <div
                                        className="fv-plugins-message-container invalid-feedback">{t("errors:" + formatMessage(errors?.filter(e => e.fieldName === "confirmation").shift()?.message))}</div>
                                }
                            </div>
                            <div className="fv-row mb-10 fv-plugins-icon-container">
                                <div className="fv-plugins-message-container invalid-feedback"/>
                            </div>

                            <div className="text-center">
                                <button type="button" id="kt_new_password_submit" onClick={onSend}
                                        data-kt-indicator={loading ? "on" : "off"}
                                        disabled={loading}
                                        className="btn btn-lg btn-primary fw-bolder">
                                    <span className="indicator-label">{t("common:submit_button")}</span>
                                    <span className="indicator-progress">{t("common:waiting_button")}
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"/></span>
                                </button>
                            </div>
                            <div className="text-center mt-3">
                                <a href="/auth/login" className="link-primary fw-bolder"> Retour sur la page de
                                    connexion</a></div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </HelmetProvider>)
}


const mapDispatchToProps = {logInUser};

const mapStateToProps = state => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
