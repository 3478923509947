import {Link, useNavigate, useParams, useSearchParams} from "react-router-dom";
import React, {useState} from "react";
import {DoctorAssociationEdit} from "./DoctorAssociationEdit";

const DoctorAssociationDetails = ({organizationId, id}) => {
    const params = useParams();
    const definitiveOrganizationId = organizationId ? organizationId : params.organizationId
    const definitiveId = id ? id : params.id
    const [searchParams, setSearchParams] = useSearchParams();
    const [currentTab, setCurrentTab] = useState(searchParams.get("tab") || "edition");
    const navigate = useNavigate()
    const [doctorFullName, setDoctorFullName] = useState("")

    const getCurrentTab = () => {
        switch (currentTab) {
            case "edition":
            default:
                return <div className="card">
                    <div className="card-header border-0 pt-6">
                        <div className="card-title">Mettre à jour</div>
                    </div>
                    <div className="card-body pt-0">
                        <DoctorAssociationEdit organizationId={definitiveOrganizationId} selectedId={definitiveId}
                                        setDoctorFullName={setDoctorFullName}
                                        onSuccess={() => {
                                        }} setOpen={() => {
                        }}
                        />
                    </div>
                </div>
        }
    }

    const selectTab = (value) => {
        setCurrentTab(value)
        navigate({
            pathname: `/organizations/${definitiveOrganizationId}/associations/${definitiveId}`,
            search: `?tab=${value}`,
        });
    }

    return <>
        <div className="card mb-5">
            <div className="card-header">
                <div className="card-title">
                    <Link
                        to={`/organizations/${definitiveOrganizationId}?tab=doctors`}
                        className="btn btn-icon btn-circle btn-bg-light btn-sm me-3">
                <span className="svg-icon svg-icon-3">
																			<i className="fa-solid fa-arrow-left"
                                                                            ></i>
                </span></Link>
                    {`Configuration du lien client pour le prescripteur ${doctorFullName}`}</div>
            </div>
        </div>

        <div className="card mb-5">
            <div className="card-body pt-0 pb-0">
                <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
                    <li className="nav-item mt-2 cursor-pointer">
                        <a className={`nav-link text-active-primary ms-0 me-10 py-5 ${currentTab === "edition" ? "active" : ""}`}
                           onClick={e => selectTab("edition")}
                        >Coordonnées</a>
                    </li>
                </ul>
            </div>
        </div>
        {getCurrentTab()}
    </>
}

export default DoctorAssociationDetails
