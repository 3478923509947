import React, {useEffect, useRef, useState} from "react";
import NotificationAlert from "../common/ui/NotificationAlert";
import {Modal} from "react-bootstrap";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {DoctorAssociationEdit} from "./DoctorAssociationEdit";
import {wrapRequest} from "../../helpers/auth";
import DoctorAssociationAPI from "../../api/DoctorAssociationAPI";
import Swal from "sweetalert2";
import {toast} from "react-toastify";
import Pagination from "@vlsergey/react-bootstrap-pagination";
import {LoadableComponent} from "../common/ui/LoadableComponent";
import {normalizeText} from "../../helpers/textUtils";

const DoctorAssociation = ({organizationId}) => {
    const effectRan = useRef(false);
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)
    const {t} = useTranslation()
    const [associations, setAssociations] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [totalResults, setTotalResults] = useState(0);
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const [selectedId, setSelectedId] = useState()


    const onPageChange = ({target}) => {
        const page = target.value;
        navigate({
            pathname: "/settings",
            search: `?page=${page}`,
        });
        getAssociations(page, 10);
    }
    const getAssociations = (page, size) => {
        setLoading(true)
        return wrapRequest(dispatch, DoctorAssociationAPI.getAssociations(page, size, organizationId))
            .then(response => {
                setLoading(false)
                if (response.status === 200) {
                    setAssociations(response.data.content)
                    setTotalPages(response.data.totalPages)
                    setCurrentPage(response.data.number)
                    setTotalResults(response.data.totalElements)
                }
            })
    }
    useEffect(() => {
        if (!effectRan.current) {
            getAssociations(0, 10, organizationId)
        }
        return () => effectRan.current = true;
    }, []);

    const onUpdate = (e) => {
        e.preventDefault()
        const id = e.target.id
        setSelectedId(id)
        setOpen(true)
    }



    return <div className="card">
        <Modal show={open} size="lg">
            <Modal.Header
                closeButton={true}
                onHide={() => {
                    setSelectedId(undefined)
                    setOpen(false)
                }}
            >
                <Modal.Title>{selectedId ? "Mettre à jour un prescripteur partenaire" : "Ajouter un prescripteur partenaire"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <DoctorAssociationEdit setOpen={setOpen}
                                       onSuccess={getAssociations}
                                       selectedId={selectedId}
                                       setSelectedId={setSelectedId}
                                       organizationId={organizationId}
                />
            </Modal.Body>
        </Modal>
        <div className="card-header border-0 pt-6">
            <div className="card-title">Prescripteurs partenaires</div>
            <div className="card-toolbar">
                <div className="d-flex justify-content-end" data-kt-customer-table-toolbar="base">
                    <button type="button" className="btn btn-primary"
                            onClick={(e) => {
                                e.preventDefault()
                                setSelectedId(undefined)
                                setOpen(true)
                            }}>Ajouter un prescripteur partenaire
                    </button>
                </div>
            </div>
        </div>
        <div className="card-body pt-0">
            <div id="kt_customers_table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer mt-8">
                <LoadableComponent loading={loading} component={<>{associations?.length === 0 ? <NotificationAlert
                        title={"Aucun prescripteur n'est abonné à votre laboratoire"}
                        message={"Veuillez ajouter un prescripteur"}
                    /> :
                    <div className="table-responsive">
                        <table className="table table-hover align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
                            <thead className="border-gray-200 fs-5 fw-semibold bg-lighten">
                            <tr>
                                <th className="min-w-125px sorting">Nom et prénom</th>
                                <th className="min-w-125px sorting">Code interne</th>
                                <th className="min-w-125px sorting">Spécialité</th>
                                <th className="min-w-125px sorting">Téléphone fixe</th>
                                <th className="min-w-125px sorting">Téléphone mobile</th>
                                <th className="min-w-125px sorting">E-mail</th>
                                <th className="min-w-125px sorting">Ville</th>
                            </tr>
                            </thead>
                            <tbody className="fw-semibold text-gray-600">
                            {associations?.map((association, idx) => <tr key={idx}
                                                                         onClick={()=> navigate(`/organizations/${organizationId}/associations/${association.id}`)}
                                                                         className={"cursor-pointer " + (idx % 2 === 0 ? "even" : "odd")}>
                                    <td data-order="2020-12-14T20:43:00+01:00">{normalizeText(association.doctorFullName, 120, "capitalize-each")}</td>
                                    <td data-order="2020-12-14T20:43:00+01:00">{association.internalCode}</td>
                                    <td>{normalizeText(association?.speciality, 40, "capitalize") || "Non renseigné"}</td>
                                    <td>{association?.phoneNumber || "Non renseigné"}</td>
                                    <td>{association?.mobilePhoneNumber || "Non renseigné"}</td>
                                    <td>{association?.email || "Non renseigné"}</td>
                                    <td>{normalizeText(association?.address?.city, 40, "capitalize") || "Non renseigné"}</td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>}</>}/>


            </div>
            {associations?.length !== 0 && <Pagination
                aroundCurrent={2}
                showFirstLast={true}
                showPrevNext={true}
                atBeginEnd={0}
                onChange={onPageChange}
                value={currentPage}
                totalPages={totalPages}
            />}
        </div>
    </div>
}
export default DoctorAssociation
