import React, {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {wrapRequest} from "../../helpers/auth";
import {toast} from "react-toastify";
import AlertConfigurationAPI from "../../api/AlertConfigurationAPI";
import {Input} from "../common/ui/Input";
import CustomSelect from "../common/ui/CustomSelect";
import AnalysisSettingsAPI from "../../api/AnalysisSettingsAPI";
import CustomAsyncSelect from "../common/ui/CustomAsyncSelect";
import {Button} from "../common/ui/Button";

export const AlertConfigurationEdit = ({onSuccess, selectedId, setOpen, organizationId, setAlertConfigurationName}) => {
    const effectRan = useRef(false);
    const dispatch = useDispatch()
    const [alert, setAlert] = useState({})
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState([])
    const types = [{value: "INTERVAL", label: "Intervalle"},
        {value: "MULTIPLE", label: "Multiple de la valeur supérieure"},
        {value: "QUALITATIVE", label: "Qualitative"},
        {value: "NONE", label: "Aucune"}]
    const values = [
        {value: "POSITIVE", label: "Positive"},
        {value: "NEGATIVE", label: "Négative"}]

    const onSend = (e) => {
        e.preventDefault()
        return wrapRequest(dispatch, selectedId ?
            AlertConfigurationAPI.update(alert, selectedId) :
            AlertConfigurationAPI.create({
                ...alert,
                organizationId: organizationId
            }))
            .then(response => {
                if (response?.status === 201) {
                    setOpen(false)
                    toast.success("Nouvelle alerte créée");
                    onSuccess(0, 10)
                } else if (response?.status === 204) {
                    setOpen(false)
                    toast.success("Alerte mise à jour");
                    getAlertToUpdate().then(() => console.log("reloaded"))
                } else if (response?.status === 400) {
                    setErrors(response.data.errors)
                } else if (response?.status === 409) {
                    toast.error("Une code similaire existe déjà ! ")
                } else {
                    setOpen(false)
                }
            })
    }

    const getAlertToUpdate = async () => {
        return wrapRequest(dispatch, AlertConfigurationAPI.get(selectedId)).then(response => {
            if (response.status === 200) {
                setAlert({
                    ...alert,
                    code: response.data.code,
                    internalCode: response.data.internalCode,
                    alertType: response.data.alertType,
                    minThreshold: response.data.minThreshold,
                    maxThreshold: response.data.maxThreshold,
                    alertValue: response.data.alertValue,
                    analysisSettingId: response.data.analysisSettingId,
                    unit: response.data.unit
                })
                setAlertConfigurationName(`${response.data.code} - ${response.data.label}`)
            }
        })
    }

    useEffect(() => {
        if (!effectRan.current) {
            if (selectedId) {
                getAlertToUpdate().then(() => console.log("reloaded"))
            }
        }
        return () => effectRan.current = true;
    }, []);

    const loadOptions = async (inputValue) => {
        const response = await wrapRequest(dispatch, AnalysisSettingsAPI.getAnalysisSettings(0, 5000, inputValue))
        return response.data.content.map(element => {
            return {
                value: element.id,
                label: `${element.code} - ${element.label} - ${element.category}`
            }
        })
    }

    const handleInputChange = (newValue) => {
        return newValue
    }
    return <>
        <div className="row">
            <CustomAsyncSelect
                wrapperClass="col-lg-6 mb-7 fv-plugins-icon-container"
                label={`Code LOINC ${selectedId !== undefined ? `(actuel : ${alert.code})` : ""}`}
                name="analysisSettingsId"
                labelClassName="required fs-6 fw-semibold mb-2"
                className="form-control form-control-solid"
                onChange={(data) => setAlert({...alert, analysisSettingId: data.value})}
                isSearchable={true}
                isLoading={false}
                placeholder={selectedId ? alert.code : "Veuillez sélectionner un code LOINC"}
                errors={errors}
                loadOptions={loadOptions}
                handleInputChange={handleInputChange}
            />
            <Input
                wrapperClass="col-lg-6  mb-7 fv-plugins-icon-container"
                label="Code interne"
                labelClassName="required fs-6 fw-semibold mb-2"
                className="form-control form-control-solid"
                name="internalCode"
                value={alert.internalCode || ""}
                onChange={(e) => setAlert({...alert, internalCode: e.target.value})}
                errors={errors}
            />
        </div>
        <div className="row">
            <CustomSelect
                wrapperClass="col-lg-6  mb-7 fv-plugins-icon-container"
                label="Type d'alerte"
                name="alertType"
                labelClassName="required fs-6 fw-semibold mb-2"
                className="form-control form-control-solid"
                onChange={(data) => setAlert({...alert, alertType: data.value})}
                options={types}
                isSearchable={true}
                isLoading={false}
                placeholder={"Veuillez sélectionner un type d'alerte"}
                value={types.filter((x) =>
                    alert?.alertType?.includes(x.value)
                )}
                errors={errors}
            />
            {alert?.alertType === "QUALITATIVE" && <CustomSelect
                wrapperClass="col-lg-6  mb-7 fv-plugins-icon-container"
                label="Valeur de l'alerte"
                name="alertValue"
                labelClassName="required fs-6 fw-semibold mb-2"
                className="form-control form-control-solid"
                onChange={(data) => setAlert({...alert, alertValue: data.value})}
                options={values}
                isSearchable={true}
                isLoading={false}
                placeholder={"Veuillez sélectionner une valeur de déclenchement de l'alerte"}
                value={values.filter((x) =>
                    alert?.alertValue?.includes(x.value)
                )}
                errors={errors}
            />}
            {alert?.alertType === "MULTIPLE" && <Input
                wrapperClass="col-lg-6  mb-7 fv-plugins-icon-container"
                label="Multiple de la valeur supérieure"
                labelClassName="required fs-6 fw-semibold mb-2"
                className="form-control form-control-solid"
                name="multipleValue"
                value={alert.multipleValue || ""}
                onChange={(e) => setAlert({...alert, multipleValue: e.target.value})}
                errors={errors}
                type={"number"}
            />}
            {alert?.alertType === "INTERVAL" && <><Input
                wrapperClass="col-lg-6  mb-7 fv-plugins-icon-container"
                label="Seuil minimal"
                labelClassName="required fs-6 fw-semibold mb-2"
                className="form-control form-control-solid"
                name="minThreshold"
                value={alert.minThreshold || ""}
                onChange={(e) => setAlert({...alert, minThreshold: e.target.value})}
                errors={errors}
                type={"number"}
            />
                <Input
                    wrapperClass="col-lg-6  mb-7 fv-plugins-icon-container"
                    label="Seuil maximal"
                    labelClassName="required fs-6 fw-semibold mb-2"
                    className="form-control form-control-solid"
                    name="maxThreshold"
                    value={alert.maxThreshold || ""}
                    onChange={(e) => setAlert({...alert, maxThreshold: e.target.value})}
                    errors={errors}
                    type={"number"}
                /></>}
            <Input
                wrapperClass="col-lg-6 mb-7 fv-plugins-icon-container"
                label="Unité (optionnelle)"
                labelClassName="required fs-6 fw-semibold mb-2"
                className="form-control form-control-solid"
                name="unit"
                value={alert.unit || ""}
                onChange={(e) => setAlert({...alert, unit: e.target.value})}
                errors={errors}
            />
        </div>
        <div className="d-flex flex-end mt-10">
            <Button onClick={onSend} loading={loading}
                    wrapperClass=""
                    label={"Enregistrer"}
            />
        </div>
    </>
}
