import React, {useState} from "react";
import Cropper from "react-easy-crop";

const ImageUploader = ({imageRendered, setCroppedAreaPixels, scale}) => {

    const [crop, setCrop] = useState({x: 0, y: 0})
    const [zoom, setZoom] = useState(1)
    const [aspect, setAspect] = useState(1)
    const [rotation, setRotation] = useState(0)

    const onCropChange = (crop) => {
        setCrop(crop)
    }

    const onCropComplete = (croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }

    const onZoomChange = (zoom) => {
        setZoom(zoom)
    }

    return <div className="crop-container" id="cropper">
                <Cropper
                    image={imageRendered}
                    crop={crop}
                    zoom={zoom}
                    rotation={rotation}
                    aspect={scale ? scale : aspect}
                    onCropChange={onCropChange}
                    onCropComplete={onCropComplete}
                    onZoomChange={onZoomChange}/>

    </div>

}

export default ImageUploader;

