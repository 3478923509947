import React, {useState} from "react";
import {normalizeText} from "../../helpers/textUtils";
import ConditionalOverlay from "../common/ui/ConditionalOverlay";
import {Modal} from "react-bootstrap";
import LaboratoryContactModal from "./LaboratoryContactModal";

const LaboratoryCell = ({record, profile}) => {

    const [open, setOpen] = useState(false)

    const getClientName = () => {
        if (profile?.roles?.includes("ROLE_SUPER_ADMIN")) {
            return <ConditionalOverlay
                message={`Client : ${record?.organizationName}`}
                position="top"
                component={
                    <i className="fa-solid fa-building text-primary fs-5 ms-2"></i>
                }
            />
        }
    }

    return <>
        <Modal show={open} size="md">
            <Modal.Header
                closeButton={true}
                onHide={() => {
                    setOpen(false)
                }}
            >
                <Modal.Title>{`Laboratoire : ${normalizeText(record?.laboratoryName, 30, "capitalize-each")}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <LaboratoryContactModal record={record}/>
            </Modal.Body>
        </Modal>
        <td>
            <div className="btn btn-outline btn-outline-dashed btn-outline-dark w-100" onClick={(e) => {
                if(record?.laboratoryName){
                    setOpen(true)
                }
            }}>
                {`${normalizeText(record?.laboratoryName || "Laboratoire inconnu", 30, "capitalize-each")} (Dossier ${normalizeText(record?.reference, 20, "none")})`}
                {getClientName()}
            </div>
        </td>
    </>
}
export default LaboratoryCell;
