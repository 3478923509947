import React, {useEffect, useRef, useState} from "react"
import {Link, useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {wrapRequest} from "../../../helpers/auth";
import {LoadableComponent} from "../../common/ui/LoadableComponent";
import NotificationAlert from "../../common/ui/NotificationAlert";
import Pagination from "@vlsergey/react-bootstrap-pagination";
import {Modal} from "react-bootstrap";
import RecommendationModal from "./RecommendationModal";
import Swal from "sweetalert2";
import {toast} from "react-toastify";
import AlertConfigurationAPI from "../../../api/AlertConfigurationAPI";
import RecommendationAPI from "../../../api/RecommendationAPI";

const Recommendations = ({alertConfigurationId, setAlertConfigurationName}) => {
    const [loading, setLoading] = useState(false)
    const effectRan = useRef(false);
    const dispatch = useDispatch()
    const params = useParams()
    const [alertConfiguration, setAlertConfiguration] = useState({})
    const [recommendations, setRecommendations] = useState([])
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [totalResults, setTotalResults] = useState(0);
    const [open, setOpen] = useState(false)
    const [selectedId, setSelectedId] = useState()


    const getAlertConfiguration = () => {
        return wrapRequest(dispatch, AlertConfigurationAPI.get(alertConfigurationId))
            .then(response => {
                if (response?.status === 200) {
                    setAlertConfiguration(response.data)
                    setAlertConfigurationName(`${response.data.code} - ${response.data.label}`)
                    return response
                }
            })
    }

    const getRecommendations = () => {
        return wrapRequest(dispatch, RecommendationAPI.getRecommendations(0, 10, alertConfigurationId))
            .then(response => {
                if (response?.status === 200) {
                    setRecommendations(response.data.content)
                    setTotalPages(response.data.totalPages)
                    setCurrentPage(response.data.number)
                    setTotalResults(response.data.totalElements)
                    return response
                }
            })
    }

    const onUpdate = (e) => {
        e.preventDefault()
        const id = e.target.id
        setSelectedId(id)
        setOpen(true)
    }

    const onDelete = (e) => {
        e.preventDefault()
        const id = e.target.id
        Swal.fire({
            title: "Voulez-vous supprimer cette recommandation?",
            text: "Attention ! Cette action est irréversible",
            showCancelButton: true,
            confirmButtonText: "Oui",
            cancelButtonText: `Non`,
            icon: "warning"
        }).then((result) => {
            if (result.isConfirmed) {
                return wrapRequest(dispatch, RecommendationAPI.remove(alertConfigurationId, id))
                    .then(response => {
                        if (response.status === 204) {
                            toast.success("Recommandation supprimée")
                            getRecommendations(0, 10)
                        }
                    })
            }
        });
    }

    useEffect(() => {
        if (!effectRan.current) {
            setLoading(true)
            getAlertConfiguration()
                .then(() => getRecommendations(0, 10))
                .finally(() => {
                    setLoading(false)
                })
        }
        return () => effectRan.current = true;
    }, []);

    const onPageChange = ({target}) => {
        const page = target.value;
        getRecommendations(page, 10);
    }

    return <div className="card">
        <Modal show={open} size="lg">
            <Modal.Header
                closeButton={true}
                onHide={() => {
                    setSelectedId(undefined)
                    setOpen(false)
                }}
            >
                <Modal.Title>{selectedId ? "Mettre à jour une recommandation" : "Ajouter une nouvelle recommandation"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <RecommendationModal alertConfigurationId={alertConfigurationId} onSuccess={getRecommendations} setOpen={setOpen}
                                     selectedId={selectedId}/>
            </Modal.Body>
        </Modal>
        <div className="card-header border-0 pt-6">
            <div className="card-title">Recommandations ({totalResults} trouvé{totalResults > 1 ? "s" : ""})
            </div>
            <div className="card-toolbar">
                <div className="d-flex justify-content-end" data-kt-customer-table-toolbar="base">
                    <button type="button" className="btn btn-primary"
                            onClick={(e) => {
                                e.preventDefault()
                                setSelectedId(undefined)
                                setOpen(true)
                            }}>Ajouter une nouvelle recommandation
                    </button>
                </div>
            </div>
        </div>
        <div className="card-body pt-0">
            <div id="kt_customers_table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer mt-8">
                <LoadableComponent loading={loading} component={<>{recommendations?.length === 0 ? <NotificationAlert
                        title={"Aucune recommandation trouvé"}
                        message={"Veuillez créer une nouvelle recommandation"}
                    /> :
                    <div className="table-responsive">
                        <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
                            <thead className="border-gray-200 fs-5 fw-semibold bg-lighten">
                            <tr>
                                <th className="min-w-125px sorting">Titre</th>
                                <th className="min-w-125px sorting">Actions</th>
                            </tr>
                            </thead>
                            <tbody className="fw-semibold text-gray-600">
                            {recommendations?.map((recommendation, idx) => <tr key={idx}
                                                                 className={idx % 2 === 0 ? "even" : "odd"}>
                                    <td>{recommendation?.title || "Non renseigné"}</td>
                                    <td>
                                        <button
                                            id={recommendation.id}
                                            onClick={onUpdate}
                                            className="btn btn-icon btn-bg-light btn-sm me-1">
                                        <span className="svg-icon svg-icon-3" id={recommendation.id}>
																			<i className="fa-solid fa-pen"
                                                                               id={recommendation.id}></i>
                                        </span></button>
                                        <button
                                            id={recommendation.id}
                                            onClick={onDelete} className="btn btn-icon btn-bg-light btn-sm me-1">
                                        <span className="svg-icon svg-icon-3" id={recommendation.id}>
																			<i className="fa-solid fa-trash"
                                                                               id={recommendation.id}
                                                                            ></i>
                                        </span>
                                        </button>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                        <Pagination
                            aroundCurrent={2}
                            showFirstLast={true}
                            showPrevNext={true}
                            atBeginEnd={0}
                            onChange={onPageChange}
                            value={currentPage}
                            totalPages={totalPages}
                        />
                    </div>}
                </>}/>
            </div>
        </div>

    </div>
}

export default Recommendations
