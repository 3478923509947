import {normalizeText} from "../../helpers/textUtils";
import React, {useEffect, useState} from "react";
import {Modal} from "react-bootstrap";
import RecommendationView from "./RecommendationView";
import {Checkbox} from "../common/ui/Checkbox";


export const ResultTable = ({
                                validatedCodes,
                                setValidatedCodes,
                                record,
                                validationMode = false,
                                refreshRecord,
                                profile
                            }) => {

    const [open, setOpen] = useState(false)
    const [selectedAlertId, setSelectedAlertId] = useState()
    const [selectAll, setSelectAll] = useState(false)

    const getResultLineColor = (result) => {
        if (result.urgent) {
            return "text-danger fw-bold"
        }
        if (result.abnormal) {
            return "text-dark-warning fw-bold"
        }
        return ""
    }

    const getBiologicalValidationStatus = (result) => {
        return result.valid ? <i className="fs-2 fa-solid fa-circle-check text-success"></i> :
            <i className="fs-2 fa-solid fa-circle-xmark text-secondary"></i>
    }

    const getTextValue = (record, result) => {
        if (profile?.roles?.includes("ROLE_DOCTOR")
            && record.biologicalValidationStatus === "biologicalValidationStatus") {
            return <td className={getResultLineColor(result)}>En attente de validation</td>
        }
        return <td
            className={getResultLineColor(result)}>{result.value} {normalizeText(result.unit, 100, "lowercase")}</td>
    }

    const getSwitchButton = (result) => {
        return <Checkbox
            label={""}
            checked={validatedCodes.includes(result.internalCode)}
            onChange={() => {
                changeValidationStatus(result, validatedCodes.includes(result.internalCode))
            }}
            wrapperClassName={"flex flex-center mb-5 ms-5"}
            rightOption={"  "}
            leftOption={"  "}
        />
    }

    useEffect(() => {
        const initialValidatedCodes = record?.analysisRecords?.filter(result => result.valid).map(result => result.internalCode)
        setValidatedCodes(initialValidatedCodes)
        setSelectAll(initialValidatedCodes.length === record?.analysisRecords.length)
    }, [])

    const changeValidationStatus = (result, inArray) => {
        let effectiveValidatedCodes = validatedCodes
        if (!inArray) {
            effectiveValidatedCodes.push(result.internalCode)
        } else {
            effectiveValidatedCodes = effectiveValidatedCodes.filter(e => e !== result.internalCode)
        }
        setValidatedCodes(effectiveValidatedCodes)
        refreshRecord()
    }

    const globalSelect = () => {
        if (selectAll) {
            setValidatedCodes([])
        } else {
            setValidatedCodes(record?.analysisRecords?.map(result => result.internalCode))
        }
        refreshRecord()
    }

    const getValidResultOnly = (record) => {
        return record?.analysisRecords?.filter(result => result.valid)
    }


    return <div className="table-responsive">
        <Modal show={open} fullscreen>
            <Modal.Header
                closeButton={true}
                onHide={() => {
                    setSelectedAlertId(undefined)
                    setOpen(false)
                }}
            >
                <Modal.Title>{"Recommandations et actualités"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <RecommendationView alertId={selectedAlertId}/>
            </Modal.Body>
        </Modal>
        <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
            <thead className="border-gray-200 fs-5 fw-semibold bg-lighten">
            <tr>
                <th width="50%" className="min-w-125px">Dénomination</th>
                <th width="10%" className="min-w-125px">Résultats</th>
                <th width="10%" className="min-w-125px">Seuils</th>
                <th width="10%" className="min-w-125px text-center">Validation</th>
                <th className="min-w-125px  text-center">Recommandations</th>
            </tr>
            {validationMode && <tr>
                <th width="50%" className="min-w-125px"></th>
                <th width="10%" className="min-w-125px"></th>
                <th width="10%" className="min-w-125px"></th>
                <th width="10%" className="min-w-125px text-center"><Checkbox
                    label={""}
                    checked={selectAll}
                    onChange={() => {
                        setSelectAll(!selectAll)
                        globalSelect()
                    }}
                    wrapperClassName={"flex flex-center mb-5 ms-5"}
                    rightOption={"  "}
                    leftOption={"  "}
                /></th>
                <th className="min-w-125px  text-center"></th>
            </tr>}
            </thead>
            <tbody className="fw-semibold text-gray-600">
            {(profile?.roles.includes("ROLE_DOCTOR") ? getValidResultOnly(record) : record?.analysisRecords)?.sort((a, b) => a.label.localeCompare(b.label))
                .map((result, idx) => <tr key={idx}
                                          className={idx % 2 === 0 ? "even" : "odd"}>
                        <td className={getResultLineColor(result)}>{normalizeText(result?.label, 100, "capitalize-each")}</td>
                        {getTextValue(record, result)}
                        <td className={getResultLineColor(result)}>{result.minThreshold} - {result.maxThreshold}</td>
                        <td className="text-center">{validationMode ? getSwitchButton(result) : getBiologicalValidationStatus(result)}</td>
                        <td className="text-center"><a className="btn btn-sm btn-light-primary" href="#"
                                                       onClick={() => {
                                                           setSelectedAlertId(result.alertConfigurationId)
                                                           setOpen(true)
                                                       }
                                                       }>Voir</a></td>
                    </tr>
                )}
            </tbody>
        </table>
    </div>
}
