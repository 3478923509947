import React, {forwardRef, useEffect, useState} from "react"
import {useSSR, useTranslation} from "react-i18next";
import {logOut} from "../../helpers/auth";
import withClickOutside from "../../helpers/hooks/withClickOutside";
import {Modal} from "react-bootstrap";
import UserEdit from "../users/UserEdit";
import {getRoleBadge} from "../../helpers/methods";

const DropDownMenu = forwardRef(({open, setOpen, props}, ref) => {

    const {t} = useTranslation()
    const [openProfile, setOpenProfile] = useState(false)

    useEffect(() => {
        if (window.innerWidth < 990) {
            $(".menu-block").css({
                transform: 'translate3d(0px, 60px, 0)',
            });
        } else if (window.innerWidth < 1400) {
            $(".menu-block").css({
                transform: 'translate3d(-20px, 60px, 0)',
            });
        } else {
            $(".menu-block").css({
                transform: 'translate3d(-' + (window.innerWidth - 1600) / 5 + 'px, 60px, 0)',
            });
        }
    }, [window.innerWidth])


    return <>
        <Modal show={openProfile} size="lg">
            <Modal.Header
                closeButton={true}
                onHide={() => {
                    setOpenProfile(false)
                }}
            >
                <Modal.Title>Mon profil</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <UserEdit setOpen={setOpenProfile}
                          onSuccess={()=>{}}
                          setUserFullName={()=> {}}
                          onEditProfile={true}
                          selectedId={props.profile.id}
                          organizationId={props.profile.organizationId}
                />
            </Modal.Body>
        </Modal>

        <div className="d-flex align-items-center ms-1 ms-lg-3"
                ref={ref}
                onClick={e => {
                    e.preventDefault()
                    setOpen(!open)
                }}>
        <div className="cursor-pointer symbol symbol-30px symbol-md-40px show menu-dropdown text-white"
        >
            <div
                className="symbol-label fs-3 bg-light-primary text-primary">
                {`${props.profile?.lastName?.substring(0, 1)}${props.profile?.firstName?.substring(0, 1)}`}
            </div>
        </div>
        <div
            className={`menu menu-block menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px ${open ? "show" : ""}`}
            style={{
                zIndex: 105,
                position: "fixed",
                inset: "0px 0px auto auto",
                margin: "0px"
            }}>
            <div className="menu-item px-3">
                <div className="menu-content d-flex align-items-center px-3">
                    <div className="cursor-pointer symbol symbol-50px show menu-dropdown">
                        <div
                            className="symbol-label fs-3 bg-light-primary text-primary">
                            {`${props.profile?.lastName?.substring(0, 1)}${props.profile?.firstName?.substring(0, 1)}`}
                        </div>
                    </div>
                    <div className="d-flex flex-column">
                        <div
                            className="fw-bold d-flex align-items-center fs-5  ms-2">{props.profile.lastName} {props.profile.firstName}</div>
                        <a href="#"
                           className="fw-semibold text-muted text-hover-primary fs-7">{getRoleBadge(props.profile, t, 1)}</a>
                    </div>
                </div>
            </div>
            <div className="menu-item px-5">
                <a onClick={e => {
                    e.preventDefault()
                    setOpenProfile(true)
                }}
                   className="menu-link px-5">Mon profil</a>
            </div>
            <div className="separator my-2"></div>
            <div className="menu-item px-5">
                <a onClick={(e) => {
                    e.preventDefault()
                    logOut()
                }}
                   className="menu-link px-5">Se déconnecter</a>
            </div>
        </div>
    </div></>

})

export default withClickOutside(DropDownMenu);
