import {Input} from "../common/ui/Input";
import React, {useEffect, useRef, useState} from "react";
import {Button} from "../common/ui/Button";
import {useDispatch} from "react-redux";
import {wrapRequest} from "../../helpers/auth";
import LabelAPI from "../../api/LabelAPI";
import {toast} from "react-toastify";

export const SpecialityModal = ({setOpen, onSuccess, selectedId}) => {
    const effectRan = useRef(false);
    const dispatch = useDispatch()
    const [speciality, setSpeciality] = useState({})
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState([])

    const onSend = (e) => {
        e.preventDefault()
        return wrapRequest(dispatch, selectedId ?
            LabelAPI.update({...speciality, type: "SPECIALITY"}, selectedId) :
            LabelAPI.create({...speciality, type: "SPECIALITY"}))
            .then(response => {
                if (response?.status === 201) {
                    setOpen(false)
                    toast.success("Spécialité ajoutée");
                    onSuccess(0, 10)
                } else if (response?.status === 204) {
                    setOpen(false)
                    toast.success("Spécialité mise à jour");
                    onSuccess(0, 10)
                } else if (response?.status === 400) {
                    setErrors(response.data.errors)
                } else if (response.status === 409) {
                    setOpen(false)
                    toast.error("Spécialité déjà présente");
                } else {
                    setOpen(false)
                }
            })
    }

    const getSpecialityToUpdate = async () => {
        return wrapRequest(dispatch, LabelAPI.get(selectedId))
    }

    useEffect(() => {
        if (!effectRan.current) {
            if(selectedId){
                getSpecialityToUpdate().then(response => {
                    if(response.status === 200){
                        setSpeciality(response.data)
                    }
                })
            }
        }
        return () => effectRan.current = true;
    }, []);


    return <>
        <Input
            wrapperClass="fv-row mb-7 fv-plugins-icon-container"
            label="Nom de la spécialité"
            labelClassName="required fs-6 fw-semibold mb-2"
            className="form-control form-control-solid"
            name="name"
            value={speciality.name || ""}
            onChange={(e) => setSpeciality({...speciality, name: e.target.value})}
            errors={errors}
        />
        <div className="d-flex flex-end">
            <Button onClick={onSend} loading={loading}
                    wrapperClass=""
                    label={"Enregistrer"}
            />
        </div>
    </>


}
