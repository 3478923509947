export function stripHtml(html) {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
}

export function normalizeText(sequence, maxChars, transformation = "capitalize") {
    if (!sequence) {
        return undefined
    }
    let transformedText = sequence?.length <= maxChars ? sequence : `${sequence?.substring(0, maxChars - 1)}...`
    switch (transformation) {
        case "lowercase":
            return transformedText?.toLowerCase()
        case "uppercase":
            return transformedText?.toUpperCase()
        case "capitalize":
            return transformedText?.substring(0, 1)?.toUpperCase() + transformedText?.substring(1, transformedText?.length)?.toLowerCase()
        case "capitalize-each":
            let words = transformedText?.split(" ");
            for (let i = 0; i < words.length; i++) {
                words[i] = words[i][0]?.toUpperCase() + words[i].substring(1)?.toLowerCase();
            }
            return words.join(" ")
        case "none":
        default:
            return transformedText
    }
}

export const isEmpty = (value) => {
    return (
        value === undefined ||
        value === null ||
        (typeof value === "object" && Object.keys(value).length === 0) ||
        (typeof value === "string" && value.trim().length === 0)
    );
};


