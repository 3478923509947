import * as types from "./actionTypes";

export function resetErrorsSuccess(error) {
    return {type: types.RESET_ERRORS, error};
}

export function resetErrors() {
    return function (dispatch) {
        dispatch(resetErrorsSuccess("reset"));
    };
}
