import {Input} from "../common/ui/Input";
import React, {useState} from "react";
import {Button} from "../common/ui/Button";
import {wrapRequest} from "../../helpers/auth";
import {toast} from "react-toastify";
import {useDispatch} from "react-redux";
import AcquisitionConnectorAPI from "../../api/AcquisitionConnectorAPI";

export const AcquisitionConnectorModal = ({organizationId, setOpen, onSuccess}) => {

    const [connector, setConnector] = useState({})
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const [errors, setErrors] = useState([])

    const onSend = (e) => {
        setLoading(true)
        e.preventDefault()
        return wrapRequest(dispatch, AcquisitionConnectorAPI.create({...connector, organizationId: organizationId, type: "NYNA"}))
            .then(response => {
                setLoading(false)
                if (response?.status === 201) {
                    setOpen(false)
                    toast.success("Nouveau connecteur ajouté");
                    onSuccess(0, 10)
                } else if (response?.status === 400) {
                    setErrors(response.data.errors)
                } else {
                    setOpen(false)
                }
            })
    }

    return <><Input
        wrapperClass="fv-row mb-7 fv-plugins-icon-container"
        label="Nom du dispositif"
        labelClassName="required fs-6 fw-semibold mb-2"
        className="form-control form-control-solid"
        name="name"
        value={connector.name || ""}
        onChange={(e) => setConnector({...connector, name: e.target.value})}
        errors={errors}
    />
        <div className="d-flex flex-end">
            <Button onClick={onSend} loading={loading}
                    wrapperClass=""
                    label={"Enregistrer"}
            />
        </div>
    </>
}
