import axios from "axios";
import {requestHeaders} from "../helpers/constants";

class AnalysisSettingsAPI {
    static getAnalysisSettings(page, size, keywords) {
        let url = `${process.env.API_BASE_URL}/analysis-settings?page=${page}&size=${size}`
        if (keywords) {
            url += `&keywords=${keywords}`
        }
        return axios({
            method: 'get',
            headers: requestHeaders(),
            url: url,
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }
}

export default AnalysisSettingsAPI
