import React from "react"
import {AcquisitionConnector} from "./AcquisitionConnector";
import Webhook from "./Webhook";
import Connectors from "./Connectors";

export const Integrations = ({organizationId}) => {

    return <>
        <AcquisitionConnector organizationId={organizationId}/>

        <Webhook organizationId={organizationId}/>

        <Connectors organizationId={organizationId}/>
    </>
}
