import React, {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {wrapRequest} from "../../helpers/auth";
import AlertConfigurationAPI from "../../api/AlertConfigurationAPI";
import {Modal} from "react-bootstrap";
import NotificationAlert from "../common/ui/NotificationAlert";
import Pagination from "@vlsergey/react-bootstrap-pagination";
import {AlertConfigurationEdit} from "./AlertConfigurationEdit";
import {LoadableComponent} from "../common/ui/LoadableComponent";

const AlertConfigurations = ({organizationId}) => {
    const effectRan = useRef(false);
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)
    const [alerts, setAlerts] = useState([]);
    const {t} = useTranslation()
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [totalResults, setTotalResults] = useState(0);
    const [loading, setLoading] = useState(false)
    const [selectedId, setSelectedId] = useState()
    const navigate = useNavigate()
    const onPageChange = ({target}) => {
        const page = target.value;
        if (organizationId) {
            navigate({
                pathname: `/organizations/${organizationId}?tab=config`,
                search: `&page=${page}`,
            });
        } else {
            navigate({
                pathname: `/settings?tab=alerts`,
                search: `&page=${page}`,
            });
        }
        getAlerts(page, 10);
    }

    const getAlerts = (page, size) => {
        setLoading(true)
        return wrapRequest(dispatch, AlertConfigurationAPI.getAlertConfigurations(page, size, organizationId))
            .then(response => {
                setLoading(false)
                if (response?.status === 200) {
                    setAlerts(response.data.content)
                    setTotalPages(response.data.totalPages)
                    setCurrentPage(response.data.number)
                    setTotalResults(response.data.totalElements)
                }
            })
    }

    useEffect(() => {
        if (!effectRan.current) {
            getAlerts(0, 10)
        }
        return () => effectRan.current = true;
    }, []);

    const getAlertType = (alert) => {
        switch (alert.alertType) {
            case "INTERVAL":
                return "Intervalle"
            case "MULTIPLE":
                return "Multiple de la valeur supérieure"
            case "QUALITATIVE":
                return "Qualitative"
            default:
                return "Aucune"
        }
    }

    const getAlertValue = (alert) => {
        switch (alert.alertType) {
            case "INTERVAL":
                return `${alert.minThreshold} -> ${alert.maxThreshold}`
            case "MULTIPLE":
                return alert.alertValue
            case "QUALITATIVE":
                return alert.alertValue === "POSITIVE" ? "Positive" : "Négative"
            default:
                return "N-A"
        }
    }

    return <div className="card">
        <Modal show={open} size="lg">
            <Modal.Header
                closeButton={true}
                onHide={() => {
                    setSelectedId(undefined)
                    setOpen(false)
                }}
            >
                <Modal.Title>{selectedId ? "Mettre à jour un paramétrage d'analyse" : "Ajouter un paramétrage d'analyse"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <AlertConfigurationEdit organizationId={organizationId} selectedId={selectedId} onSuccess={getAlerts}
                                        setOpen={setOpen}/>
            </Modal.Body>
        </Modal>
        <div className="card-header border-0 pt-6">
            <div className="card-title">Paramétrage des analyses ({totalResults} trouvé{totalResults > 1 ? "s" : ""})
            </div>
            <div className="card-toolbar">
                <div className="d-flex justify-content-end" data-kt-customer-table-toolbar="base">
                    <button type="button" className="btn btn-primary"
                            onClick={(e) => {
                                e.preventDefault()
                                setSelectedId(undefined)
                                setOpen(true)
                            }}>Ajouter un paramétrage d'analyse
                    </button>
                </div>
            </div>
        </div>

        <div className="card-body pt-0">
            <div id="kt_customers_table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer mt-8">
                <LoadableComponent loading={loading} component={<>{alerts?.length === 0 ? <NotificationAlert
                        title={"Aucun paramètre d'analyse trouvé"}
                        message={"Veuillez créer un paramètre d'analyse"}
                    /> :
                    <div className="table-responsive">
                        <table
                            className="table table-hover align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
                            <thead className="border-gray-200 fs-5 fw-semibold bg-lighten">
                            <tr>
                                <th className="min-w-125px sorting">Code Loinc</th>
                                <th className="min-w-125px sorting">Intitulé</th>
                                <th className="min-w-125px sorting">Unité</th>
                                <th className="min-w-125px sorting">Catégorie</th>
                                <th className="min-w-125px sorting">Synonyme</th>
                                <th className="min-w-125px sorting">Type d'alerte</th>
                                <th className="min-w-125px sorting">Valeur de l'alerte</th>
                                <th className="min-w-125px sorting">Code interne</th>

                            </tr>
                            </thead>
                            <tbody className="fw-semibold text-gray-600">
                            {alerts?.map((alert, idx) => <tr key={idx}
                                                             onClick={() => navigate(organizationId ? `/organizations/${organizationId}/alert-configurations/${alert.id}` :
                                                                 `/settings/alert-configurations/${alert.id}`)}
                                                             className={"cursor-pointer " + (idx % 2 === 0 ? "even" : "odd")}>
                                    <td>{alert?.code || "Non renseigné"}</td>
                                    <td>{alert?.label || "Non renseigné"}</td>
                                    <td>{alert?.unit || "Non renseigné"}</td>
                                    <td>{alert?.category || "Non renseigné"}</td>
                                    <td>{alert?.synonyms || "Non renseigné"}</td>
                                    <td>{getAlertType(alert) || "Non renseigné"}</td>
                                    <td>{getAlertValue(alert) || "Non renseigné"}</td>
                                    <td>{alert.internalCode || "Non renseigné"}</td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>}</>}/>
                {alerts.length !== 0 && <div className="mt-3">
                    <Pagination
                        aroundCurrent={2}
                        showFirstLast={true}
                        showPrevNext={true}
                        atBeginEnd={0}
                        onChange={onPageChange}
                        value={currentPage}
                        totalPages={totalPages}
                    />
                </div>}
            </div>
        </div>
    </div>
}

export default AlertConfigurations
