import React, {useEffect, useRef, useState} from "react"
import {Input} from "../common/ui/Input";
import {wrapRequest} from "../../helpers/auth";
import {toast} from "react-toastify";
import {useDispatch} from "react-redux";
import DeviceAPI from "../../api/DeviceAPI";
import {Button} from "../common/ui/Button";

const DeviceEdit = ({setOpen, onSuccess, selectedId}) => {
    const effectRan = useRef(false);
    const dispatch = useDispatch()
    const [device, setDevice] = useState({})
    const [errors, setErrors] = useState([])
    const [loading, setLoading] = useState(false)


    const onSend = (e) => {
        e.preventDefault()
        setLoading(true)
        return wrapRequest(dispatch, selectedId ?
            DeviceAPI.update(device, selectedId) :
            DeviceAPI.create(device))
            .then(response => {
                setLoading(false)
                if (response?.status === 201) {
                    setOpen(false)
                    toast.success("Nouveau dispositif enregistré");
                    onSuccess(0, 10)
                } else if (response?.status === 204) {
                    setOpen(false)
                    toast.success("Dispositif mise à jour");
                    onSuccess(0, 10)

                } else if (response?.status === 400) {
                    setErrors(response.data.errors)
                } else {
                    setOpen(false)
                }
            })
    }

    const getDeviceToUpdate = async () => {
        return wrapRequest(dispatch, DeviceAPI.get(selectedId))
    }

    useEffect(() => {
        if (!effectRan.current) {
            if (selectedId) {
                getDeviceToUpdate().then(response => {
                    if (response.status === 200) {
                        setDevice(response.data)
                    }
                })
            }
        }
        return () => effectRan.current = true;
    }, []);

    return <>
        <div className="row">
            <Input
                wrapperClass="col-lg-6 mb-7 fv-plugins-icon-container"
                label="Label"
                labelClassName="required fs-6 fw-semibold mb-2"
                className="form-control form-control-solid"
                name="label"
                value={device.label || ""}
                onChange={(e) => setDevice({...device, label: e.target.value})}
                errors={errors}
            />
            <Input
                wrapperClass="col-lg-6 mb-7 fv-plugins-icon-container"
                label="Identifiant (Adresse MAC)"
                labelClassName="required fs-6 fw-semibold mb-2"
                className="form-control form-control-solid"
                name="deviceNumber"
                value={device.deviceNumber || ""}
                onChange={(e) => setDevice({...device, deviceNumber: e.target.value})}
                errors={errors}
            />
        </div>
        <div className="d-flex flex-end">
            <Button onClick={onSend} loading={loading}
                    wrapperClass=""
                    label={"Enregistrer"}
            />
        </div>
    </>
}
export default DeviceEdit
