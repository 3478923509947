import React from "react"
import PatientEdit from "./PatientEdit";
import {Link, useParams} from "react-router-dom";

const PatientDetails = ({id}) => {
    const params = useParams();
    const definitiveId = id ? id : params.id
    return <>
        <div className="card">
            <div className="card-header border-0 pt-6 mb-8">

                <div className="card-title">
                    <Link
                        to={`/patients`}
                        className="btn btn-icon btn-circle btn-bg-light btn-sm me-3">
                <span className="svg-icon svg-icon-3">
																			<i className="fa-solid fa-arrow-left"
                                                                            ></i>
                </span></Link>
                    Edition du patient
                </div>
            </div>
            <div className="card-body pt-0">
                <PatientEdit
                    onSuccess={() => {
                    }}
                    setOpen={() => {
                    }}
                    selectedId={definitiveId}
                />
            </div>
        </div>
    </>
}
export default PatientDetails
