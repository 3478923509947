import React, {useEffect, useRef, useState} from "react"
import {wrapRequest} from "../../helpers/auth";
import LogAPI from "../../api/LogAPI";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import Pagination from "@vlsergey/react-bootstrap-pagination";

const LogView = ({record}) => {
    const effectRan = useRef(false);
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const [logs, setLogs] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [totalResults, setTotalResults] = useState(0);
    const [loading, setLoading] = useState(false)

    const getLogs = (page, size) => {
        setLoading(true)
        return wrapRequest(dispatch, LogAPI.getLogs(page, size, record.id))
            .then(response => {
                setLoading(false)
                if (response.status === 200) {
                    setLogs(response.data.content)
                    setTotalPages(response.data.totalPages)
                    setCurrentPage(response.data.number)
                    setTotalResults(response.data.totalElements)
                }
            })
    }
    const onPageChange = ({target}) => {
        const page = target.value;
        getLogs(page, 30);
    }

    useEffect(() => {
        if (!effectRan.current) {
            getLogs(0, 30)
        }
        return () => effectRan.current = true;
    }, []);

    return <div className="table-responsive">
        <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
            <thead className="border-gray-200 fs-5 fw-semibold bg-lighten">
            <tr>
                <th width="20%" className="min-w-125px sorting">Date</th>
                <th width="50%" className="min-w-125px sorting">Évènements</th>
            </tr>
            </thead>
            <tbody className="fw-semibold text-gray-600">

            {logs?.map((log, idx) => <tr key={idx}
                                         className={idx % 2 === 0 ? "even" : "odd"}>
                    <td data-order="2020-12-14T20:43:00+01:00">{moment.utc(log?.createdDate).local().format(t("common:dateTimeFormat"))}</td>
                    <td data-order="2020-12-14T20:43:00+01:00">{t(`logs:${log?.action}`, log?.metadata)} par {log.userData?.lastName} {log?.userData?.firstName} </td>
                </tr>
            )}
            </tbody>
        </table>
        <Pagination
            aroundCurrent={2}
            showFirstLast={true}
            showPrevNext={true}
            atBeginEnd={0}
            onChange={onPageChange}
            value={currentPage}
            totalPages={totalPages}
        />
    </div>
}
export default LogView
