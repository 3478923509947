import React, {useEffect, useRef, useState} from "react"
import {useDispatch} from "react-redux";
import {wrapRequest} from "../../../helpers/auth";
import ContactAPI from "../../../api/ContactAPI";
import {toast} from "react-toastify";
import {Input} from "../../common/ui/Input";
import {Button} from "../../common/ui/Button";
import CustomSelect from "../../common/ui/CustomSelect";

const ContactModal = ({selectedId, laboratoryId, setOpen, onSuccess}) => {
    const effectRan = useRef(false);
    const dispatch = useDispatch()
    const [contact, setContact] = useState({})
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState([])
    const jobs = [
        {value: "Secrétariat technique", label: "Secrétariat technique"},
        {value: "Biologiste responsable", label: "Biologiste responsable"},
        {value: "Autre", label: "Autre"}]
    const getContactToUpdate = async () => {
        return wrapRequest(dispatch, ContactAPI.get(laboratoryId, selectedId))
    }
    const onSend = (e) => {
        e.preventDefault()
        setLoading(true)
        return wrapRequest(dispatch, selectedId ?
            ContactAPI.update(laboratoryId, selectedId, contact) :
            ContactAPI.create(
                laboratoryId,
                contact
            ))
            .then(response => {
                setLoading(false)
                if (response?.status === 201) {
                    setOpen(false)
                    toast.success("Nouveau contact créé");
                    onSuccess(0, 10)
                } else if (response?.status === 204) {
                    setOpen(false)
                    toast.success("Contact mis à jour");
                    onSuccess(0, 10)
                } else if (response?.status === 400) {
                    setErrors(response.data.errors)
                } else {
                    setOpen(false)
                }
            })
    }
    useEffect(() => {
        if (!effectRan.current) {
            if (selectedId) {
                getContactToUpdate().then(response => {
                    if (response.status === 200) {
                        setContact({
                            ...contact,
                            firstName: response.data?.firstName,
                            lastName: response.data?.lastName,
                            phoneNumber: response.data?.phoneNumber,
                            email: response.data?.email,
                            job: response.data?.job,
                            comments: response.data?.comments
                        })
                    }
                })
            }
        }
        return () => effectRan.current = true;
    }, []);

    return <><Input
        wrapperClass="fv-row mb-7 fv-plugins-icon-container"
        label="Nom"
        labelClassName="required fs-6 fw-semibold mb-2"
        className="form-control form-control-solid"
        name="lastName"
        value={contact.lastName || ""}
        onChange={(e) => setContact({...contact, lastName: e.target.value})}
        errors={errors}
    />
        <Input
            wrapperClass="fv-row mb-7 fv-plugins-icon-container"
            label="Prénom"
            labelClassName="required fs-6 fw-semibold mb-2"
            className="form-control form-control-solid"
            name="firstName"
            value={contact.firstName || ""}
            onChange={(e) => setContact({...contact, firstName: e.target.value})}
            errors={errors}
        />
        <Input
            wrapperClass="fv-row mb-7 fv-plugins-icon-container"
            label="Email"
            labelClassName="fs-6 fw-semibold mb-2"
            className="form-control form-control-solid"
            name="email"
            value={contact.email || ""}
            onChange={(e) => setContact({...contact, email: e.target.value})}
            errors={errors}
        />
        <Input
            wrapperClass="fv-row mb-7 fv-plugins-icon-container"
            label="Numéro de téléphone"
            labelClassName="fs-6 fw-semibold mb-2"
            className="form-control form-control-solid"
            name="phoneNumber"
            value={contact.phoneNumber || ""}
            onChange={(e) => setContact({...contact, phoneNumber: e.target.value})}
            errors={errors}
        />
        <CustomSelect
            wrapperClass="fv-row mb-7 fv-plugins-icon-container"
            label="Poste"
            name="job"
            labelClassName="required fs-6 fw-semibold mb-2"
            className="form-control form-control-solid"
            onChange={(data) => setContact({...contact, job: data.value})}
            options={jobs}
            isSearchable={true}
            isLoading={false}
            placeholder={"Veuillez sélectionner un intitulé de poste"}
            value={jobs.filter((x) =>
                contact?.job?.includes(x.value)
            )}
            errors={errors}
        />
        <div className="d-flex flex-end">
            <Button onClick={onSend} loading={loading}
                    wrapperClass=""
                    label={"Enregistrer"}
            />
        </div>
    </>
}

export default ContactModal
