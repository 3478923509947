import * as types from "../actions/actionTypes";
import initialState from "./initialState";
import {ACCESS_TOKEN, REFRESH_TOKEN} from "../../helpers/constants";

export default function sessionReducer(state = initialState.session, action) {
    switch (action.type) {
        case types.LOG_IN_SUCCESS:
            localStorage.setItem(ACCESS_TOKEN, action.tokens.accessToken);
            localStorage.setItem(REFRESH_TOKEN, action.tokens.refreshToken);
            return true;
        case types.LOG_OUT:
            localStorage.removeItem(ACCESS_TOKEN);
            localStorage.removeItem(REFRESH_TOKEN);
            window.location.href = `/auth/login${action?.cause?.message === "session_expiration" ? "?err=session_expiration" : ""}`
            return false;
        default:
            return state;
    }
}
