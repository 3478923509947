import React, {useEffect, useRef, useState} from "react"
import {Input} from "../common/ui/Input";
import {useDispatch} from "react-redux";
import {Button} from "../common/ui/Button";
import {wrapRequest} from "../../helpers/auth";
import {toast} from "react-toastify";
import DoctorAPI from "../../api/DoctorAPI";
import LabelAPI from "../../api/LabelAPI";
import CustomSelect from "../common/ui/CustomSelect";
import AddressAPI from "../../api/AddressAPI";
import Autocomplete from "../common/ui/Autocomplete";
import Swal from "sweetalert2";
import UserAPI from "../../api/UserAPI";

const DoctorEdit = ({setOpen, onSuccess, selectedId, setDoctorFullName}) => {
    const effectRan = useRef(false);
    const dispatch = useDispatch()
    const [doctor, setDoctor] = useState({})
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState([])
    const [specialities, setSpecialities] = useState([])
    const [searchValue, setSearchValue] = useState("")
    const [searchResult, setSearchResult] = useState({})
    const [emailValue, setEmailValue] = useState("")

    function createOrUpdateDoctor(e) {
        e.preventDefault()
        setLoading(true)
        return wrapRequest(dispatch, selectedId ?
            DoctorAPI.update(doctor, selectedId) :
            DoctorAPI.create(doctor))
            .then(response => {
                setLoading(false)
                if (response?.status === 201) {
                    setOpen(false)
                    toast.success("Nouveau prescripteur créé");
                    onSuccess(0, 10)
                } else if (response?.status === 204) {
                    setOpen(false)
                    toast.success("Prescripteur mis à jour");
                    onSuccess(0, 10)
                } else if (response?.status === 409) {
                    setOpen(false)
                    toast.error("L'adresse email est utilisé par un autre compte Lyve.");
                } else if (response?.status === 400) {
                    setErrors(response.data.errors)
                } else {
                    setOpen(false)
                }
            })
    }

    const onSend = (e) => {
        if (selectedId && emailValue !== doctor.email) {
            Swal.fire({
                icon: 'warning',
                title: "Attention ! L'adresse email a été modifiée.",
                text: "L'utilisateur devra utiliser cette adresse pour se connecter.",
                showCancelButton: true,
                confirmButtonText: "Oui",
                cancelButtonText: `Non`
            }).then((result) => {
                if (result.isConfirmed) {
                    return createOrUpdateDoctor(e);
                }
            })
        } else {
            return createOrUpdateDoctor(e);
        }
    }

    const loadOptions = () => {
        wrapRequest(dispatch, LabelAPI.getLabels("SPECIALITY", 0, 500))
            .then(response => {
                    if (response.status === 200) {
                        setSpecialities(response.data.content.map(element => {
                            return {
                                value: element.name,
                                label: `${element.name} `
                            }
                        }))
                    }
                }
            )
    }

    const getDoctorToUpdate = async () => {
        return wrapRequest(dispatch, DoctorAPI.get(selectedId))
    }

    const onSearch = (event) => {
        event.preventDefault();
        setSearchValue(event.target.value)
        if (event.target.value.length > 3) {
            document.getElementById("addressSearch").style["display"] = "block";
            return AddressAPI.fetchAddressFromSearchAPI(event.target.value.replace(new RegExp(" ", 'g'), "+"))
                .then(response => {
                    setSearchResult(response.data)
                });
        }
    }

    const onSelectItem = (event) => {
        event.preventDefault();
        const addressFromSearch = searchResult?.features.filter(feature => feature.properties.id === event.target.id)[0]
        document.getElementById("addressSearch").style["display"] = "none";
        setSearchValue(addressFromSearch.properties.name)
        setDoctor({
            ...doctor,
            firstLine: addressFromSearch.properties.name,
            city: addressFromSearch.properties.city,
            zipCode: addressFromSearch.properties.postcode
        })
    }

    const onResetPassword = (e) => {
        e.preventDefault()
        Swal.fire({
            icon: 'warning',
            title: "Voulez-vous réinitialiser le mot de passe de ce prescripteur ?",
            text: "Un email avec des instructions lui sera envoyé",
            showCancelButton: true,
            confirmButtonText: "Oui",
            cancelButtonText: `Non`
        }).then((result) => {
            if (result.isConfirmed) {
                setLoading(true)
                return wrapRequest(dispatch, UserAPI.requestPasswordModification({email: doctor.email}))
                    .then(response => {
                        setLoading(false)
                        if (response.status === 200) {
                            toast.success("Email de réinitialisation envoyé")
                        }
                    })
            }
        });
    }

    useEffect(() => {
        if (!effectRan.current) {
            loadOptions()
            if (selectedId) {
                getDoctorToUpdate().then(response => {
                    if (response.status === 200) {
                        setDoctor({
                            ...doctor,
                            firstName: response.data?.firstName,
                            lastName: response.data?.lastName,
                            phoneNumber: response.data?.phoneNumber,
                            mobilePhoneNumber: response.data?.mobilePhoneNumber,
                            email: response.data?.email,
                            msSanteEmail: response.data?.msSanteEmail,
                            firstLine: response.data?.address?.firstLine,
                            secondLine: response.data?.address?.secondLine,
                            city: response.data?.address?.city,
                            zipCode: response.data?.address?.zipCode,
                            speciality: response.data?.speciality,
                            rppsNumber: response.data?.rppsNumber
                        })
                        setSearchValue(response.data?.address?.firstLine)
                        setEmailValue(response.data?.email)
                        setDoctorFullName(`${response.data?.lastName} ${response.data?.firstName}`)
                    }
                })
            }
        }
        return () => effectRan.current = true;
    }, []);

    return <>
        <div className="row">
            <Input
                wrapperClass="col-lg-6 mb-7 fv-plugins-icon-container"
                label="RPPS"
                labelClassName="required fs-6 fw-semibold mb-2"
                className="form-control form-control-solid"
                name="rppsNumber"
                value={doctor.rppsNumber || ""}
                onChange={(e) => setDoctor({...doctor, rppsNumber: e.target.value})}
                errors={errors}
            />
            <Input
                wrapperClass="col-lg-6 mb-7 fv-plugins-icon-container"
                label="Prénom"
                labelClassName="required fs-6 fw-semibold mb-2"
                className="form-control form-control-solid"
                name="firstName"
                value={doctor.firstName || ""}
                onChange={(e) => setDoctor({...doctor, firstName: e.target.value})}
                errors={errors}
            />
            <Input
                wrapperClass="col-lg-6 mb-7 fv-plugins-icon-container"
                label="Nom"
                labelClassName="required fs-6 fw-semibold mb-2"
                className="form-control form-control-solid"
                name="lastName"
                value={doctor.lastName || ""}
                onChange={(e) => setDoctor({...doctor, lastName: e.target.value})}
                errors={errors}
            />
            <Input
                wrapperClass="col-lg-6 mb-7 fv-plugins-icon-container"
                label="Email"
                labelClassName="required fs-6 fw-semibold mb-2"
                className="form-control form-control-solid"
                name="email"
                value={doctor.email || ""}
                onChange={(e) => setDoctor({...doctor, email: e.target.value})}
                errors={errors}
            />
            <Input
                wrapperClass="col-lg-6 mb-7 fv-plugins-icon-container"
                label="Adresse MS Santé"
                labelClassName="fs-6 fw-semibold mb-2"
                className="form-control form-control-solid"
                name="msSanteEmail"
                value={doctor.msSanteEmail || ""}
                onChange={(e) => setDoctor({...doctor, msSanteEmail: e.target.value})}
                errors={errors}
            />
            <CustomSelect
                wrapperClass="col-lg-6 mb-7 fv-plugins-icon-container"
                label="Spécialité"
                name="speciality"
                labelClassName="required fs-6 fw-semibold mb-2"
                className="form-control form-control-solid"
                onChange={(data) => setDoctor({...doctor, speciality: data.value})}
                options={specialities}
                isSearchable={true}
                isLoading={false}
                placeholder={"Veuillez sélectionner une spécialité"}
                value={specialities.filter((x) =>
                    doctor?.speciality === x.value
                )}
                errors={errors}
            />
            <Input
                wrapperClass="col-lg-6 mb-7 fv-plugins-icon-container"
                label="Numéro de téléphone"
                labelClassName="fs-6 fw-semibold mb-2"
                className="form-control form-control-solid"
                name="phoneNumber"
                value={doctor.phoneNumber || ""}
                onChange={(e) => setDoctor({...doctor, phoneNumber: e.target.value})}
                errors={errors}
            />
            <Input
                wrapperClass="col-lg-6 mb-7 fv-plugins-icon-container"
                label="Numéro de téléphone portable"
                labelClassName="fs-6 fw-semibold mb-2"
                className="form-control form-control-solid"
                name="mobilePhoneNumber"
                value={doctor.mobilePhoneNumber || ""}
                onChange={(e) => setDoctor({...doctor, mobilePhoneNumber: e.target.value})}
                errors={errors}
            />
        </div>
        <div className="row">
            <Autocomplete id="addressSearch"
                          value={searchValue || ""}
                          results={searchResult?.features}
                          onSelectItem={onSelectItem}
                          label="Rechercher une adresse"
                          name="searchValue"
                          onChange={onSearch}
                          errors={errors}
                          customLabel={"Adresse"}
                          wrapperClass="col-lg-6 mb-7 fv-plugins-icon-container"
            />

            <Input
                wrapperClass="col-lg-6 mb-7 fv-plugins-icon-container"
                label="Complément d'adresse"
                labelClassName="fs-6 fw-semibold mb-2"
                className="form-control form-control-solid"
                name="secondLine"
                value={doctor?.secondLine || ""}
                onChange={(e) => setDoctor({...doctor, secondLine: e.target.value})}
                errors={errors}
            />
            <Input
                wrapperClass="col-lg-6 mb-7 fv-plugins-icon-container"
                label="Code postal"
                labelClassName="fs-6 fw-semibold mb-2"
                className="form-control form-control-solid"
                name="zipCode"
                value={doctor?.zipCode || ""}
                onChange={(e) => setDoctor({...doctor, zipCode: e.target.value})}
                errors={errors}
            />
            <Input
                wrapperClass="col-lg-6 mb-7 fv-plugins-icon-container"
                label="Ville"
                labelClassName="fs-6 fw-semibold mb-2"
                className="form-control form-control-solid"
                name="city"
                value={doctor?.city || ""}
                onChange={(e) => setDoctor({...doctor, city: e.target.value})}
                errors={errors}
            />
        </div>
        <div className="d-flex flex-end">
            {selectedId !== undefined && <Button onClick={onResetPassword} loading={loading}
                                                 wrapperClass="me-5"
                                                 buttonClass="btn btn-secondary"
                                                 label={"Changer le mot de passe"}
            />}
            <Button onClick={onSend} loading={loading}
                    wrapperClass=""
                    label={"Enregistrer"}
            />
        </div>
    </>
}
export default DoctorEdit
