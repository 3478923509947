import React, {useState} from "react"
import {Checkbox} from "../common/ui/Checkbox";
import {Input} from "../common/ui/Input";
import {Button} from "../common/ui/Button";
import {wrapRequest} from "../../helpers/auth";
import {useDispatch} from "react-redux";
import RecordAPI from "../../api/RecordAPI";
import {toast} from "react-toastify";
import NotificationAlert from "../common/ui/NotificationAlert";

const SupportView = ({id, onSuccess, setOpen, profile}) => {
    const dispatch = useDispatch()
    const [support, setSupport] = useState({support: true})
    const [errors, setErrors] = useState([])
    const [loading, setLoading] = useState(false)
    const onSend = () => {
        wrapRequest(dispatch, RecordAPI.createSupport(support, id))
            .then(response => {
                if (response?.status === 201) {
                    setOpen(false)
                    toast.success("Patient pris en charge");
                    onSuccess(0, 10)
                } else if (response?.status === 400) {
                    setErrors(response.data.errors)
                } else {
                    setOpen(false)
                }
            })
    }

    return (profile?.roles?.includes("ROLE_SUPER_ADMIN")
                || profile?.roles?.includes("ROLE_USER"))
    ? <NotificationAlert
            title={"Droits insuffisants"}
            message={"Seuls un administrateur, un responsable de laboratoire ou un médecin prescripteur peuvent valider une prise en charge."}
        /> :
        <>
            <Checkbox
                label={"Je prends en charge le patient"}
                checked={support?.support}
                onChange={() => setSupport({...support, support: !support?.support})}
            />
            {!support?.support && <><Checkbox
                label={"Je demande le transfert du patient vers un établissement d'urgence (SAMU...)"}
                checked={!support?.support}
                onChange={() => setSupport({...support, support: !support?.support})}
            />
                <Input
                    wrapperClass="fv-row mb-7 fv-plugins-icon-container"
                    label="Nom de l'établissement"
                    labelClassName="required fs-6 fw-semibold mb-2"
                    className="form-control form-control-solid"
                    name="name"
                    value={support.name || ""}
                    onChange={(e) => setSupport({...support, name: e.target.value})}
                    errors={errors}
                />
            </>}
            <div className="d-flex flex-end mt-10">
                <Button onClick={onSend} loading={loading}
                        wrapperClass=""
                        label={"Enregistrer"}
                />
            </div>
        </>

}

export default SupportView
