import axios from "axios";
import {requestHeaders} from "../helpers/constants";
import {logOut} from "../helpers/auth";

class WebhookAPI {

    static create(webhook) {
        return axios({
            method: 'post',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/webhooks`,
            data: webhook
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }
    static getAll(organizationId, page, size) {
        return axios({
            method: 'get',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/webhooks?page=${page}&size=${size}&organizationId=${organizationId}`
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }

    static get(id) {
        return axios({
            method: 'get',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/webhooks/${id}`
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }

    static remove(id) {
        return axios({
            method: 'delete',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/webhooks/${id}`
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }

    static update(webhook, id) {
        return axios({
            method: 'put',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/webhooks/${id}`,
            data: webhook
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }
}
export default WebhookAPI
