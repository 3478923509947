import React, {useEffect, useRef, useState} from "react"
import CustomSelect from "../common/ui/CustomSelect";
import {wrapRequest} from "../../helpers/auth";
import OrganizationAPI from "../../api/OrganizationAPI";
import {useDispatch} from "react-redux";
import LaboratoryAPI from "../../api/LaboratoryAPI";
import {Button} from "../common/ui/Button";
import DatePicker, {registerLocale} from "react-datepicker";
import fr from 'date-fns/locale/fr';

import "react-datepicker/dist/react-datepicker.css";
import {Input} from "../common/ui/Input";

registerLocale('fr', fr)

const FilterCard = ({profile, setOpen, onClose, currentTab}) => {
    const dispatch = useDispatch()
    const effectRan = useRef(false);
    const [loading, setLoading] = useState(false)
    const [organizations, setOrganizations] = useState([])
    const [laboratories, setLaboratories] = useState([])
    const [searchRequest, setSearchRequest] = useState({})
    const [errors] = useState([])
    const [alertStatuses] = useState([
        {label: "Urgents", value: "URGENT"},
        {label: "Anormaux", value: "ABNORMAL"},
        {label: "Normaux", value: "NORMAL"}
    ])
    const [supportStatuses] = useState([
        {label: "En attente", value: "PENDING"},
        {label: "Non nécessaire", value: "UNNECESSARY"},
        {label: "Pris en charge", value: "COMPLETED"},
        {label: "Transféré à un autre établissement", value: "FORWARDED"}
    ])
    const [biologicalValidationStatuses] = useState([
        {label: "Non validé", value: "NOT_STARTED"},
        {label: "Validation partielle", value: "IN_PROGRESS"},
        {label: "Validé biologiquement", value: "VALIDATED"}
    ])
    const [sortingValues] = useState([
        {label: "Date d'analyses décroissante", value: "date_desc"},
        {label: "Date d'analyses croissante", value: "date_asc"},
        {label: "Nom du prescripteur décroissant", value: "doctor.lastName_desc"},
        {label: "Nom du prescripteur croissant", value: "doctor.lastName_asc"},
        {label: "Nom du patient décroissant", value: "patient.lastName_desc"},
        {label: "Nom du patient croissant", value: "patient.lastName_asc"}
    ])
    const loadOrganizations = () => {
        return wrapRequest(dispatch, OrganizationAPI.getOrganizations(0, 500))
            .then(response => {
                    if (response.status === 200) {
                        setOrganizations(response.data.content.map(element => {
                            return {
                                value: element.id,
                                label: `${element.name} `
                            }
                        }))
                    }
                }
            )
    }
    const loadLaboratories = (selectedOrganizationId) => {
        return wrapRequest(dispatch, LaboratoryAPI.getLaboratories(0, 500, selectedOrganizationId))
            .then(response => {
                    if (response.status === 200) {
                        setLaboratories(response.data.content.map(element => {
                            return {
                                value: element.id,
                                label: `${element.name} `
                            }
                        }))
                    }
                }
            )
    }

    const onSend = () => {
        localStorage.setItem("search", JSON.stringify(searchRequest))
        onClose(0, 10)
        setOpen(false)
    }

    const onClear = () => {
        localStorage.removeItem("search")
        setSearchRequest({})
        onClose(0, 10)
        setOpen(false)
    }

    useEffect(() => {
        if (!effectRan.current) {
            const search = localStorage.getItem("search") ? JSON.parse(localStorage.getItem("search")) : {}
            setSearchRequest(search)
            if (profile?.roles?.includes("ROLE_SUPER_ADMIN")) {
                loadOrganizations();
            } else {
                loadLaboratories(profile?.organizationId)
            }
            if (search?.laboratories) {
                loadLaboratories(search?.organizationId)
            }
        }
        return () => effectRan.current = true;
    }, []);

    const isAfterStartDate = (date) => {
        if (searchRequest?.startDatetime) {
            return date <= new Date() && date >= searchRequest?.startDatetime
        }
        return date <= new Date()
    }


    const isBeforeEndDate = (date) => {
        if (searchRequest?.endDatetime) {
            return date <= new Date() && date <= searchRequest?.endDatetime
        }
        return date <= new Date()
    }

    return <>
        <div className="row">
            {profile?.roles?.includes("ROLE_SUPER_ADMIN") && <CustomSelect
                wrapperClass="col-lg-4 fv-plugins-icon-container mb-2"
                label="Nom du client"
                name="organizationId"
                labelClassName="required fs-6 fw-semibold mb-2"
                className="form-control form-control-solid"
                onChange={(data) => {
                    setSearchRequest({...searchRequest, organizationId: data.value})
                    loadLaboratories(data.value)
                }}
                options={organizations}
                isSearchable={true}
                isLoading={false}
                placeholder={"Veuillez sélectionner un client"}
                value={organizations.filter((x) =>
                    searchRequest.organizationId === x.value
                )}
                errors={errors}
            />}
            {(!profile?.roles?.includes("ROLE_SUPER_ADMIN") || searchRequest?.organizationId) &&
                <CustomSelect
                    wrapperClass="col-lg-4 fv-plugins-icon-container  mb-2"
                    label="Nom du laboratoire ou site"
                    name="laboratories"
                    labelClassName="fs-6 fw-semibold mb-2"
                    className="form-control form-control-solid"
                    onChange={(data) => setSearchRequest({...searchRequest, laboratories: data.map((x) => x.value)})}
                    options={laboratories}
                    isSearchable={true}
                    isLoading={false}
                    isMulti={true}
                    placeholder={"Veuillez sélectionner un laboratoire ou un site"}
                    value={laboratories.filter((x) =>
                        searchRequest?.laboratories?.includes(x.value)
                    )}
                    errors={errors}
                />}
            {currentTab === "all" && <CustomSelect
                wrapperClass="col-lg-4  fv-plugins-icon-container  mb-2"
                label="Statut de validation"
                name="biologicalValidationStatuses"
                labelClassName="fs-6 fw-semibold mb-2"
                className="form-control form-control-solid"
                onChange={(data) => setSearchRequest({
                    ...searchRequest,
                    biologicalValidationStatuses: data.map((x) => x.value)
                })}
                options={biologicalValidationStatuses}
                isSearchable={true}
                isLoading={false}
                isMulti={true}
                placeholder={"Veuillez sélectionner un ou plusieurs types de résultats"}
                value={biologicalValidationStatuses.filter((x) =>
                    searchRequest?.biologicalValidationStatuses?.includes(x.value)
                )}
                errors={errors}
            />}
            <CustomSelect
                wrapperClass="col-lg-4  fv-plugins-icon-container  mb-2"
                label="Niveau d'alerte"
                name="alertStatuses"
                labelClassName="fs-6 fw-semibold mb-2"
                className="form-control form-control-solid"
                onChange={(data) => setSearchRequest({...searchRequest, alertStatuses: data.map((x) => x.value)})}
                options={alertStatuses}
                isSearchable={true}
                isLoading={false}
                isMulti={true}
                placeholder={"Veuillez sélectionner un ou plusieurs types de résultats"}
                value={alertStatuses.filter((x) =>
                    searchRequest?.alertStatuses?.includes(x.value)
                )}
                errors={errors}
            />
            <CustomSelect
                wrapperClass="col-lg-4 fv-plugins-icon-container mb-2"
                label="Niveau de prise en charge"
                name="supportStatuses"
                labelClassName="fs-6 fw-semibold mb-2"
                className="form-control form-control-solid"
                onChange={(data) => setSearchRequest({...searchRequest, supportStatuses: data.map((x) => x.value)})}
                options={supportStatuses}
                isSearchable={true}
                isLoading={false}
                isMulti={true}
                placeholder={"Veuillez sélectionner un ou plusieurs niveaux de prise en charge"}
                value={supportStatuses.filter((x) =>
                    searchRequest?.supportStatuses?.includes(x.value)
                )}
                errors={errors}
            />
            <Input
                wrapperClass="col-lg-4  fv-plugins-icon-container mb-2"
                label="Numéro de dossier"
                labelClassName="fs-6 fw-semibold mb-2"
                className="form-control form-control-solid"
                name="reference"
                value={searchRequest.reference || ""}
                onChange={(e) => setSearchRequest({...searchRequest, reference: e.target.value})}
                errors={errors}
            />
            <div className="col-lg-4  d-grid gap-2  mb-2">
                <label className="fs-6 fw-semibold me-2">Date de début</label>
                <DatePicker
                    style="width:100%"
                    className={"form-control form-control-solid w"}
                    selected={Date.parse(searchRequest?.startDatetime)}
                    onChange={(date) => setSearchRequest({...searchRequest, startDatetime: date})}
                    locale="fr"
                    dateFormat="P"
                    placeholderText="DD/MM/YYYY"
                    filterDate={isBeforeEndDate}
                /></div>

            <div className="col-lg-4  d-grid gap-2  mb-2">
                <label className="fs-6 fw-semibold me-2">Date de fin</label>
                <DatePicker
                    className="form-control form-control-lg form-control-solid w-100"
                    selected={Date.parse(searchRequest?.endDatetime)}
                    onChange={(date) => setSearchRequest({...searchRequest, endDatetime: date})}
                    locale="fr"
                    dateFormat="P"
                    placeholderText="DD/MM/YYYY"
                    filterDate={isAfterStartDate}
                />
            </div>
            <CustomSelect
                wrapperClass="col-lg-4  fv-plugins-icon-container  mb-2"
                label="Trier par..."
                name="sortingValues"
                labelClassName="fs-6 fw-semibold mb-2"
                className="form-control form-control-solid"
                onChange={(data) => setSearchRequest({
                    ...searchRequest,
                    sortBy: data.value.split("_")[0],
                    sortDirection: data.value.split("_")[1]
                })}
                options={sortingValues}
                isLoading={false}
                placeholder={"Choisissez un ordre"}
                value={sortingValues.filter((x) =>
                    searchRequest?.sortBy === x.value.split("_")[0] && searchRequest?.sortDirection === x.value.split("_")[1]
                )}
                errors={errors}
            />
        </div>
        <div className="d-flex flex-end mt-6">
            <Button onClick={onClear} loading={loading}
                    wrapperClass="me-3"
                    buttonClass={"btn btn-secondary"}
                    label={"Effacer"}
            />
            <Button onClick={onSend} loading={loading}
                    wrapperClass=""
                    label={"Appliquer"}
            />
        </div>
    </>
}
export default FilterCard;
