import React, {useState} from "react"
import {Helmet, HelmetProvider} from 'react-helmet-async';
import "./Login.css"
import {Input} from "../common/ui/Input";
import {connect} from "react-redux";
import {logInUser} from "../../redux/actions/sessionActions";
import {Button} from "../common/ui/Button";
import {resetErrors} from "../../redux/actions/errorsActions";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";


const Login = ({logInUser, resetErrors, loading, session, errors}) => {
    const {t} = useTranslation("loginPage", {useSuspense: true});
    const helmetContext = {};
    const from = "/"
    const [credentials, setCredentials] = useState({email: '', password: ''})
    const onSend = (event) => {
        event.preventDefault()
        resetErrors()
        logInUser(credentials, t)
    }
    if (session) {
        window.location.href = from
    }
    return <HelmetProvider context={helmetContext}>
        <Helmet>
            <body style="background-image: url('/assets/images/bg.png')"
                  className="app-blank bgi-size-cover bgi-position-center bgi-no-repeat"></body>
        </Helmet>
        <div className="d-flex flex-column flex-column-fluid flex-lg-row">
            <div className="d-flex flex-center w-lg-50 pt-15 pt-lg-0 px-10">
                <div className="d-flex flex-center flex-lg-start flex-column">
                    <div className="left-container">
                        <h1 className="text-white fs-2tx text-center">Lyve Medical Network</h1>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-center w-lg-50 p-10">
                <div className="card rounded-3 w-md-550px">
                    <div className="card-body p-10 p-lg-20">
                        <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
                              noValidate="novalidate" action="#">
                            <div className="text-center mb-11">
                                <h1 className="text-dark fw-bolder mb-3">{t("title")}</h1>
                            </div>
                            <div className="text-center mb-9">
                                <a href={process.env.PSC_OAUTH_URL}>
                                    <img alt="pro-sante-connection" src="/assets/images/pro-sante-connect.png"
                                         width={"170px"}
                                         height={"47px"}/>
                                </a>
                            </div>
                            <div className="separator separator-content my-14">
                                <span className="w-250px text-gray-500 fw-semibold fs-7">{t("separator.text")}</span>
                            </div>
                            <Input
                                wrapperClass="fv-row mb-8 fv-plugins-icon-container"
                                className="form-control bg-transparent"
                                name="email"
                                type={"text"}
                                placeholder={t("email.field")}
                                onChange={(e) => setCredentials({...credentials, email: e.target.value})}
                                errors={errors}
                            />
                            <Input
                                wrapperClass="fv-row mb-3 fv-plugins-icon-container"
                                className="form-control bg-transparent"
                                name="password"
                                type={"password"}
                                placeholder={t("password.field")}
                                onChange={(e) => setCredentials({...credentials, password: e.target.value})}
                                errors={errors}
                            />
                            <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
                                <div></div>
                                <Link to={"/auth/forgot-pwd"}
                                      className="link-primary">{t("forgot.password.link")}</Link>
                            </div>
                            <Button onClick={onSend} loading={loading}
                                    label={t("button.label")}
                            />
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </HelmetProvider>
}

const mapDispatchToProps = {logInUser, resetErrors};

const mapStateToProps = state => ({
    session: state.session,
    errors: state.errors,
    loading: state.loading
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
