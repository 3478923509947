import React, {useEffect, useRef, useState} from "react"
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {wrapRequest} from "../../helpers/auth";
import DeviceAPI from "../../api/DeviceAPI";
import {LoadableComponent} from "../common/ui/LoadableComponent";
import NotificationAlert from "../common/ui/NotificationAlert";
import moment from "moment/moment";
import {Modal} from "react-bootstrap";
import DeviceEdit from "./DeviceEdit";
import Swal from "sweetalert2";
import {toast} from "react-toastify";

const Devices = ({profile}) => {
    const [devices, setDevices] = useState([]);
    const effectRan = useRef(false);
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const [totalResults, setTotalResults] = useState(0);
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const [selectedId, setSelectedId] = useState(false)
    const navigate = useNavigate()

    const getDevices = (page, size) => {
        setLoading(true)
        return wrapRequest(dispatch, DeviceAPI.getAll(page, size))
            .then(response => {
                setLoading(false)
                if (response.status === 200) {
                    setDevices(response.data.content)
                    setTotalResults(response.data.totalElements)
                }
            })
    }

    const onUpdate = (e) => {
        e.preventDefault()
        const id = e.target.id
        setSelectedId(id)
        setOpen(true)
    }

    const onDelete = (e) => {
        e.preventDefault()
        const id = e.target.id
        Swal.fire({
            title: "Voulez-vous supprimer ce dispositif ?",
            text: "Attention ! Cette action est irréversible",
            showCancelButton: true,
            confirmButtonText: "Oui",
            cancelButtonText: `Non`,
            icon: "warning"
        }).then((result) => {
            if (result.isConfirmed) {
                return wrapRequest(dispatch, DeviceAPI.remove(id))
                    .then(response => {
                        if (response.status === 204) {
                            toast.success("Dispositif supprimé")
                            getDevices(0, 100)
                        }
                    })
            }
        });
    }

    useEffect(() => {
        if (!effectRan.current) {
            getDevices(0, 100)
        }
        return () => effectRan.current = true;
    }, []);

    return <div className="card">
        <Modal show={open} size="lg">
            <Modal.Header
                closeButton={true}
                onHide={() => {
                    setSelectedId(undefined)
                    setOpen(false)
                }}
            >
                <Modal.Title>Ajouter un nouveau dispositif</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <DeviceEdit setOpen={setOpen} onSuccess={() => getDevices(0, 100)} selectedId={selectedId}/>
            </Modal.Body>
        </Modal>
        <div className="card-header border-0 pt-6">
            <div className="card-title">Dispositifs de mesure ({totalResults} trouvé{totalResults > 1 ? "s" : ""})
            </div>
            <div className="card-toolbar">
                <div className="d-flex justify-content-end" data-kt-customer-table-toolbar="base">
                    <button type="button" className="btn btn-primary"
                            onClick={(e) => {
                                e.preventDefault()
                                setSelectedId(undefined)
                                setOpen(true)
                            }}> Ajouter un nouveau dispositif
                    </button>
                </div>
            </div>
        </div>
        <div className="card-body pt-0">
            <div className="dataTables_wrapper dt-bootstrap4 no-footer mt-8">
                <LoadableComponent loading={loading} component={<>{devices?.length === 0 ? <NotificationAlert
                        title={"Aucun appareil trouvé"}
                        message={"Veuillez enregistrer un appareil"}
                    /> :
                    <div className="table-responsive">
                        <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
                            <thead className="border-gray-200 fs-5 fw-semibold bg-lighten">
                            <tr>
                                <th className="min-w-125px sorting">Date de création</th>
                                <th className="min-w-125px sorting">Nom du dispositif</th>
                                <th className="min-w-125px sorting">Identifiant MAC</th>
                                <th className="min-w-125px sorting">Type</th>
                                <th className="min-w-125px sorting">Actions</th>
                            </tr>
                            </thead>
                            <tbody className="fw-semibold text-gray-600">
                            {devices?.map((device, idx) => <tr key={idx}
                                                               className={(idx % 2 === 0 ? "even" : "odd")}>
                                    <td>{moment.utc(device?.createdDate).local().format(t("common:dateTimeFormat"))}</td>
                                    <td>{device.label}</td>
                                    <td>{device.deviceNumber}</td>
                                    <td>Qassay</td>
                                    <td id={device.id}>
                                        <button
                                            id={device.id}
                                            onClick={onUpdate}
                                            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                                        <span className="svg-icon svg-icon-3" id={device.id}>
																			<i className="fa-solid fa-pen"
                                                                               id={device.id}></i>
                                        </span>
                                        </button>
                                        <button
                                            className="btn btn-icon btn-bg-light btn-active-color-danger btn-sm"
                                            id={device.id}
                                            onClick={onDelete}>
                                            <span className="svg-icon svg-icon-3">
																			<i className="fa-solid fa-trash"
                                                                               id={device.id}></i>
                                            </span>
                                        </button>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>}
                </>}/>
            </div>
        </div>

    </div>
}
export default Devices
