import React from "react";
import PropTypes from "prop-types";

const Autocomplete = ({
                          id,
                          results = [],
                          type = "text",
                          onSelectItem,
                          value,
                          wrapperClass = "d-flex align-items-center position-relative mb-7 col-lg-12",
                          label = "Rechercher une adresse",
                          className = "form-control form-control-solid",
                          name = "searchValue",
                          labelClassName = "form-label fs-6 fw-bolder text-dark",
                          customLabel = "",
                          onChange
                      }) => {
    const styles =
        {
            display: "block",
            transformOrigin: "0px 0px",
            opacity: "1",
            transform: "scaleX(1) scaleY(1)",
            left: "14px",
            bottom: "20px",
            zIndex: 1000,
            position: "absolute"
        }
    return <>
        <div className={wrapperClass}>
            {customLabel !== undefined ? customLabel :
                <label className={labelClassName}>{customLabel}</label>}
            <input type={type}
                   className={className}
                   placeholder={label}
                   onChange={onChange}
                   defaultValue={value}
                   name={name}
                   autoComplete={"off"}
            /></div>
        {((results !== undefined && !results.isEmpty) &&
            <ul id={id}
                className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold col-lg-12"
                tabIndex="0"
                style={styles}>
                {results.map(result =>
                    <div className="menu-item px-3"
                         key={result.properties.id} onClick={onSelectItem}>
                        <div className="menu-content fs-6 text-gray-900 fw-bold px-3 py-4" id={result.properties.id}>
                            {result.properties.label}
                        </div>
                    </div>
                )}
            </ul>
        )}
    </>
};

Autocomplete.propTypes = {
    id: PropTypes.string.isRequired,
    results: PropTypes.array,
    type: PropTypes.string,
    onSelectItem: PropTypes.func.isRequired
};

export default Autocomplete;
