import axios from "axios";
import {requestHeaders} from "../helpers/constants";

class ContactAPI {
    static create(laboratoryId, contact) {
        return axios({
            method: 'post',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/laboratories/${laboratoryId}/contacts`,
            data: contact
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }

    static update(laboratoryId, id, contact) {
        return axios({
            method: 'put',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/laboratories/${laboratoryId}/contacts/${id}`,
            data: contact
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }

    static remove(laboratoryId, id) {
        return axios({
            method: 'delete',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/laboratories/${laboratoryId}/contacts/${id}`
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }

    static get(laboratoryId, id) {
        return axios({
            method: 'get',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/laboratories/${laboratoryId}/contacts/${id}`
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }

    static getContacts(page, size, laboratoryId) {
        let url = `${process.env.API_BASE_URL}/laboratories/${laboratoryId}/contacts?page=${page}&size=${size}`
        return axios({
            method: 'get',
            headers: requestHeaders(),
            url: url,
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }
}

export default ContactAPI
