import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import * as config from './config'
import {fallback} from './config'
import translationLoader from "./translation-loader";

const langDetectorOptions = {
    order: ['localStorage','navigator'],
    lookupCookie: 'locale',
    lookupLocalStorage: 'locale',
    caches: ['localStorage'],
    excludeCacheFor: ['cimode'],
    checkWhitelist: true
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .use(translationLoader)
    .init({
        returnNull: false,
        react: {
            useSuspense: false
        },
        fallbackLng: fallback,
        ns: config.namespaces,
        defaultNS: config.defaultNamespace,
        whitelist: ['fr'],
        keySeparator: false,
        detection: langDetectorOptions,
        interpolation: {
            escapeValue: false,
        }
    });


export default i18n;
