import React from "react";
import {useTranslation} from "react-i18next";
import {formatMessage} from "../../../helpers/methods";


export const Input = ({
                          id,
                          label,
                          errors,
                          name,
                          onChange,
                          value,
                          type = "text",
                          wrapperClass = "input-field col s12",
                          disabled = false,
                          defaultValue,
                          maxLength,
                          customLabel,
                          placeholder,
                          className = "form-control form-control-lg form-control-solid",
                          labelClassName = "form-label fs-6 fw-bolder text-dark",
                          withIcon
                      }) => {
    const {t, i18n} = useTranslation();
    return (
        <div className={wrapperClass}>
            {customLabel !== undefined ? customLabel :
                <label className={labelClassName}>{label}</label>}
            {withIcon}
            <input type={type} id={id} name={name} onChange={onChange} value={value}
                   autoComplete="off"
                   placeholder={placeholder}
                   disabled={disabled} defaultValue={defaultValue} maxLength={maxLength}
                   className={className + " " + ((errors?.filter(e => e.fieldName === name).shift()?.message) ? "is-invalid" : "")}
            />
            {(errors?.filter(e => e.fieldName === name).shift()?.message) &&
                <div className="fv-plugins-message-container invalid-feedback">
                    <div data-field="email"
                         data-validator="notEmpty">{t("errors:" + formatMessage(errors?.filter(e => e.fieldName === name).shift()?.message))}</div>
                </div>
            }
        </div>
    );
};
