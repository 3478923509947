import React from "react"

export const LoadableComponent = ({
                                      loading,
                                      component: Component
                                  }) => {
    return <>
        {loading ? <div className="text-center mb-10 mt-10">
            <div className="spinner-border text-primary" style={{width: "3rem", height: "3rem"}} role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div> : Component}
    </>
}
