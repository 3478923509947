import React, {useEffect, useRef, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {wrapRequest} from "../../helpers/auth";
import {toast} from "react-toastify";
import UserAPI from "../../api/UserAPI";
import {Input} from "../common/ui/Input";
import {Button} from "../common/ui/Button";
import CustomSelect from "../common/ui/CustomSelect";
import AddressAPI from "../../api/AddressAPI";
import Autocomplete from "../common/ui/Autocomplete";
import Swal from "sweetalert2";
import {useNavigate} from "react-router-dom";

const UserEdit = ({onSuccess, selectedId, setOpen, organizationId, profile, setUserFullName, onEditProfile = false}) => {
    const effectRan = useRef(false);
    const dispatch = useDispatch()
    const [user, setUser] = useState({})
    const [loading, setLoading] = useState(false)
    const [searchValue, setSearchValue] = useState("")
    const [searchResult, setSearchResult] = useState({})
    const [errors, setErrors] = useState([])
    const navigate = useNavigate()
    const roles = [{value: "ROLE_ADMIN", label: "Administrateur"},
        {value: "ROLE_MANAGER", label: "Directeur de laboratoire"},
        {value: "ROLE_USER", label: "Secrétaire/Technicien"}]
    const jobs = [{value: "Secrétariat technique", label: "Secrétariat technique"},
        {value: "Biologiste responsable", label: "Biologiste responsable"},
        {value: "Référent hématologie", label: "Référent hématologie"},
        {value: "Référent microbiologie", label: "Référent microbiologie"},
        {value: "Référent biochimie", label: "Référent biochimie"},
        {value: "Référent Génétique", label: "Référent Génétique"},
        {value: "Référent Auto immunité", label: "Référent Auto immunité"},
        {value: "Référent PMA/Fertilité", label: "Référent PMA/Fertilité"}]
    const [emailValue, setEmailValue] = useState()

    function createOrUpdateUser(e) {
        e.preventDefault()
        setLoading(true)
        return wrapRequest(dispatch, selectedId ?
            UserAPI.update(user, selectedId) :
            UserAPI.create({
                ...user,
                organizationId: profile.roles?.includes("ROLE_SUPER_ADMIN") ? organizationId : undefined
            }))
            .then(response => {
                setLoading(false)
                if (response?.status === 201) {
                    setOpen(false)
                    toast.success("Nouvel utilisateur créé");
                    onSuccess(0, 10)
                } else if (response?.status === 204) {
                    setOpen(false)
                    toast.success("Utilisateur mis à jour");
                    onSuccess(0, 10)
                } else if (response?.status === 400) {
                    setErrors(response.data.errors)
                } else if (response?.status === 409) {
                    setOpen(false)
                    toast.error("Utilisateur déjà présent dans la base.");
                    onSuccess(0, 10)
                } else {
                    setOpen(false)
                }
            })
    }

    const onSend = (e) => {
        if (selectedId && emailValue !== user.email) {
            Swal.fire({
                icon: 'warning',
                title: "Attention ! L'adresse email a été modifié.",
                text: "L'utilisateur devra utiliser cette adresse pour se connecter.",
                showCancelButton: true,
                confirmButtonText: "Oui",
                cancelButtonText: `Non`
            }).then((result) => {
                if (result.isConfirmed) {
                    return createOrUpdateUser(e);
                }
            })
        } else {
            return createOrUpdateUser(e);
        }
    }

    const getUserToUpdate = async () => {
        return wrapRequest(dispatch, UserAPI.get(selectedId))
    }

    useEffect(() => {
        if (!effectRan.current) {
            if (selectedId) {
                getUserToUpdate().then(response => {
                    if (response.status === 200) {
                        setUser({
                            ...user,
                            id: response.data?.id,
                            organizationId: response.data?.organizationId,
                            firstName: response.data?.firstName,
                            lastName: response.data?.lastName,
                            phoneNumber: response.data?.phoneNumber,
                            email: response.data?.email,
                            firstLine: response.data?.address?.firstLine,
                            secondLine: response.data?.address?.secondLine,
                            city: response.data?.address?.city,
                            zipCode: response.data?.address?.zipCode,
                            role: response.data?.roles[0],
                            job: response.data?.job
                        })
                        setUserFullName(`${response.data?.lastName} ${response.data?.firstName}`)
                        setEmailValue(response.data?.email)
                        setSearchValue(response.data?.firstLine)
                    }
                })
            }
        }
        return () => effectRan.current = true;
    }, []);

    const onSearch = (event) => {
        event.preventDefault();
        setSearchValue(event.target.value)
        if (event.target.value.length > 3) {
            document.getElementById("addressSearch").style["display"] = "block";
            return AddressAPI.fetchAddressFromSearchAPI(event.target.value.replace(new RegExp(" ", 'g'), "+"))
                .then(response => {
                    setSearchResult(response.data)
                });
        }
    }

    const onSelectItem = (event) => {
        event.preventDefault();
        const addressFromSearch = searchResult?.features.filter(feature => feature.properties.id === event.target.id)[0]
        document.getElementById("addressSearch").style["display"] = "none";
        setSearchValue( addressFromSearch.properties.name)
        setUser({
            ...user,
            firstLine: addressFromSearch.properties.name,
            city: addressFromSearch.properties.city,
            zipCode: addressFromSearch.properties.postcode
        })
    }

    const onResetPassword = (e) => {
        e.preventDefault()
        Swal.fire({
            icon: 'warning',
            title: "Voulez-vous réinitialiser le mot de passe de cet utilisateur ?",
            text: "Un email avec des instructions lui sera envoyé",
            showCancelButton: true,
            confirmButtonText: "Oui",
            cancelButtonText: `Non`
        }).then((result) => {
            if (result.isConfirmed) {
                setLoading(true)
                return wrapRequest(dispatch, UserAPI.requestPasswordModification({email: user.email}))
                    .then(response => {
                        setLoading(false)
                        if (response.status === 200) {
                            toast.success("Email de réinitialisation envoyé")
                        }
                    })
            }
        });
    }

    const onDelete = (e) => {
        e.preventDefault()
        Swal.fire({
            icon: 'warning',
            title: "Voulez-vous archiver cet utilisateur ?",
            text: "Attention, son compte sera désactivé.",
            showCancelButton: true,
            confirmButtonText: "Oui",
            cancelButtonText: `Non`
        }).then((result) => {
            if (result.isConfirmed) {
                setLoading(true)
                return wrapRequest(dispatch, UserAPI.delete(user.id))
                    .then(response => {
                        setLoading(false)
                        if (response.status === 204) {
                            toast.success("Compte archivé")
                            navigate(`/organizations/${user.organizationId}?tab=users`)
                        }
                    })
            }
        });
    }

    return <>
        <div className="row">
            <Input
                wrapperClass="col-lg-6 mb-7 fv-plugins-icon-container"
                label="Prénom"
                labelClassName="required fs-6 fw-semibold mb-2"
                className="form-control form-control-solid"
                name="firstName"
                value={user.firstName || ""}
                onChange={(e) => setUser({...user, firstName: e.target.value})}
                errors={errors}
            />
            <Input
                wrapperClass="col-lg-6 mb-7 fv-plugins-icon-container"
                label="Nom"
                labelClassName="required fs-6 fw-semibold mb-2"
                className="form-control form-control-solid"
                name="lastName"
                value={user.lastName || ""}
                onChange={(e) => setUser({...user, lastName: e.target.value})}
                errors={errors}
            />
            <Input
                wrapperClass="col-lg-6 mb-7 fv-plugins-icon-container"
                label="Email"
                disabled={onEditProfile}
                labelClassName="required fs-6 fw-semibold mb-2"
                className="form-control form-control-solid"
                name="email"
                value={user.email || ""}
                onChange={(e) => setUser({...user, email: e.target.value})}
                errors={errors}
            />
            {!onEditProfile && <CustomSelect
                wrapperClass="col-lg-6 mb-7 fv-plugins-icon-container"
                label="Role"
                name="role"
                labelClassName="required fs-6 fw-semibold mb-2"
                className="form-control form-control-solid"
                onChange={(data) => setUser({...user, role: data.value})}
                options={roles}
                isSearchable={true}
                isLoading={false}
                placeholder={"Veuillez sélectionner un rôle"}
                value={roles.filter((x) =>
                    user?.role === x.value
                )}
                errors={errors}
            />}
            {!onEditProfile && <CustomSelect
                wrapperClass="col-lg-6 mb-7 fv-plugins-icon-container"
                label="Poste"
                name="job"
                labelClassName="fs-6 fw-semibold mb-2"
                className="form-control form-control-solid"
                onChange={(data) => setUser({...user, job: data.value})}
                options={jobs}
                isSearchable={true}
                isLoading={false}
                placeholder={"Veuillez sélectionner un poste"}
                value={jobs.filter((x) =>
                    user?.job === x.value
                )}
                errors={errors}
            />}
            <Input
                wrapperClass="col-lg-6 mb-7 fv-plugins-icon-container"
                label="Numéro de téléphone"
                labelClassName="fs-6 fw-semibold mb-2"
                className="form-control form-control-solid"
                name="phoneNumber"
                value={user.phoneNumber || ""}
                onChange={(e) => setUser({...user, phoneNumber: e.target.value})}
                errors={errors}
            />
        </div>
        <div className="row">
            <Autocomplete id="addressSearch"
                          value={searchValue || ""}
                          results={searchResult?.features}
                          onSelectItem={onSelectItem}
                          label="Rechercher une adresse"
                          name="searchValue"
                          onChange={onSearch}
                          errors={errors}
                          customLabel={"Adresse"}
                          wrapperClass="col-lg-6 mb-7 fv-plugins-icon-container"
            />
            <Input
                wrapperClass="col-lg-6 mb-7 fv-plugins-icon-container"
                label="Complément d'adresse"
                labelClassName="fs-6 fw-semibold mb-2"
                className="form-control form-control-solid"
                name="secondLine"
                value={user?.secondLine || ""}
                onChange={(e) => setUser({...user, secondLine: e.target.value})}
                errors={errors}
            />
            <Input
                wrapperClass="col-lg-6 mb-7 fv-plugins-icon-container"
                label="Code postal"
                labelClassName="fs-6 fw-semibold mb-2"
                className="form-control form-control-solid"
                name="zipCode"
                value={user?.zipCode || ""}
                onChange={(e) => setUser({...user, zipCode: e.target.value})}
                errors={errors}
            />
            <Input
                wrapperClass="col-lg-6 mb-7 fv-plugins-icon-container"
                label="Ville"
                labelClassName="fs-6 fw-semibold mb-2"
                className="form-control form-control-solid"
                name="city"
                value={user?.city || ""}
                onChange={(e) => setUser({...user, city: e.target.value})}
                errors={errors}
            /></div>
        <div className="d-flex flex-end mt-10">
            {(!onEditProfile && selectedId !== undefined) && <><Button onClick={onResetPassword} loading={loading}
                                                                       wrapperClass="me-5"
                                                                       buttonClass="btn btn-secondary"
                                                                       label={"Changer le mot de passe"}
            /><Button onClick={onDelete} loading={loading}
                      wrapperClass="me-5"
                      buttonClass="btn btn-danger"
                      label={"Désactiver"}
            /></>}
            <Button onClick={onSend} loading={loading}
                    wrapperClass=""
                    label={"Enregistrer"}
            />
        </div>
    </>
}

const mapStateToProps = state => ({
    profile: state.profile
});

export default connect(mapStateToProps, null)(UserEdit);
