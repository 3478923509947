import React from "react";
import {createRoot} from "react-dom/client";
import {App} from "./App";
import {store} from "./helpers/store";
import {Provider} from 'react-redux';
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const rootElement = document.getElementById("app");
const root = createRoot(rootElement);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <ToastContainer
                position="top-right"
                autoClose={1000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
            <App/>
        </Provider>
    </React.StrictMode>
);
