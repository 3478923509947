import axios from "axios";
import {requestHeaders} from "../helpers/constants";

class OrganizationAPI {

    static create(organization) {
        return axios({
            method: 'post',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/organizations`,
            data: organization
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }

    static update(organization, id) {
        return axios({
            method: 'put',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/organizations/${id}`,
            data: organization
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }
    static getOrganizations(page, size) {
        return axios({
            method: 'get',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/organizations?page=${page}&size=${size}`,
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }

    static get(id) {
        return axios({
            method: 'get',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/organizations/${id}`,
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }



}
export default OrganizationAPI
