import {connect} from "react-redux";
import React, {useEffect, useState} from "react"
import {Speciality} from "./Speciality";
import Device from "./Devices";
import Signature from "./Signature";
import {useNavigate, useSearchParams} from "react-router-dom";
import AlertConfigurations from "../alertConfigurations/AlertConfigurations";

const ConfigurationWrapper = ({profile}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [currentTab, setCurrentTab] = useState(searchParams.get("tab") || "alerts");
    const navigate = useNavigate()
    const getCurrentTab = () => {
        if (profile?.roles?.includes("ROLE_SUPER_ADMIN")) {
            return <Speciality/>
        }
        if (profile?.roles?.includes("ROLE_DOCTOR")) {
            switch (currentTab) {
                case "alerts":
                    return <AlertConfigurations/>
                case "device":
                    return <Device/>
                case "signature":
                default:
                    return <Signature profile={profile}/>
            }
        }
    }
    const selectTab = (value) => {
        setCurrentTab(value)
        navigate({
            pathname: `/settings`,
            search: `?tab=${value}`,
        });
    }
    useEffect(() => {
        if (profile?.roles?.includes("ROLE_SUPER_ADMIN")) {
            setCurrentTab("speciality")
        }
    }, []);

    return <>
        <div className="card mb-5">
            <div className="card-body pt-0 pb-0">
                <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
                    {profile?.roles?.includes("ROLE_SUPER_ADMIN") &&
                        <li className="nav-item mt-2 cursor-pointer">
                            <a className={`nav-link text-active-primary ms-0 me-10 py-5 ${currentTab === "speciality" ? "active" : ""}`}
                               onClick={e => selectTab("speciality")}
                            >Spécialités</a>
                        </li>}
                    {profile?.roles?.includes("ROLE_DOCTOR") &&
                        <>
                            <li className="nav-item mt-2 cursor-pointer">
                                <a className={`nav-link text-active-primary ms-0 me-10 py-5 ${currentTab === "signature" ? "active" : ""}`}
                                   onClick={e => selectTab("signature")}
                                >Signature</a>
                            </li>
                            <li className="nav-item mt-2 cursor-pointer">
                                <a className={`nav-link text-active-primary ms-0 me-10 py-5 ${currentTab === "alerts" ? "active" : ""}`}
                                   onClick={e => selectTab("alerts")}
                                >Alertes</a>
                            </li>
                            <li className="nav-item mt-2 cursor-pointer">
                                <a className={`nav-link text-active-primary ms-0 me-10 py-5 ${currentTab === "device" ? "active" : ""}`}
                                   onClick={e => selectTab("device")}
                                >Dispositifs de mesure</a>
                            </li>
                        </>}
                </ul>
            </div>


        </div>
        {getCurrentTab()}
    </>
}
const mapStateToProps = state => ({
    profile: state.profile
});

export default connect(mapStateToProps, null)(ConfigurationWrapper);
