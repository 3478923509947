import {combineReducers} from "redux";
import session from "./sessionReducer"
import profile from "./profileReducer"
import errors from "./errorReducer"
import loading from "./loadingReducer"

const rootReducer = combineReducers({
    session,
    profile,
    loading,
    errors
})

export default rootReducer;
