import React, {useEffect, useRef, useState} from "react";
import {wrapRequest} from "../../helpers/auth";
import LaboratoryAPI from "../../api/LaboratoryAPI";
import {useDispatch} from "react-redux";
import {call} from "../../helpers/methods";
import ContactAPI from "../../api/ContactAPI";
import {normalizeText} from "../../helpers/textUtils";

const LaboratoryContactModal = ({record}) => {
    const [laboratory, setLaboratory] = useState({})
    const [contacts, setContact] = useState([])
    const dispatch = useDispatch()
    const effectRan = useRef(false);

    useEffect(() => {
        if (!effectRan.current) {
            wrapRequest(dispatch, LaboratoryAPI.get(record?.laboratoryId))
                .then(response => {
                    if (response.status === 200) {
                        setLaboratory(response.data)
                    }
                    wrapRequest(dispatch, ContactAPI.getContacts(0, 100, record?.laboratoryId)).then(response => {
                        if (response.status === 200) {
                            setContact(response.data.content)
                        }

                    })
                })
        }
        return () => effectRan.current = true;
    }, []);


    return <div>
        <div className="fs-6">
            <div className="d-flex">
                <div className="d-flex gap-7 align-items-center">
                    <div className="symbol symbol-circle symbol-100px">
                        <span className="symbol-label bg-light-danger text-danger fs-3x fw-bolder">L</span>
                    </div>
                    <div className="d-flex flex-column gap-2">
                        <h3 className="mb-0">Numéro principal</h3>
                        <div className="d-flex align-items-center gap-2">
                            <span className="svg-icon svg-icon-2">
															<svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
																<path opacity="0.3"
                                                                      d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z"
                                                                      fill="currentColor"></path>
																<path
                                                                    d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z"
                                                                    fill="currentColor"></path>
															</svg>
														</span>
                            <a href={`mailto:${laboratory?.email}`}
                               className="text-muted text-hover-primary">{laboratory?.email || "Aucun email renseigné"}</a>
                        </div>
                        <div className="d-flex align-items-center gap-2">
                            <span className="svg-icon svg-icon-2">
															<svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
																<path
                                                                    d="M5 20H19V21C19 21.6 18.6 22 18 22H6C5.4 22 5 21.6 5 21V20ZM19 3C19 2.4 18.6 2 18 2H6C5.4 2 5 2.4 5 3V4H19V3Z"
                                                                    fill="currentColor"></path>
																<path opacity="0.3" d="M19 4H5V20H19V4Z"
                                                                      fill="currentColor"></path>
															</svg>
														</span>
                            <a href="#" onClick={() => call(dispatch, record, laboratory?.phoneNumber)}
                               className="text-muted text-hover-primary">{laboratory?.phoneNumber || "Aucun numéro renseigné"}</a>
                        </div>
                    </div>
                </div>
            </div>


            {contacts?.map((contact, index) => <div key={index} className="d-flex gap-7 align-items-center mt-6">
                <div className="symbol symbol-circle symbol-100px">
                    <span className="symbol-label bg-light-primary text-primary fs-3x fw-bolder">L</span>
                </div>
                <div className="d-flex flex-column gap-2">
                    <h3 className="mb-0">{normalizeText(contact.lastName, 20, "capitalize")} {normalizeText(contact.firstName, 20, "capitalize")}</h3>
                    <div className="d-flex align-items-center gap-2">
                            <span className="svg-icon svg-icon-2">
															<svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
																<path opacity="0.3"
                                                                      d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z"
                                                                      fill="currentColor"></path>
																<path
                                                                    d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z"
                                                                    fill="currentColor"></path>
															</svg>
														</span>
                        <a href={`mailto:${contact?.email}`}
                           className="text-muted text-hover-primary">{contact?.email || "Aucun email renseigné"}</a>
                    </div>
                    <div className="d-flex align-items-center gap-2">
                            <span className="svg-icon svg-icon-2">
															<svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
																<path
                                                                    d="M5 20H19V21C19 21.6 18.6 22 18 22H6C5.4 22 5 21.6 5 21V20ZM19 3C19 2.4 18.6 2 18 2H6C5.4 2 5 2.4 5 3V4H19V3Z"
                                                                    fill="currentColor"></path>
																<path opacity="0.3" d="M19 4H5V20H19V4Z"
                                                                      fill="currentColor"></path>
															</svg>
														</span>

                        <a href="#" onClick={() => call(dispatch, record, contact?.phoneNumber)}
                           className="text-muted text-hover-primary">{contact?.phoneNumber || "Aucun numéro renseigné"}</a>
                    </div>
                </div>
            </div>)}
        </div>
    </div>
}
export default LaboratoryContactModal
