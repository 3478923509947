import React from "react"
import {OverlayTrigger, Tooltip} from "react-bootstrap";

const ConditionalOverlay = ({show = true, wrapped = false, component, message, position="top"}) => {
    const popover = (
        <Tooltip id="tooltip-disabled">{message}</Tooltip>
    );
    if (show && wrapped) {
        return <OverlayTrigger trigger={['hover', 'focus']} placement={position} overlay={popover}>
            <div>
                {component}
            </div>
        </OverlayTrigger>
    } else if (show && !wrapped) {
        return <OverlayTrigger trigger={['hover', 'focus']} placement={position} overlay={popover}>
            {component}
        </OverlayTrigger>
    } else {
        return component;
    }

}
export default ConditionalOverlay;
