import React, {useEffect, useRef, useState} from "react";
import {wrapRequest} from "../../helpers/auth";
import OrganizationAPI from "../../api/OrganizationAPI";
import {useDispatch} from "react-redux";
import CustomSelect from "../common/ui/CustomSelect";
import {Button} from "../common/ui/Button";
import RecordAPI from "../../api/RecordAPI";
import {toast} from "react-toastify";

export const ImportRecordModal = ({setOpen, onSuccess}) => {
    const effectRan = useRef(false);
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const [selectedId, setSelectedId] = useState()
    const [organizations, setOrganizations] = useState([])
    const [errors, setErrors] = useState([])
    const [file, setFile] = useState();

    const handleFileChange = (e) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
        }
    }

    const onSend = () => {
        setLoading(true)
        return wrapRequest(dispatch, RecordAPI.simulate(file, selectedId))
            .then(response => {
                setLoading(false)
                if (response?.status === 201) {
                    setOpen(false)
                    toast.success("Résultats importés", {
                        onClose: () => onSuccess(0, 10)
                    });
                } else if (response?.status === 400) {
                    setErrors(response.data.errors)
                } else {
                    setOpen(false)
                }
            })
    }

    const loadOptions = () => {
        wrapRequest(dispatch, OrganizationAPI.getOrganizations(0, 500))
            .then(response => {
                    if (response.status === 200) {
                        setOrganizations(response.data.content.map(element => {
                            return {
                                value: element.id,
                                label: `${element.name} `
                            }
                        }))
                    }
                }
            )
    }

    useEffect(() => {
        if (!effectRan.current) {
            loadOptions();
        }
        return () => effectRan.current = true;
    }, []);


    return <><CustomSelect
        wrapperClass="fv-row mb-7 fv-plugins-icon-container"
        label="Nom du client"
        name="organizationId"
        labelClassName="required fs-6 fw-semibold mb-2"
        className="form-control form-control-solid"
        onChange={(data) => setSelectedId(data.value)}
        options={organizations}
        isSearchable={true}
        isLoading={false}
        placeholder={"Veuillez sélectionner un client"}
        value={organizations.filter((x) =>
            selectedId === x.value
        )}
        errors={errors}
    />
        <div>
            <label htmlFor="file" className="btn btn-large btn-primary">
                Choisir un fichier CSV d'import
            </label>
            <input id="file" type="file" onChange={handleFileChange} accept="text/csv" hidden/> <span
            className="fw-semibold ms-4">{file?.name}</span>
        </div>
        {file &&
            <div className="d-flex flex-end">
                <Button onClick={onSend} loading={loading}
                        wrapperClass=""
                        label={"Importer"}
                />
            </div>}
    </>
}
