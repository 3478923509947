import React, {useState} from "react"
import {wrapRequest} from "../../helpers/auth";
import PatientAPI from "../../api/PatientAPI";
import {useDispatch} from "react-redux";
import Swal from "sweetalert2";
import {toast} from "react-toastify";
import RecordAPI from "../../api/RecordAPI";

const PatientAssignation = ({recordId, getRecords}) => {
    const dispatch = useDispatch()
    const [patientSearch, setPatientSearch] = useState()
    const [loading, setLoading] = useState(false)
    const [patients, setPatients] = useState([])

    const getPatients = (page, size) => {
        return wrapRequest(dispatch, PatientAPI.getPatients(page, size, patientSearch))
            .then(response => {
                setLoading(false)
                if (response.status === 200) {
                    setPatients(response.data.content)
                }
            })
    }

    const onSelect = (e) => {
        e.preventDefault()
        const id = e.target.id
        Swal.fire({
            title: "Voulez-vous associer ce patient ?",
            text: "Attention ! Cette action est irréversible",
            showCancelButton: true,
            confirmButtonText: "Oui",
            cancelButtonText: `Non`,
            icon: "warning"
        }).then((result) => {
            if (result.isConfirmed) {
                return wrapRequest(dispatch, RecordAPI.assignPatient(recordId, id))
                    .then(response => {
                        if (response.status === 204) {
                            toast.success("Patient associé")
                            getRecords(0, 10)
                        }
                    })
            }
        });
    }


    return <>
        <div className="d-flex align-items-center position-relative me-4 ">
                        <span className="svg-icon svg-icon-1 position-absolute ms-4 input-group-lg">
												<svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
													<rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546"
                                                          height="2" rx="1" transform="rotate(45 17.0365 15.1223)"
                                                          fill="currentColor"></rect>
													<path
                                                        d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                                        fill="currentColor"></path>
												</svg>
											</span>
            <input id={"patientOrDoctorSearch"} onChange={(e) => {
                e.preventDefault()
                setPatientSearch(e.target.value)
                getPatients(0, 10)
            }} type="text" className="form-control form-control-solid ps-14"
                   placeholder="Rechercher un patient par nom ou prénom"/>
        </div>
        <div className="table-responsive mt-8">

            <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                <thead>
                <tr className="border-0">
                    <th className="p-0"></th>
                    <th className="p-0"></th>
                </tr>
                </thead>
                <tbody>
                {patients?.map((patient, idx) => <tr key={idx}>
                    <td>
                        <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                                <span
                                   className="text-dark fw-bold mb-1 fs-6">{patient.lastName} {patient.firstName}</span>
                                <span
                                   className="text-muted fw-semibold text-muted d-block fs-7">
                                        <span
                                            className="text-dark">Numéro de sécurité sociale</span>: {patient.socialSecurityNumber}
                                </span>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div className=" d-flex flex-end ">
                            <a id={patient.id} href="#" onClick={onSelect} className="btn btn-sm btn-light btn-active-primary">
                            <span className="svg-icon svg-icon-3">
													<svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
														<rect opacity="0.5" x="11.364" y="20.364" width="16" height="2"
                                                              rx="1" transform="rotate(-90 11.364 20.364)"
                                                              fill="currentColor"></rect>
														<rect x="4.36396" y="11.364" width="16" height="2" rx="1"
                                                              fill="currentColor"></rect>
													</svg>
												</span>Choisir</a>
                        </div>


                    </td>
                </tr>)}
                </tbody>
            </table>
        </div>
    </>
}

export default PatientAssignation
