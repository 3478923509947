import React, {useEffect, useRef, useState} from "react"
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {wrapRequest} from "../../helpers/auth";
import LogAPI from "../../api/LogAPI";
import NotificationAlert from "../common/ui/NotificationAlert";
import Pagination from "@vlsergey/react-bootstrap-pagination";
import {useTranslation} from "react-i18next";
import {LoadableComponent} from "../common/ui/LoadableComponent";

export const LogActions = () => {

    const effectRan = useRef(false);
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const [logs, setLogs] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [totalResults, setTotalResults] = useState(0);
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const onPageChange = ({target}) => {
        const page = target.value;
        navigate({
            pathname: "/logs",
            search: `?page=${page}`,
        });
        getLogs(page, 30);
    }

    const getLogs = (page, size) => {
        setLoading(true)
        return wrapRequest(dispatch, LogAPI.getLogs(page, size))
            .then(response => {
                setLoading(false)
                if (response.status === 200) {
                    setLogs(response.data.content)
                    setTotalPages(response.data.totalPages)
                    setCurrentPage(response.data.number)
                    setTotalResults(response.data.totalElements)
                }
            })
    }

    useEffect(() => {
        if (!effectRan.current) {
            getLogs(0, 30)
        }
        return () => effectRan.current = true;
    }, []);

    return <div className="card">
        <div className="card-header border-0 pt-6">
            <div className="card-title">Journal des évènements  ({totalResults} trouvé{totalResults > 1 ? "s" : ""})</div>
        </div>

        <div className="card-body pt-0">
            <div className="dataTables_wrapper dt-bootstrap4 no-footer mt-8">
                <LoadableComponent loading={loading} component={<>{logs?.length === 0 ? <NotificationAlert
                        title={"Aucun log trouvé"}
                        message={"Veuillez commencer à configurer la plateforme"}
                    /> :
                    <div className="table-responsive">
                        <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
                            <thead className="border-gray-200 fs-5 fw-semibold bg-lighten">
                            <tr>
                                <th width="20%" className="min-w-125px sorting">Date</th>
                                <th width="60%" className="min-w-125px sorting">Action</th>
                                <th width="20%" className="min-w-125px sorting">Utilisateur</th>
                            </tr>
                            </thead>
                            <tbody className="fw-semibold text-gray-600">
                            {logs?.map((log, idx) => <tr key={idx}
                                                         className={idx % 2 === 0 ? "even" : "odd"}>
                                    <td data-order="2020-12-14T20:43:00+01:00">{moment.utc(log?.createdDate).local().format(t("common:dateTimeFormat"))}</td>
                                    <td data-order="2020-12-14T20:43:00+01:00">{t(`logs:${log?.action}`, log?.metadata)}</td>
                                    <td data-order="2020-12-14T20:43:00+01:00">{log.userData?.lastName} {log?.userData?.firstName} </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                        <Pagination
                            aroundCurrent={2}
                            showFirstLast={true}
                            showPrevNext={true}
                            atBeginEnd={0}
                            onChange={onPageChange}
                            value={currentPage}
                            totalPages={totalPages}
                        />
                    </div>}
                </>}/>
            </div>
        </div>

    </div>
}
