import axios from "axios";
import {requestHeaders} from "../helpers/constants";

class UserAPI {

    static create(user) {
        return axios({
            method: 'post',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/users`,
            data: user
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }

    static update(user, id) {
        return axios({
            method: 'put',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/users/${id}`,
            data: user
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }

    static delete(id) {
        return axios({
            method: 'delete',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/users/${id}`,
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }

    static get(id) {
        return axios({
            method: 'get',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/users/${id}`
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }

    static getUsers(page, size, organizationId) {
        return axios({
            method: 'get',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/users?page=${page}&size=${size}&organizationId=${organizationId}`,
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }

    static requestPasswordModification(data) {
        return axios({
            method: 'post',
            url: `${process.env.API_BASE_URL}/auth/request-change-password`,
            data: data
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }

    static resetPassword(data) {
        return axios({
            method: 'put',
            url: `${process.env.API_BASE_URL}/auth/reset-password`,
            data: data
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }

}

export default UserAPI;
