import React from "react";
import NotificationAlert from "../common/ui/NotificationAlert";

const Subscriptions = ({}) => {
    return <div className="card">
        <div className="card-header border-0 pt-6">
            <div className="card-title">Abonnement</div>
        </div>
        <div className="card-body pt-0">
            <div id="kt_customers_table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer mt-8">
                <NotificationAlert
                    title={"Aucun abonnement souscrit"}
                    message={"Veuillez contacter notre équipe commerciale"}
                /></div>
        </div>
    </div>
}
export default Subscriptions
