import React, {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {LoadableComponent} from "../common/ui/LoadableComponent";
import {wrapRequest} from "../../helpers/auth";
import MetricAPI from "../../api/MetricAPI";

const Connectors = ({organizationId}) => {

    const effectRan = useRef(false);
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [count, setCount] = useState(0)

    const getCounts = (connectorName) => {
        setLoading(true)
        return wrapRequest(dispatch, MetricAPI.getByConnector(organizationId, connectorName))
            .then(response => {
                setLoading(false)
                if (response.status === 200) {
                    setCount(response.data.count)
                }
            })
    }

    useEffect(() => {
        if (!effectRan.current) {
            getCounts("nYna")
        }
        return () => effectRan.current = true;
    }, []);
    return <div className="card">
        <div className="card-header border-0">
            <div className="card-title">Connecteurs</div>

        </div>
        <div className="card-body">

            <LoadableComponent component={<div className="d-flex mb-7">
                <div className="symbol symbol-60px flex-shrink-0 me-4">
                    <img src="https://byg4lab.com/wp-content/uploads/2020/08/BYG_Y_Outline_CMJN.png" alt=""/>
                </div>

                <div className="d-flex align-items-center flex-wrap flex-grow-1 mt-n2 mt-lg-n1">
                    <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 pe-3">
                        <a href="#" className="fs-5 text-gray-800 text-hover-primary fw-bold">nYna
                            <div
                                className="btn btn-icon position-relative btn btn-icon w-30px h-30px w-md-40px h-md-40px pulse pulse-success">
                                <i className="bi bi-book fs-2"></i>
                                <span className="pulse-ring w-45px h-45px"></span>
                            </div>
                        </a>

                        <span className="text-gray-400 fw-semibold fs-7">Edité par :
														<a href="https://byg4lab.com/" target="_blank"
                                                           className="text-primary fw-bold"> Byg4Lab</a></span>

                    </div>

                    <div className="text-end py-lg-0 py-2">
                        <span className="text-gray-800 fw-bolder fs-3">{count}</span>
                        <span className="text-gray-400 fs-7 fw-semibold d-block">Enregistrements</span>
                    </div>
                </div>
            </div>} loading={loading}/>
        </div>
    </div>
}

export default Connectors
