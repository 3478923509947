import initialState from './initialState';
import * as types from "../actions/actionTypes";

export default function loadingReducer(state = initialState.loading, action) {
    switch (action.type) {
        case types.START_LOADING:
            return true
        case types.STOP_LOADING:
            return false
        default:
            return state;
    }
}
