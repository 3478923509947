import Swal from "sweetalert2";
import {wrapRequest} from "./auth";
import LogAPI from "../api/LogAPI";
import {toast} from "react-toastify";
import React from "react";

export function formatMessage(value){
    return value.replaceAll(".", "_")
}
export function call(dispatch, record, phoneNumber){
    Swal.fire({
        title: "Voulez-vous appeler ce contact ?",
        text: "Votre tentative d'appel sera tracée.",
        showCancelButton: true,
        confirmButtonText: "Oui",
        cancelButtonText: `Non`,
        icon: "warning"
    }).then((result) => {
        if (result.isConfirmed) {
            return wrapRequest(dispatch, LogAPI.create({
                type: "CONTACT_CALLED",
                parameters: {phoneNumber: phoneNumber, recordId: record.id}
            }))
                .then(response => {
                    if (response.status === 201) {
                        toast.success("Appel enregistré.")
                        window.location.href = `tel:${phoneNumber}`
                    }
                })
        }
    });
}

export const getRoleBadge = (user, t, height = 1) => {
    switch (user?.roles[0]) {
        case 'ROLE_USER':
            return <span
                className={`badge badge-lg badge-light-info fw-bolder fs-8 px-2 py-${height} ms-2`}>{t("users:role_user")}</span>
        case 'ROLE_MANAGER':
            return <span
                className={`badge badge-light-danger fw-bolder fs-8 px-2 py-${height} ms-2`}>{t("users:role_manager")}</span>
        case 'ROLE_ADMIN':
            return <span
                className={`badge badge-light-success fw-bolder fs-8 px-2 py-${height} ms-2`}>{t("users:role_admin")}</span>
        case 'ROLE_SUPER_ADMIN':
            return <span
                className={`badge badge-light-info fw-bolder fs-8 px-2 py-${height} ms-2`}>{t("users:role_super_admin")}</span>
        case 'ROLE_DOCTOR':
            return <span
                className={`badge badge-warning fw-bolder fs-8 px-2 py-${height} ms-2`}>{t("users:role_doctor")}</span>
    }
}
