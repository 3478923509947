import axios from "axios";
import {requestHeaders} from "../helpers/constants";

class AdditionalAnalyzesAPI {

    static create(additionalAnalysis) {
        return axios({
            method: 'post',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/additional-analyzes`,
            data: additionalAnalysis
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }

    static get(id) {
        return axios({
            method: 'get',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/additional-analyzes/${id}`
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }

    static accept(id, comments) {
        return axios({
            method: 'put',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/additional-analyzes/${id}`,
            data: {comments: comments}
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }

    static getFile(id) {
        return axios({
            method: 'get',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/additional-analyzes/${id}/file`
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }

    static getAll(page, size) {
        let url = `${process.env.API_BASE_URL}/additional-analyzes?page=${page}&size=${size}`
        return axios({
            method: 'get',
            headers: requestHeaders(),
            url: url,
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }

    static getTemplates(page=0, size=100) {
        let url = `${process.env.API_BASE_URL}/analysis-templates?page=${page}&size=${size}`
        return axios({
            method: 'get',
            headers: requestHeaders(),
            url: url,
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }
}
export default AdditionalAnalyzesAPI
