import React from "react";

export const Button = ({
                           label = "Je me connecte",
                           wrapperClass = "d-grid mb-10",
                           buttonClass = "btn btn-primary",
                           loadingLabel = "Veuillez patienter...",
                           onClick = (e) => {
                               e.preventDefault()
                           },
                           loading = false
                       }) => {
    return <div className={wrapperClass}>
        <button type="submit" id="kt_sign_in_submit" className={buttonClass} onClick={onClick}
                data-kt-indicator={loading ? "on" : "off"}
                disabled={loading}>
            <span className="indicator-label">{label}</span>
            <span className="indicator-progress">{loadingLabel}
                <span
                    className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
        </button>
    </div>
}
