import axios from "axios";
import {requestHeaders} from "../helpers/constants";

class MetricAPI {
    static getByConnector(organizationId, connectorName) {
        return axios({
            method: 'get',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/metrics?connectorName=${connectorName}&organizationId=${organizationId}`
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }
}

export default MetricAPI
