import React, {useEffect, useRef, useState} from "react";

export default function withClickOutside(WrappedComponent) {
    return (props) => {
        const [open, setOpen] = useState(false);
        const ref = useRef();
        useEffect(() => {
            const handleClickOutside = (event) => {
                if (!ref.current?.contains(event.target)) {
                    setOpen(false);
                }
            };
            document.addEventListener("mousedown", handleClickOutside);
        }, [ref]);

        return <WrappedComponent open={open} setOpen={setOpen} ref={ref} props={props}/>;
    };
}
