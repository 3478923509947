import React, {useEffect, useRef, useState} from "react"
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {wrapRequest} from "../../helpers/auth";
import {toast} from "react-toastify";
import PatientAPI from "../../api/PatientAPI";
import {Input} from "../common/ui/Input";
import {Button} from "../common/ui/Button";
import CustomSelect from "../common/ui/CustomSelect";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {formatMessage} from "../../helpers/methods";
import {useTranslation} from "react-i18next";

const PatientEdit = ({
                         selectedId,
                         setOpen,
                         onSuccess
                     }) => {
    const {t} = useTranslation()
    const effectRan = useRef(false);
    const dispatch = useDispatch()
    const [patient, setPatient] = useState({})
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState([])
    const navigate = useNavigate()

    const genders = [{
        value: "M", label: "Homme"},
        {value: "F", label: "Femme"},
        {value: "U", label: "Non renseigné"}]

    const onSend = (e) => {
        e.preventDefault()
        setLoading(true)
        return wrapRequest(dispatch, selectedId ?
            PatientAPI.update(patient, selectedId) :
            PatientAPI.create(patient))
            .then(response => {
                setLoading(false)
                if (response?.status === 201) {
                    setOpen(false)
                    toast.success("Nouveau patient enregistré");
                    onSuccess(0, 30)
                } else if (response?.status === 204) {
                    setOpen(false)
                    toast.success("Patient mis à jour");
                    onSuccess(0, 30)
                } else if (response?.status === 400) {
                    setErrors(response.data.errors)
                } else {
                    setOpen(false)
                }
            })
    }

    const getPatientToUpdate = () => {
         return wrapRequest(dispatch, PatientAPI.get(selectedId))
    }

    useEffect(() => {
        if (!effectRan.current) {
            if (selectedId) {
                getPatientToUpdate().then(response => {
                    if (response.status === 200) {
                        setPatient({
                            ...patient,
                            firstName: response.data?.firstName,
                            lastName: response.data?.lastName,
                            birthName: response.data?.lastName,
                            birthDate: response.data?.birthDate,
                            phoneNumber: response.data?.phoneNumber,
                            mobileNumber: response.data?.mobileNumber,
                            email: response.data?.email,
                            ddn: response.data?.ddn,
                            gender: response.data?.gender,
                            socialSecurityNumber: response.data?.socialSecurityNumber
                        })
                    }
                })
            }
        }
        return () => effectRan.current = true;
    }, []);

    const onDelete = (e) => {
        e.preventDefault()
        Swal.fire({
            icon: "warning",
            title: "Voulez-vous archiver ce patient ?",
            text: "Attention ! Cette action est irréversible",
            showCancelButton: true,
            confirmButtonText: "Oui",
            cancelButtonText: `Non`
        }).then((result) => {
            if (result.isConfirmed) {
                return wrapRequest(dispatch, PatientAPI.delete(selectedId))
                    .then(response => {
                        if (response.status === 204) {
                            toast.success("Patient archivé")
                            navigate(`/patients`)
                        }
                    })
            }
        });
    }

    const isNotInFuture = (date = null) => {
        return date <= new Date()
    }

    return <>
        <div className="row">
            <Input
                wrapperClass="col-lg-6 mb-7 fv-plugins-icon-container"
                label="Numéro DDN"
                labelClassName="required fs-6 fw-semibold mb-2"
                className="form-control form-control-solid"
                name="ddn"
                value={patient.ddn || ""}
                onChange={(e) => setPatient({...patient, ddn: e.target.value})}
                errors={errors}
            />
            <Input
                wrapperClass="col-lg-6 mb-7 fv-plugins-icon-container"
                label="Numéro de sécurité sociale"
                labelClassName="fs-6 fw-semibold mb-2"
                className="form-control form-control-solid"
                name="socialSecurityNumber"
                value={patient.socialSecurityNumber || ""}
                onChange={(e) => setPatient({...patient, socialSecurityNumber: e.target.value})}
                errors={errors}
            />
            <Input
                wrapperClass="col-lg-6 mb-7 fv-plugins-icon-container"
                label="Nom"
                labelClassName="required fs-6 fw-semibold mb-2"
                className="form-control form-control-solid"
                name="lastName"
                value={patient.lastName || ""}
                onChange={(e) => setPatient({...patient, lastName: e.target.value})}
                errors={errors}
            />
            <Input
                wrapperClass="col-lg-6 mb-7 fv-plugins-icon-container"
                label="Nom de naissance"
                labelClassName="fs-6 fw-semibold mb-2"
                className="form-control form-control-solid"
                name="birthName"
                value={patient.birthName || ""}
                onChange={(e) => setPatient({...patient, birthName: e.target.value})}
                errors={errors}
            />
            <Input
                wrapperClass="col-lg-6 mb-7 fv-plugins-icon-container"
                label="Prénom"
                labelClassName="required fs-6 fw-semibold mb-2"
                className="form-control form-control-solid"
                name="firstName"
                value={patient.firstName || ""}
                onChange={(e) => setPatient({...patient, firstName: e.target.value})}
                errors={errors}
            />
            <Input
                wrapperClass="col-lg-6 mb-7 fv-plugins-icon-container"
                label="Email"
                labelClassName="required fs-6 fw-semibold mb-2"
                className="form-control form-control-solid"
                name="email"
                value={patient.email || ""}
                onChange={(e) => setPatient({...patient, email: e.target.value})}
                errors={errors}
            />
            <Input
                wrapperClass="col-lg-6 mb-7 fv-plugins-icon-container"
                label="Numéro de téléphone"
                labelClassName="fs-6 fw-semibold mb-2"
                className="form-control form-control-solid"
                name="phoneNumber"
                value={patient.phoneNumber || ""}
                onChange={(e) => setPatient({...patient, phoneNumber: e.target.value})}
                errors={errors}
            />
            <Input
                wrapperClass="col-lg-6 mb-7 fv-plugins-icon-container"
                label="Numéro de portable"
                labelClassName="required fs-6 fw-semibold mb-2"
                className="form-control form-control-solid"
                name="mobileNumber"
                value={patient.mobileNumber || ""}
                onChange={(e) => setPatient({...patient, mobileNumber: e.target.value})}
                errors={errors}
            />
            <div className="col-lg-6  d-grid gap-2  mb-2">
                <label className="fs-6 fw-semibold me-2">Date de naissance</label>
                <DatePicker
                    style="width:100%"
                    className={"form-control form-control-solid w"}
                    selected={Date.parse(patient?.birthDate)}
                    onChange={(date) => setPatient({...patient, birthDate: date})}
                    locale="fr"
                    dateFormat="P"
                    placeholderText="DD/MM/YYYY"
                    filterDate={isNotInFuture}
                />
                {(errors?.filter(e => e.fieldName === "birthDate").shift()?.message) &&
                    <div
                        className="fv-plugins-message-container invalid-feedback">{t("errors:" + formatMessage(errors?.filter(e => e.fieldName === "birthDate").shift()?.message))}</div>
                }
            </div>
            <CustomSelect
                wrapperClass="col-lg-6 mb-7 fv-plugins-icon-container"
                label="Genre"
                name="gender"
                labelClassName="required fs-6 fw-semibold mb-2"
                className="form-control form-control-solid"
                onChange={(data) => setPatient({...patient, gender: data.value})}
                options={genders}
                isSearchable={true}
                isLoading={false}
                placeholder={"Veuillez sélectionner un genre"}
                value={genders.filter((x) =>
                    patient?.gender === x.value
                )}
                errors={errors}
            />
        </div>
        <div className="d-flex flex-end">
            {selectedId !== undefined && <Button onClick={onDelete} loading={loading}
                                                 wrapperClass="me-5"
                                                 buttonClass="btn btn-danger"
                                                 label={"Archiver"}
            />}
            <Button onClick={onSend} loading={loading}
                    wrapperClass=""
                    label={"Enregistrer"}
            />
        </div>
    </>
}

export default PatientEdit;
