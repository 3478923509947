import React from "react"
import FilteredResultPage from "./FilteredResultPage";

const Alerts = () => {
    return <FilteredResultPage searchRequest={{
        alertStatuses: ["URGENT"],
        supportStatuses: ["PENDING"]
    }}/>
}

export default Alerts