import React from "react"

const RecommendationTypeSelect = ({activeItem, setActiveItem}) => {
    return <>
        <ul className="nav" role="tablist">
            <li className="nav-item" role="presentation"><a onClick={()=>setActiveItem("etiology")}
                className={`nav-link btn btn-sm btn-color-muted btn-active-color-primary btn-active-light fw-semibold fs-7 px-6 me-1 ${activeItem === "etiology" ? "active" : ""}`}>
                Etiologie</a></li>
            <li className="nav-item" role="presentation"><a onClick={()=>setActiveItem("semiology")}
                className={`nav-link btn btn-sm btn-color-muted btn-active-color-primary btn-active-light fw-semibold fs-7 px-6 me-1  ${activeItem === "semiology" ? "active" : ""}`}>
                Sémiologie</a></li>
            <li className="nav-item" role="presentation"><a onClick={()=>setActiveItem("medicalCare")}
                className={`nav-link btn btn-sm btn-color-muted btn-active-color-primary btn-active-light fw-semibold fs-7 px-6 me-1  ${activeItem === "medicalCare" ? "active" : ""}`}>
                Prise en charge</a></li>
            <li className="nav-item" role="presentation"><a onClick={()=>setActiveItem("exploration")}
                className={`nav-link btn btn-sm btn-color-muted btn-active-color-primary btn-active-light fw-semibold fs-7 px-6 me-1  ${activeItem === "exploration" ? "active" : ""}`}>
                Exploration</a></li>
        </ul>
    </>
}
export default RecommendationTypeSelect