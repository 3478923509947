import React, {useEffect, useState} from "react";
import {Modal, OverlayTrigger, Popover} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {normalizeText} from "../../helpers/textUtils";
import {ResultTable} from "./ResultTable";
import LogView from "./LogView";
import {Button} from "../common/ui/Button";
import {useDispatch} from "react-redux";
import {wrapRequest} from "../../helpers/auth";
import RecordAPI from "../../api/RecordAPI";
import Swal from "sweetalert2";
import {toast} from "react-toastify";
import SupportView from "./SupportView";
import SupportDetails from "./SupportDetails";
import moment from "moment"
import AdditionalAnalysisForm from "../additionalAnalyzes/AdditionalAnalysisForm";

export const ResultCell = ({sourceRecord, profile, refresh}) => {
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const [open, setOpen] = useState(false)
    const [validationMode, setValidationMode] = useState(false)
    const [currentView, setCurrentView] = useState("results")
    const [validatedCodes, setValidatedCodes] = useState([])

    const [record, setRecord] = useState(sourceRecord)

    const getStatus = (record) => {
        if (record?.supportStatus === "PENDING" && record?.alertStatus === "URGENT") {
            return "btn-outline-danger btn-active-light-danger"
        }
        if (record?.supportStatus === "PENDING" && record?.alertStatus === "ABNORMAL") {
            return "btn-outline-dark-warning btn-active-dark-warning"
        }
        return "btn-outline-dark btn-active-light-dark"
    }


    const getResultLineContent = (record) => {
        if (record?.supportStatus === "PENDING" && record?.alertStatus === "URGENT") {
            return normalizeText(record?.analysisRecords
                .filter(result => result.urgent)
                .map(result => result?.label)[0], 200, "capitalize-each")
        }
        if (record?.supportStatus === "PENDING" && record?.alertStatus === "ABNORMAL") {
            return normalizeText(record?.analysisRecords
                .filter(result => result.abnormal)
                .map(result => result?.label)[0], 200, "capitalize-each")
        }
        return normalizeText(record?.analysisRecords
            .map(result => result?.label)[0], 200, "capitalize-each")
    }

    const getStateIcon = (record) => {
        if (record.supportStatus !== "PENDING" && record.supportStatus !== "UNNECESSARY") {
            return <div
                className="d-flex flex-center fs-7 fw-bolder ms-auto h-100">
                Voir plus... <OverlayTrigger rootClose={true} trigger={["hover", "hover"]} placement="right"
                                             overlay={<Popover>
                                                 <Popover.Body>
                                                     Prise en charge effectuée
                                                 </Popover.Body>
                                             </Popover>}><i
                className="fas fa-briefcase-medical fs-2 ms-2 text-primary"></i>
            </OverlayTrigger>
                <div
                    className="badge badge-light-primary">{moment.utc(record?.date).locale(t("common:dateLocale")).fromNow()}</div>
            </div>
        }
        if (record.readStatus === "READ") {
            return <div
                className="d-flex flex-center fs-7 fw-bolder ms-auto h-100">
                Voir plus... <OverlayTrigger rootClose={true} trigger={["hover", "hover"]} placement="right"
                                             overlay={<Popover>
                                                 <Popover.Body>
                                                     Résultat vu par le prescripteur
                                                 </Popover.Body>
                                             </Popover>}><i
                className="fas fa-check-circle fs-2 ms-2 text-success"></i></OverlayTrigger>
                <div
                    className="badge badge-light-primary">{moment.utc(record?.date).locale(t("common:dateLocale")).fromNow()}</div>
            </div>
        }
        if (record.biologicalValidationStatus !== "NOT_STARTED") {
            return <div
                className="d-flex flex-center fs-7 fw-bolder ms-auto h-100">
                Voir plus... <OverlayTrigger rootClose={true} trigger={["hover", "hover"]} placement="right"
                                             overlay={<Popover>
                                                 <Popover.Body>
                                                     Résultat validé par le laboratoire
                                                 </Popover.Body>
                                             </Popover>}><i
                className="fas fa-check-circle fs-2 ms-2 text-warning"></i></OverlayTrigger>
                <div
                    className="badge badge-light-primary">{moment.utc(record?.date).locale(t("common:dateLocale")).fromNow()}</div>
            </div>
        }
        return <div
            className="d-flex flex-center fs-7 fw-bolder ms-auto h-100">
            Voir plus... <OverlayTrigger rootClose={true} trigger={["hover", "hover"]} placement="right"
                                         overlay={<Popover>
                                             <Popover.Body>
                                                 En attente de validation
                                             </Popover.Body>
                                         </Popover>}><i
            className={`fas fa-exclamation-circle fs-2 ms-2 text-warning`}></i></OverlayTrigger>
            <div
                className="badge badge-light-primary">{moment.utc(record?.date).locale(t("common:dateLocale")).fromNow()}</div>
        </div>
    }

    const getTitleModal = () => {
        return `Dossier ${record?.reference} du ${moment.utc(record?.date).local().format(t("common:dateFormat"))} ${record.patient ? `du patient ${record?.patient?.lastName} ${record?.patient?.firstName}` : ""}`
    }

    const refreshRecord = () => {
        wrapRequest(dispatch, RecordAPI.get(record?.id, record?.organizationId))
            .then(response => setRecord(response.data))
    }

    const validate = () => {
        Swal.fire({
            icon: 'warning',
            title: "Enregistrer les changements ?",
            text: `Vous êtes sur le point de modifier l'état de validation de cette analyse.`,
            showCancelButton: true,
            confirmButtonText: "Oui",
            cancelButtonText: `Non`
        }).then((result) => {
            if (result.isConfirmed) {
                wrapRequest(dispatch, RecordAPI.validateResult({
                    codes: validatedCodes
                }, record?.id))
                    .then(() => {
                        toast.success(`Validation appliquée avec succès`);
                        setValidationMode(false)
                        refreshRecord()
                    })
            }
        })
    }

    const read = () => {
        Swal.fire({
            icon: 'warning',
            title: "Ce résultat sera marqué comme lu par le prescripteur.",
            text: `Confirmez-vous l'action ?`,
            showCancelButton: true,
            confirmButtonText: "Oui",
            cancelButtonText: `Non`
        }).then((result) => {
            if (result.isConfirmed) {
                wrapRequest(dispatch, RecordAPI.read(record?.id))
                    .then(() => {
                        toast.success(`Résultat marqué comme lu`)
                        setOpen(false)
                        refreshRecord()
                    })
            }
        })

    }


    const popover = (<Popover>
        <Popover.Header as="h3">Informations complémentaires</Popover.Header>
        <Popover.Body>
            {`Date de naissance : ${record?.patient?.birthDate}`} <br/>
            {`Adresse : ${record?.patient?.address}`}<br/>
            {`Sexe  : ${record?.patient?.gender}`}
        </Popover.Body>
    </Popover>)

    useEffect(() => {
        setRecord(sourceRecord)
    }, [sourceRecord])

    const getView = () => {
        if (currentView === "results") {
            return <ResultTable validatedCodes={validatedCodes}
                                setValidatedCodes={setValidatedCodes}
                                validationMode={validationMode}
                                refreshRecord={refreshRecord}
                                record={record} validate={validate}
                                profile={profile}/>
        }
        if (currentView === "historical") {
            return <LogView record={record}/>
        }
        if (currentView === "support") {
            return <div className="mt-10">{!record.support ?
                <SupportView id={record?.id} Success={() => refreshRecord()}
                             profile={profile}/> :
                <SupportDetails support={record.support}/>}</div>
        }
        if (currentView === "additional_analyzes") {
            return <div className="mt-10"><AdditionalAnalysisForm
                recordId={record.id}
                additionalAnalysisId={record?.additionalAnalysisId}
                generated={record?.additionalAnalyzes}
                hasPatient={record?.patient !== undefined}
                setOpen={setOpen}/></div>
        }
        return <></>
    }


    return <>
        <Modal show={open} size="xl">
            <Modal.Header
                closeButton={!(profile?.roles?.includes("ROLE_DOCTOR") && record?.readStatus === "UNREAD")}
                onHide={() => {
                    setOpen(false)
                    refresh()
                }}
            >
                <Modal.Title>{getTitleModal()} <OverlayTrigger rootClose={true} trigger="click" placement="right"
                                                               overlay={popover}>
                    <i className="fa-solid fa-circle-info text-info fs-3"></i>
                </OverlayTrigger>
                    {validationMode ?
                        <span className="badge badge-primary mb-2 ms-3">Mode validation de résultats</span> : <></>}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <>
                    <ul className="nav" role="tablist">
                        <li className="nav-item" role="presentation">
                            <a onClick={e => setCurrentView("results")}
                               className={`nav-link btn btn-sm btn-color-muted btn-active-color-primary btn-active-light fw-semibold fs-7 px-4 me-1 ${currentView === "results" ? "active" : ""}`}>Résultats
                                d'analyses</a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a onClick={e => setCurrentView("historical")}
                               className={`nav-link btn btn-sm btn-color-muted btn-active-color-primary btn-active-light fw-semibold fs-7 px-4 me-1 ${currentView === "historical" ? "active" : ""}`}>Historique
                                des actions</a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a onClick={e => setCurrentView("support")}
                               className={`nav-link btn btn-sm btn-color-muted btn-active-color-primary btn-active-light fw-semibold fs-7 px-4 me-1  ${currentView === "support" ? "active" : ""}\`}`}>Prise
                                en charge</a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a onClick={e => setCurrentView("additional_analyzes")}
                               className={`nav-link btn btn-sm btn-color-muted btn-active-color-primary btn-active-light fw-semibold fs-7 px-4 me-1  ${currentView === " additional_analyzes" ? "active" : ""}\`}`}>
                                Analyses complémentaires
                            </a>
                        </li>


                    </ul>
                </>
                {getView()}
            </Modal.Body>
            {(currentView === "results" && (profile?.roles?.includes("ROLE_ADMIN")
                    || profile?.roles?.includes("ROLE_MANAGER"))) &&
                <Modal.Footer>
                    {!validationMode ? <><Button onClick={(e) => {
                        setValidationMode(true)
                    }}
                                                 wrapperClass=""
                                                 label={"Validation Biologique"}
                    />
                        <Button onClick={(e) => {
                            setOpen(false)
                        }}
                                wrapperClass=""
                                buttonClass="btn btn-primary"
                                label={"Terminer"}
                        />

                    </> : <><Button onClick={validate}
                                    wrapperClass=""
                                    buttonClass="btn btn-primary"
                                    label={"Valider"}
                    />
                        <Button onClick={(e) => {
                            setValidationMode(false)
                        }}
                                wrapperClass=""
                                buttonClass="btn btn-secondary"
                                label={"Annuler"}
                        /></>}
                </Modal.Footer>}
            {(currentView === "results" && profile?.roles?.includes("ROLE_DOCTOR") && record?.readStatus === "UNREAD") &&
                <Modal.Footer>
                    <><Button onClick={() => setOpen(false)}
                              wrapperClass=""
                              buttonClass="btn btn-secondary"
                              label={"Ignorer"}
                    />
                        <Button onClick={read}
                                wrapperClass=""
                                buttonClass="btn btn-primary"
                                label={"Marquer comme lu"}
                        />
                    </>
                </Modal.Footer>}
        </Modal>
        <td onClick={() => setOpen(true)}>
            <div className={`btn btn-outline btn-outline-dashed ${getStatus(record)} w-100 d-flex`}>
                <div>{getResultLineContent(record)}</div>
                {getStateIcon(record)}
            </div>
        </td>
    </>
}
