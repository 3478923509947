import React from "react"

export const Checkbox = ({
                             checked = false,
                             className = "form-check-input",
                             name,
                             id,
                             onChange = () => {
                             },
                             label,
                             wrapperClassName = "row mb-5",
                             rightOption = "Oui",
                             leftOption = "Non"
                         }) => {
    return <div className={wrapperClassName}>
        <div className="col-10 col-form-label  fs-6 fw-semibold">{label}</div>
        <div className="col-2 form-check form-check-custom form-switch align-items-center">
            <span className="fs-6 fw-semibold me-3">{leftOption}</span>
            <input
                name={name}
                className={className}
                type="checkbox"
                checked={checked}
                id={id}
                onChange={onChange}
            /><span className="fs-6 fw-semibold ms-3">{rightOption}</span>
        </div>
    </div>


}
