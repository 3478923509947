import Select from "react-select";
import React from "react";
import {useTranslation} from "react-i18next";
import {normalizeText} from "../../../helpers/textUtils";
import {formatMessage} from "../../../helpers/methods";

const CustomSelect = ({
                          value,
                          name,
                          onChange,
                          onInputChange = () => {
                          },
                          options,
                          isSearchable = false,
                          isLoading = false,
                          isMulti = false,
                          disabled = false,
                          customLabel,
                          label,
                          wrapperClass,
                          labelClassName,
                          defaultValue,
                          maxCharacters = 100,
                          isClear,
                          placeholder,
                          errors
                      }) => {
    const {t} = useTranslation();

    return (
        <div className={wrapperClass}>
            {customLabel !== undefined ? customLabel :
                <label className={labelClassName}>{label}</label>}
            <Select
                isDisabled={disabled}
                isClearable={isClear}
                defaultValue={defaultValue}
                isMulti={isMulti}
                value={value}
                onChange={onChange}
                options={options?.map((option) => {
                    return {
                        value: option.value,
                        label: normalizeText(option.label, maxCharacters, "none"),
                        id: option.id && option.id,
                    };
                })}
                onInputChange={onInputChange}
                isSearchable={isSearchable}
                isLoading={isLoading}
                placeholder={placeholder}
                noOptionsMessage={() => "No data found!"}
            />
            {(errors?.filter(e => e.fieldName === name).shift()?.message) &&
                <div className="fv-plugins-message-container invalid-feedback">
                    <div
                        data-validator="notEmpty">{t("errors:" + formatMessage(errors?.filter(e => e.fieldName === name).shift()?.message))}</div>
                </div>
            }
        </div>
    );
};
export default CustomSelect;
