import React, {useEffect, useRef, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {wrapRequest} from "../../helpers/auth";
import {toast} from "react-toastify";
import LaboratoryAPI from "../../api/LaboratoryAPI";
import {Input} from "../common/ui/Input";
import {Button} from "../common/ui/Button";
import AddressAPI from "../../api/AddressAPI";
import Autocomplete from "../common/ui/Autocomplete";
import Swal from "sweetalert2";
import {useNavigate} from "react-router-dom";

const LaboratoryEdit = ({selectedId, organizationId, profile, setOpen, onSuccess, setLaboratoryName}) => {
    const effectRan = useRef(false);
    const dispatch = useDispatch()
    const [laboratory, setLaboratory] = useState({})
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState([])
    const [searchValue, setSearchValue] = useState("")
    const [searchResult, setSearchResult] = useState({})
    const navigate = useNavigate()

    const onSend = (e) => {
        e.preventDefault()
        setLoading(true)
        return wrapRequest(dispatch, selectedId ?
            LaboratoryAPI.update(laboratory, selectedId) :
            LaboratoryAPI.create({
                ...laboratory,
                organizationId: profile.roles?.includes("ROLE_SUPER_ADMIN") ? organizationId : undefined
            }))
            .then(response => {
                setLoading(false)
                if (response?.status === 201) {
                    setOpen(false)
                    toast.success("Nouveau laboratoire créé");
                    onSuccess(0, 10)
                } else if (response?.status === 204) {
                    setOpen(false)
                    toast.success("Laboratoire mis à jour");
                    onSuccess(0, 10)
                } else if (response?.status === 400) {
                    setErrors(response.data.errors)
                } else {
                    setOpen(false)
                }
            })
    }

    const getLaboratoryToUpdate = async () => {
        return wrapRequest(dispatch, LaboratoryAPI.get(selectedId))
    }

    useEffect(() => {
        if (!effectRan.current) {
            if (selectedId) {
                getLaboratoryToUpdate().then(response => {
                    if (response.status === 200) {
                        setLaboratory({
                            ...laboratory,
                            id: response.data?.id,
                            organizationId: response.data?.organizationId,
                            name: response.data?.name,
                            identifier: response.data?.identifier,
                            phoneNumber: response.data?.phoneNumber,
                            email: response.data?.email,
                            firstLine: response.data?.address?.firstLine,
                            secondLine: response.data?.address?.secondLine,
                            city: response.data?.address?.city,
                            zipCode: response.data?.address?.zipCode
                        })
                    }
                    setLaboratoryName(response.data?.name)
                    setSearchResult(response.data?.firstLine)
                })
            }
        }
        return () => effectRan.current = true;
    }, []);

    const onSearch = (event) => {
        event.preventDefault();
        setSearchValue(event.target.value)
        if (event.target.value.length > 3) {
            document.getElementById("addressSearch").style["display"] = "block";
            return AddressAPI.fetchAddressFromSearchAPI(event.target.value.replace(new RegExp(" ", 'g'), "+"))
                .then(response => {
                    setSearchResult(response.data)
                });
        }
    }

    const onSelectItem = (event) => {
        event.preventDefault();
        const addressFromSearch = searchResult?.features.filter(feature => feature.properties.id === event.target.id)[0]
        document.getElementById("addressSearch").style["display"] = "none";
        setSearchValue(addressFromSearch.properties.name)
        setLaboratory({
            ...laboratory,
            firstLine: addressFromSearch.properties.name,
            city: addressFromSearch.properties.city,
            zipCode: addressFromSearch.properties.postcode
        })
    }

    const onDelete = (e) => {
        e.preventDefault()
        Swal.fire({
            icon: 'warning',
            title: "Voulez-vous archiver cet site ?",
            text: "Attention, les résultats reçus ne seront plus attachés à ce site.",
            showCancelButton: true,
            confirmButtonText: "Oui",
            cancelButtonText: `Non`
        }).then((result) => {
            if (result.isConfirmed) {
                setLoading(true)
                return wrapRequest(dispatch, LaboratoryAPI.delete(laboratory.id))
                    .then(response => {
                        setLoading(false)
                        if (response.status === 204) {
                            toast.success("Site archivé")
                            navigate(`/organizations/${laboratory.organizationId}?tab=labs`)
                        }
                    })
            }
        });
    }


    return <>
        <div className="row">
            <Input
                wrapperClass="mb-7 fv-plugins-icon-container col-lg-6"
                label="Nom"
                labelClassName="required fs-6 fw-semibold mb-2"
                className="form-control form-control-solid"
                name="name"
                value={laboratory.name || ""}
                onChange={(e) => setLaboratory({...laboratory, name: e.target.value})}
                errors={errors}
            />
            <Input
                wrapperClass="col-lg-6 mb-7 fv-plugins-icon-container"
                label="Identifiant"
                labelClassName="required fs-6 fw-semibold mb-2"
                className="form-control form-control-solid"
                name="identifier"
                value={laboratory.identifier || ""}
                onChange={(e) => setLaboratory({...laboratory, identifier: e.target.value})}
                errors={errors}
            />
            <Input
                wrapperClass="col-lg-6 mb-7 fv-plugins-icon-container"
                label="Email"
                labelClassName="required fs-6 fw-semibold mb-2"
                className="form-control form-control-solid"
                name="email"
                value={laboratory.email || ""}
                onChange={(e) => setLaboratory({...laboratory, email: e.target.value})}
                errors={errors}
            />

            <Input
                wrapperClass="col-lg-6 mb-7 fv-plugins-icon-container"
                label="Numéro de téléphone"
                labelClassName="fs-6 fw-semibold mb-2"
                className="form-control form-control-solid"
                name="phoneNumber"
                value={laboratory.phoneNumber || ""}
                onChange={(e) => setLaboratory({...laboratory, phoneNumber: e.target.value})}
                errors={errors}
            />
        </div>
        <div className="row">
            <Autocomplete id="addressSearch"
                          value={searchValue || ""}
                          results={searchResult?.features}
                          onSelectItem={onSelectItem}
                          label="Rechercher une adresse"
                          name="searchValue"
                          onChange={onSearch}
                          errors={errors}
                          customLabel={"Adresse"}
                          wrapperClass="col-lg-6 mb-7 fv-plugins-icon-container"
            />

            <Input
                wrapperClass="col-lg-6 mb-7 fv-plugins-icon-container"
                label="Adresse"
                labelClassName="fs-6 fw-semibold mb-2"
                className="form-control form-control-solid"
                name="firstLine"
                value={laboratory?.firstLine || ""}
                onChange={(e) => setLaboratory({...laboratory, firstLine: e.target.value})}
                errors={errors}
            />
            <Input
                wrapperClass="col-lg-6 mb-7 fv-plugins-icon-container"
                label="Complément d'adresse"
                labelClassName="fs-6 fw-semibold mb-2"
                className="form-control form-control-solid"
                name="secondLine"
                value={laboratory?.secondLine || ""}
                onChange={(e) => setLaboratory({...laboratory, secondLine: e.target.value})}
                errors={errors}
            />
            <Input
                wrapperClass="col-lg-6 mb-7 fv-plugins-icon-container"
                label="Code postal"
                labelClassName="fs-6 fw-semibold mb-2"
                className="form-control form-control-solid"
                name="zipCode"
                value={laboratory?.zipCode || ""}
                onChange={(e) => setLaboratory({...laboratory, zipCode: e.target.value})}
                errors={errors}
            />
            <Input
                wrapperClass="col-lg-6 mb-7 fv-plugins-icon-container"
                label="Ville"
                labelClassName="fs-6 fw-semibold mb-2"
                className="form-control form-control-solid"
                name="city"
                value={laboratory?.city || ""}
                onChange={(e) => setLaboratory({...laboratory, city: e.target.value})}
                errors={errors}
            />
        </div>
        <div className="d-flex flex-end">
            {selectedId !== undefined && <><Button onClick={onDelete} loading={loading}
                                                   wrapperClass="me-5"
                                                   buttonClass="btn btn-danger"
                                                   label={"Supprimer"}
            /></>}
            <Button onClick={onSend} loading={loading}
                    wrapperClass=""
                    label={"Enregistrer"}
            />
        </div>
    </>

}

const mapStateToProps = state => ({
    profile: state.profile
});

export default connect(mapStateToProps, null)(LaboratoryEdit);
