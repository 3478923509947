import React, {useEffect, useRef, useState} from 'react';
import {Helmet, HelmetProvider} from "react-helmet-async";
import SideMenu from "./menus/SideMenu";
import Header from "./Header";
import {Footer} from "./Footer";
import MobileSideMenu from "./menus/MobileSideMenu";

export const PrivateRoute = ({
                                 activeLink = "dashboard",
                                 component: Component,
                                 ...props
                             }) => {

    const [open, setOpen] = useState(false);
    const useOutsideAlerter = (ref) => {
        useEffect(() => {
            const handleClickOutside = (event) => {
                if (ref.current && !ref.current.contains(event.target)) {
                    setOpen(false);
                }
            };
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    };
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    const helmetContext = {};
    return <HelmetProvider context={helmetContext}>
        <Helmet>
            <body
                className="aside-enabled">
            </body>
        </Helmet>
        <div className="page d-flex flex-row flex-column-fluid">
            <SideMenu activeLink={activeLink}/>
            <MobileSideMenu open={open} wrapperRef={wrapperRef} setOpen={setOpen} activeLink={activeLink}/>
            <div className="wrapper d-flex flex-column flex-row-fluid">
                <Header setOpen={setOpen}/>
                <div className="d-flex flex-column flex-column-fluid">
                    <div className="container-xxl"><Component {...props} /></div>
                </div>
                <Footer/>
            </div>
        </div>
    </HelmetProvider>
}
