import React, {useEffect, useRef, useState} from "react"
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {wrapRequest} from "../../helpers/auth";
import DoctorAPI from "../../api/DoctorAPI";
import {Modal} from "react-bootstrap";
import NotificationAlert from "../common/ui/NotificationAlert";
import Pagination from "@vlsergey/react-bootstrap-pagination";
import DoctorEdit from "./DoctorEdit";
import {LoadableComponent} from "../common/ui/LoadableComponent";
import {normalizeText} from "../../helpers/textUtils";

export const Doctors = ({}) => {

    const effectRan = useRef(false);
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)
    const [doctors, setDoctors] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [totalResults, setTotalResults] = useState(0);
    const {t} = useTranslation()
    const [loading, setLoading] = useState(false)
    const [selectedId, setSelectedId] = useState()
    const navigate = useNavigate()

    const onPageChange = ({target}) => {
        const page = target.value;
        navigate({
            pathname: "/doctors",
            search: `?page=${page}`,
        });
        getDoctors(page, 10);
    }

    const getDoctors = (page, size) => {
        setLoading(true)
        return wrapRequest(dispatch, DoctorAPI.getDoctors(page, size))
            .then(response => {
                setLoading(false)
                if (response.status === 200) {
                    setDoctors(response.data.content)
                    setTotalPages(response.data.totalPages)
                    setCurrentPage(response.data.number)
                    setTotalResults(response.data.totalElements)
                }
            })
    }

    useEffect(() => {
        if (!effectRan.current) {
            getDoctors(0, 10)
        }
        return () => effectRan.current = true;
    }, []);
    return <div className="card">
        <Modal show={open} size="lg">
            <Modal.Header
                closeButton={true}
                onHide={() => {
                    setSelectedId(undefined)
                    setOpen(false)
                }}
            >
                <Modal.Title>{selectedId ? "Mettre à jour un prescripteur" : "Ajouter un nouveau prescripteur"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <DoctorEdit setOpen={setOpen}
                            onSuccess={getDoctors}
                            selectedId={selectedId}
                />
            </Modal.Body>
        </Modal>

        <div className="card-header border-0 pt-6">
            <div className="card-title">Prescripteurs ({totalResults} trouvé{totalResults > 1 ? "s" : ""})</div>
            <div className="card-toolbar">
                <div className="d-flex justify-content-end" data-kt-customer-table-toolbar="base">
                    <button type="button" className="btn btn-primary"
                            onClick={(e) => {
                                e.preventDefault()
                                setSelectedId(undefined)
                                setOpen(true)
                            }}>Ajouter un nouveau prescripteur
                    </button>
                </div>
            </div>
        </div>

        <div className="card-body pt-0">
            <div id="kt_customers_table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer mt-8">
                {<LoadableComponent loading={loading} component={<>{doctors?.length === 0 ? <NotificationAlert
                        title={"Aucun prescripteur trouvé"}
                        message={"Créer un nouveau prescripteur"}
                    /> :
                    <div className="table-responsive">
                        <table className="table table-hover align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
                            <thead className="border-gray-200 fs-5 fw-semibold bg-lighten">
                            <tr>
                                <th className="min-w-125px sorting">Nom</th>
                                <th className="min-w-125px sorting">Prénom</th>
                                <th className="min-w-125px sorting">Spécialité</th>
                                <th className="min-w-125px sorting">Téléphone fixe</th>
                                <th className="min-w-125px sorting">Téléphone mobile</th>
                                <th className="min-w-125px sorting">E-mail</th>
                                <th className="min-w-125px sorting">Ville</th>
                            </tr>
                            </thead>
                            <tbody className="fw-semibold text-gray-600">
                            {doctors?.map((doctor, idx) => <tr key={idx}
                                                               onClick={()=> navigate(`/doctors/${doctor.id}`)}
                                                               className={"cursor-pointer " + (idx % 2 === 0 ? "even" : "odd")}>
                                    <td>{normalizeText(doctor?.lastName, 20, "capitalize")}</td>
                                    <td>{normalizeText(doctor?.firstName, 20, "capitalize")}</td>
                                    <td>{normalizeText(doctor?.speciality, 20, "capitalize") || "Non renseigné"}</td>
                                    <td>{normalizeText(doctor?.phoneNumber, 20, "capitalize") || "Non renseigné"}</td>
                                    <td>{doctor?.mobilePhoneNumber || "Non renseigné"}</td>
                                    <td>{doctor?.email}</td>
                                    <td>{normalizeText(doctor?.address?.city, 20, "capitalize") || "Non renseigné"}</td>
                                </tr>
                            )}
                            </tbody>
                        </table>

                    </div>}</>}/>}
            </div>
            {doctors?.length > 0 && <div className="mt-3">
                <Pagination
                    aroundCurrent={2}
                    showFirstLast={true}
                    showPrevNext={true}
                    atBeginEnd={0}
                    onChange={onPageChange}
                    value={currentPage}
                    totalPages={totalPages}
                />
            </div>}
        </div>

    </div>
}
