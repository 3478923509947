import axios from "axios";
import {requestHeaders} from "../helpers/constants";

class AlertConfigurationAPI {
    static create(alert) {
        return axios({
            method: 'post',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/alert-configurations`,
            data: alert
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }

    static update(alert, id) {
        return axios({
            method: 'put',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/alert-configurations/${id}`,
            data: alert
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }

    static get(id) {
        return axios({
            method: 'get',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/alert-configurations/${id}`
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }

    static getAlertConfigurations(page, size, organizationId) {
        let url = `${process.env.API_BASE_URL}/alert-configurations?page=${page}&size=${size}`
        if (organizationId) {
            url += `&organizationId=${organizationId}`
        }
        return axios({
            method: 'get',
            headers: requestHeaders(),
            url: url,
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }
}

export default AlertConfigurationAPI
