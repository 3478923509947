import AsyncSelect from 'react-select/async';
import React from "react";
import {useTranslation} from "react-i18next";
import {normalizeText} from "../../../helpers/textUtils";
import {formatMessage} from "../../../helpers/methods";

const CustomAsyncSelect = ({
                               value,
                               name,
                               onChange,
                               options,
                               isSearchable = false,
                               isLoading = false,
                               isMulti = false,
                               customLabel,
                               label,
                               wrapperClass,
                               labelClassName,
                               defaultValue,
                               maxCharacters = 100,
                               isClear,
                               placeholder,
                               errors,
                               loadOptions,
                               handleInputChange

                           }) => {
    const {t} = useTranslation();

    return (
        <div className={wrapperClass}>
            {customLabel !== undefined ? customLabel :
                <label className={labelClassName}>{label}</label>}
            <AsyncSelect
                isClearable={isClear}
                defaultValue={defaultValue}
                isMulti={isMulti}
                value={value}
                onChange={onChange}
                isSearchable={isSearchable}
                cacheOptions
                loadOptions={loadOptions}
                defaultOptions
                onInputChange={handleInputChange}
                isLoading={isLoading}
                placeholder={placeholder}
                noOptionsMessage={() => "No data found!"}
            />
            {(errors?.filter(e => e.fieldName === name).shift()?.message) &&
                <div className="fv-plugins-message-container invalid-feedback">
                    <div
                        data-validator="notEmpty">{t("errors:" + formatMessage(errors?.filter(e => e.fieldName === name).shift()?.message))}</div>
                </div>
            }
        </div>
    );
};
export default CustomAsyncSelect;
