import React, {useEffect, useRef, useState} from "react"
import {useDispatch} from "react-redux";
import {wrapRequest} from "../../helpers/auth";
import {toast} from "react-toastify";
import OrganizationAPI from "../../api/OrganizationAPI";
import {Input} from "../common/ui/Input";
import {Button} from "../common/ui/Button";
import Autocomplete from "../common/ui/Autocomplete";
import AddressAPI from "../../api/AddressAPI";

export const OrganizationEdit = ({selectedId, setOpen, onSuccess}) => {
    const effectRan = useRef(false);
    const dispatch = useDispatch()
    const [organization, setOrganization] = useState({})
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState([])
    const [searchValue, setSearchValue] = useState("")
    const [searchResult, setSearchResult] = useState({})

    const onSend = (e) => {
        e.preventDefault()
        return wrapRequest(dispatch, selectedId ?
            OrganizationAPI.update(organization, selectedId) :
            OrganizationAPI.create(organization))
            .then(response => {
                if (response?.status === 201) {
                    setOpen(false)
                    toast.success("Nouveau client créé");
                    onSuccess(0, 10)
                } else if (response?.status === 204) {
                    setOpen(false)
                    toast.success("Client mise à jour");
                    onSuccess(0, 10)
                } else if (response?.status === 400) {
                    setErrors(response.data.errors)
                } else {
                    setOpen(false)
                }
            })
    }

    const getOrganizationToUpdate = async () => {
        return wrapRequest(dispatch, OrganizationAPI.get(selectedId))
    }

    useEffect(() => {
        if (!effectRan.current) {
            if (selectedId) {
                getOrganizationToUpdate().then(response => {
                    if (response.status === 200) {
                        setOrganization({
                            name: response.data?.name,
                            phoneNumber: response.data?.phoneNumber,
                            email: response.data?.email,
                            firstLine: response.data?.address?.firstLine,
                            secondLine: response.data?.address?.secondLine,
                            city: response.data?.address?.city,
                            zipCode: response.data?.address?.zipCode
                        })
                        setSearchValue(response.data?.firstLine)
                    }
                })
            }
        }
        return () => effectRan.current = true;
    }, []);


    const onSearch = (event) => {
        event.preventDefault();
        setSearchValue(event.target.value)
        if (event.target.value.length > 3) {
            document.getElementById("addressSearch").style["display"] = "block";
            return AddressAPI.fetchAddressFromSearchAPI(event.target.value.replace(new RegExp(" ", 'g'), "+"))
                .then(response => {
                    setSearchResult(response.data)
                });
        }
    }

    const onSelectItem = (event) => {
        event.preventDefault();
        const addressFromSearch = searchResult?.features.filter(feature => feature.properties.id === event.target.id)[0]
        document.getElementById("addressSearch").style["display"] = "none";
        setSearchValue( addressFromSearch.properties.name)
        setOrganization({
            ...organization,
            firstLine: addressFromSearch.properties.name,
            city: addressFromSearch.properties.city,
            zipCode: addressFromSearch.properties.postcode
        })
    }

    return <>
        <div className="row">
            <Input
                wrapperClass="mb-7 fv-plugins-icon-container col-lg-6"
                label="Nom"
                labelClassName="required fs-6 fw-semibold mb-2"
                className="form-control form-control-solid"
                name="name"
                value={organization.name || ""}
                onChange={(e) => setOrganization({...organization, name: e.target.value})}
                errors={errors}
            />
            <Input
                wrapperClass="mb-7 fv-plugins-icon-container col-lg-6"
                label="Numéro de téléphone"
                labelClassName="fs-6 fw-semibold mb-2"
                className="form-control form-control-solid"
                name="phoneNumber"
                value={organization.phoneNumber || ""}
                onChange={(e) => setOrganization({...organization, phoneNumber: e.target.value})}
                errors={errors}
            />
            <Input
                wrapperClass="mb-7 fv-plugins-icon-container col-lg-6"
                label="Email"
                labelClassName="fs-6 fw-semibold mb-2"
                className="form-control form-control-solid"
                name="email"
                value={organization.email || ""}
                onChange={(e) => setOrganization({...organization, email: e.target.value})}
                errors={errors}
            />
        </div>
        <div className="row">
            <Autocomplete id="addressSearch"
                          value={searchValue || ""}
                          results={searchResult?.features}
                          onSelectItem={onSelectItem}
                          label="Rechercher une adresse"
                          name="searchValue"
                          onChange={onSearch}
                          errors={errors}
                          customLabel={"Adresse"}
                          wrapperClass="col-lg-6 mb-7 fv-plugins-icon-container"
            />
            <Input
                wrapperClass="mb-7 fv-plugins-icon-container col-lg-6"
                label="Complément d'adresse"
                labelClassName="fs-6 fw-semibold mb-2"
                className="form-control form-control-solid"
                name="secondLine"
                value={organization?.secondLine || ""}
                onChange={(e) => setOrganization({...organization, secondLine: e.target.value})}
                errors={errors}
            />
            <Input
                wrapperClass="mb-7 fv-plugins-icon-container col-lg-6"
                label="Code postal"
                labelClassName="fs-6 fw-semibold mb-2"
                className="form-control form-control-solid"
                name="zipCode"
                value={organization?.zipCode || ""}
                onChange={(e) => setOrganization({...organization, zipCode: e.target.value})}
                errors={errors}
            />
            <Input
                wrapperClass="mb-7 fv-plugins-icon-container col-lg-6"
                label="Ville"
                labelClassName="fs-6 fw-semibold mb-2"
                className="form-control form-control-solid"
                name="city"
                value={organization?.city || ""}
                onChange={(e) => setOrganization({...organization, city: e.target.value})}
                errors={errors}
            /></div>
        <div className="d-flex flex-end">
            <Button onClick={onSend} loading={loading}
                    wrapperClass=""
                    label={"Enregistrer"}
            />
        </div>
    </>
}
