export function requestHeaders() {
    return {
        Authorization: `Bearer ${localStorage.accessToken}`,
        "Content-Type": "application/json",
        "Accept-Language": "en",
    };
}
export function requestHeadersForUpload() {
    return {
        Authorization: `Bearer ${localStorage.accessToken}`,
        "Content-Type": "multipart/form-data",
    };
}
export const ACCESS_TOKEN = "accessToken";
export const REFRESH_TOKEN = "refreshToken";
