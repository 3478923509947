import React, {useEffect, useRef, useState} from "react";
import FilterCard from "./FilterCard";
import {useDispatch} from "react-redux";
import {wrapRequest} from "../../helpers/auth";
import RecordAPI from "../../api/RecordAPI";

const ResultNavbar = ({
                          currentTab,
                          selectTab,
                          filterOpen,
                          setFilterOpen,
                          setPatientOrDoctorSearch,
                          getRecords,
                          profile,
                          setImportModalOpen,
                          totalResults,
                      }) => {

    const [recordCounts, setRecordCounts] = useState([])
    const dispatch = useDispatch()
    const effectRan = useRef(false);


    const getFiltersMessage = () => {
        if(currentTab !== "all"){
            return <></>
        }
        if (profile?.roles?.includes("ROLE_SUPER_ADMIN")) {
            return <button type="button" className="btn btn-icon btn-circle btn-primary"
                           onClick={(e) => {
                               e.preventDefault()
                               setFilterOpen(!filterOpen)
                           }}>{filterOpen ? <i className="fa-solid fa-filter-circle-xmark"></i> :
                <i className="fa-solid fa-filter"></i>}
            </button>
        }
        return <button type="button" className="btn btn-primary"
                       onClick={(e) => {
                           e.preventDefault()
                           setFilterOpen(!filterOpen)
                       }}>{filterOpen ? "Masquer les filtres" :
            "Voir les filtres"}
        </button>
    }

    useEffect(() => {
        if (!effectRan.current) {
            wrapRequest(dispatch, RecordAPI.getRecordCounts())
                .then(response => {
                    if (response.status === 200) {
                        setRecordCounts(response.data)
                    }
                })
        }
        return () => effectRan.current = true;
    }, [currentTab])


    return <div className="card mb-2">
        <div className="card-header pt-0 pb-0">
            <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
                <li className="nav-item mt-2 cursor-pointer">
                    <a className={`nav-link text-active-primary ms-0 me-10 py-5 ${currentTab === "all" ? "active" : ""}`}
                       onClick={e => selectTab("all")}>Tous les résultats
                            <span className="ms-2 badge badge-light-danger">{currentTab === "all"
                                ? totalResults
                                : recordCounts?.filter(data => data.type === "ALL")[0]?.count}</span>
                    </a>

                </li>
                <li className="nav-item mt-2 cursor-pointer">
                    <a className={`nav-link text-active-primary ms-0 me-10 py-5 ${currentTab === "to_validate" ? "active" : ""}`}
                       onClick={e => selectTab("to_validate")}
                    >{profile?.roles?.includes("ROLE_DOCTOR") ? "Résultats en attente" :  "Résultats à valider"}
                            <span className="ms-2 badge badge-light-danger">{recordCounts?.filter(data => data.type === "TO_VALIDATE")[0]?.count}</span></a>

                </li>
                <li className="nav-item mt-2 cursor-pointer">
                    <a className={`nav-link text-active-primary ms-0 me-10 py-5 ${currentTab === "unread" ? "active" : ""}`}
                       onClick={e => selectTab("unread")}
                    >Résultats non lus
                            <span className="ms-2 badge badge-light-danger">{recordCounts?.filter(data => data.type === "UNREAD")[0]?.count}</span></a>
                </li>
            </ul>
            <div className="card-toolbar">
                <div className="d-flex justify-content-end">
                    <div className="d-flex align-items-center position-relative me-4 ">
                        <span className="svg-icon svg-icon-1 position-absolute ms-4 input-group-lg">
												<svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
													<rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546"
                                                          height="2" rx="1" transform="rotate(45 17.0365 15.1223)"
                                                          fill="currentColor"></rect>
													<path
                                                        d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                                        fill="currentColor"></path>
												</svg>
											</span>
                        <input id={"patientOrDoctorSearch"} onChange={(e) => {
                            e.preventDefault()
                            setPatientOrDoctorSearch(e.target.value)
                            getRecords(0, 10,)
                        }} type="text" className="form-control form-control-solid w-md-325px ps-14"
                               placeholder="Rechercher"/>
                    </div>
                    {getFiltersMessage()}
                    {profile?.roles?.includes("ROLE_SUPER_ADMIN") &&
                        < div className="ms-4">
                            <button type="button" className="btn btn-icon btn-circle btn-primary"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setImportModalOpen(true)
                                    }}><i className="fa-solid fa-upload"></i>
                            </button>
                        </div>}
                </div>
            </div>
        </div>
        {(filterOpen && currentTab === "all") && <div className="card-body pt-0">
            <div className="mt-2">
                <FilterCard profile={profile}
                            currentTab={currentTab}
                            setOpen={() => {
                            }}
                            onClose={getRecords}/>
            </div>
        </div>}

    </div>
}
export default ResultNavbar;
