import React, {useEffect} from "react"
import {useNavigate} from "react-router-dom";

const RedirectLogin = ({}) => {

    const navigate = useNavigate()

    useEffect(()=> {
        navigate({pathname: "/auth/login"})
    }, [])

    return <div/>
}

export default RedirectLogin;
