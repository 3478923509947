import React, {useEffect, useRef, useState} from "react"
import {useDispatch} from "react-redux";
import {wrapRequest} from "../../../helpers/auth";
import {toast} from "react-toastify";
import {Input} from "../../common/ui/Input";
import {Button} from "../../common/ui/Button";
import RecommendationAPI from "../../../api/RecommendationAPI";
import {formatMessage} from "../../../helpers/methods";
import {useTranslation} from "react-i18next";
import RecommendationTypeSelect from "./RecommendationTypeSelect";

const RecommendationModal = ({selectedId, alertConfigurationId, setOpen, onSuccess}) => {
    const effectRan = useRef(false);
    const dispatch = useDispatch()
    const [recommendation, setRecommendation] = useState({})
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState([])
    const {t} = useTranslation()

    const [activeItem, setActiveItem] = useState("etiology")

    const types = [
        {value: "ETIOLOGY", label: "Etiologie"},
        {value: "SEMIOLOGY", label: "Sémiologie"},
        {value: "MEDICAL_CARE", label: "Prise en charge"},
        {value: "EXPLORATION", label: "Exploration"}]
    const getRecommendationToUpdate = async () => {
        return wrapRequest(dispatch, RecommendationAPI.get(alertConfigurationId, selectedId))
    }
    const onSend = (e) => {
        e.preventDefault()
        setLoading(true)
        return wrapRequest(dispatch, selectedId ?
            RecommendationAPI.update(alertConfigurationId, selectedId, recommendation) :
            RecommendationAPI.create(
                alertConfigurationId,
                recommendation
            ))
            .then(response => {
                setLoading(false)
                if (response?.status === 201) {
                    setOpen(false)
                    toast.success("Nouvelle recommendation créé");
                    onSuccess(0, 10)
                } else if (response?.status === 204) {
                    setOpen(false)
                    toast.success("Recommendation  mis à jour");
                    onSuccess(0, 10)
                } else if (response?.status === 400) {
                    setErrors(response.data.errors)
                } else {
                    setOpen(false)
                }
            })
    }
    useEffect(() => {
        if (!effectRan.current) {
            if (selectedId) {
                getRecommendationToUpdate().then(response => {
                    if (response.status === 200) {
                        setRecommendation({
                            ...recommendation,
                            title: response.data?.title,
                            medicalCareText: response.data?.medicalCareText,
                            explorationText: response.data?.explorationText,
                            etiologyText: response.data?.etiologyText,
                            semiologyText: response.data?.semiologyText
                        })
                    }
                })
            }
        }
        return () => effectRan.current = true;
    }, []);
    return <><Input
        wrapperClass="fv-row mb-7 fv-plugins-icon-container"
        label="Titre"
        labelClassName="required fs-6 fw-semibold mb-2"
        className="form-control form-control-solid"
        name="title"
        value={recommendation.title || ""}
        onChange={(e) => setRecommendation({...recommendation, title: e.target.value})}
        errors={errors}
    />

        <RecommendationTypeSelect activeItem={activeItem} setActiveItem={setActiveItem} />

        {activeItem === "etiology" && <div className="mb-10 fv-row fv-plugins-icon-container">
                        <textarea id="content" name="content" onChange={(e) => setRecommendation({...recommendation, etiologyText: e.target.value})}
                                  placeholder={"Etiologie"}
                                  value={recommendation.etiologyText}
                                  autoComplete="new-password" style={{resize: "none", minHeight: 250}}
                                  className="form-control form-control-lg form-control-solid"
                        />
            {(errors?.filter(e => e.fieldName === "etiologyText").shift()?.message) &&
                <div
                    className="fv-plugins-message-container invalid-feedback">{t("errors:" + formatMessage(errors?.filter(e => e.fieldName === "etiologyText").shift()?.message))}</div>
            }
        </div>}
        {activeItem === "semiology" && <div className="mb-10 fv-row fv-plugins-icon-container">
                        <textarea id="content" name="content" onChange={(e) => setRecommendation({...recommendation, semiologyText: e.target.value})}
                                  placeholder={"Sémiologie"}
                                  value={recommendation.semiologyText}
                                  autoComplete="new-password" style={{resize: "none", minHeight: 250}}
                                  className="form-control form-control-lg form-control-solid"
                        />
            {(errors?.filter(e => e.fieldName === "semiologyText").shift()?.message) &&
                <div
                    className="fv-plugins-message-container invalid-feedback">{t("errors:" + formatMessage(errors?.filter(e => e.fieldName === "semiologyText").shift()?.message))}</div>
            }
        </div>}
        {activeItem === "medicalCare" && <div className="mb-10 fv-row fv-plugins-icon-container">
                        <textarea id="content" name="content" onChange={(e) => setRecommendation({...recommendation, medicalCareText: e.target.value})}
                                  placeholder={"Prise en charge"}
                                  value={recommendation.medicalCareText}
                                  autoComplete="new-password" style={{resize: "none", minHeight: 250}}
                                  className="form-control form-control-lg form-control-solid"
                        />
            {(errors?.filter(e => e.fieldName === "medicalCareText").shift()?.message) &&
                <div
                    className="fv-plugins-message-container invalid-feedback">{t("errors:" + formatMessage(errors?.filter(e => e.fieldName === "medicalCareText").shift()?.message))}</div>
            }
        </div>}
        {activeItem === "exploration" && <div className="mb-10 fv-row fv-plugins-icon-container">
                        <textarea id="content" name="content" onChange={(e) => setRecommendation({...recommendation, explorationText: e.target.value})}
                                  placeholder={"Exploration"}
                                  value={recommendation.explorationText}
                                  autoComplete="new-password" style={{resize: "none", minHeight: 250}}
                                  className="form-control form-control-lg form-control-solid"
                        />
            {(errors?.filter(e => e.fieldName === "explorationText").shift()?.message) &&
                <div
                    className="fv-plugins-message-container invalid-feedback">{t("errors:" + formatMessage(errors?.filter(e => e.fieldName === "explorationText").shift()?.message))}</div>
            }
        </div>}
        <div className="d-flex flex-end">
            <Button onClick={onSend} loading={loading}
                    wrapperClass=""
                    label={"Enregistrer"}
            />
        </div>
    </>
}

export default RecommendationModal
