import React from 'react';

const NotificationAlert = ({level = "info", title, message, icon}) => {

    const getIcon = () => {
        if (icon) {
            return icon;
        }
        switch (level) {
            case 'info':
                return <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"/>
                    <rect x="11" y="17" width="7" height="2" rx="1" transform="rotate(-90 11 17)" fill="currentColor"/>
                    <rect x="11" y="9" width="2" height="2" rx="1" transform="rotate(-90 11 9)" fill="currentColor"/>
                </svg>
            case 'warning':
                return <svg width="24" height="24"
                            viewBox="0 0 24 24" fill="none">
                    <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10"
                          fill="currentColor"/>
                    <rect x="11" y="14" width="7" height="2" rx="1"
                          transform="rotate(-90 11 14)" fill="currentColor"/>
                    <rect x="11" y="17" width="2" height="2" rx="1"
                          transform="rotate(-90 11 17)" fill="currentColor"/>
                </svg>
            case 'danger':
                return <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"/>
                    <rect x="7" y="15.3137" width="12" height="2" rx="1" transform="rotate(-45 7 15.3137)"
                          fill="currentColor"/>
                    <rect x="8.41422" y="7" width="12" height="2" rx="1" transform="rotate(45 8.41422 7)" fill="currentColor"/>
                </svg>
             case 'unlock':
                return <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <mask fill="white">
                        <use href="#path-1"/>
                    </mask>
                    <g/>
                    <path d="M7,10 L7,8 C7,5.23857625 9.23857625,3 12,3 C14.7614237,3 17,5.23857625 17,8 L17,10 L18,10 C19.1045695,10 20,10.8954305 20,12 L20,18 C20,19.1045695 19.1045695,20 18,20 L6,20 C4.8954305,20 4,19.1045695 4,18 L4,12 C4,10.8954305 4.8954305,10 6,10 L7,10 Z M12,5 C10.3431458,5 9,6.34314575 9,8 L9,10 L15,10 L15,8 C15,6.34314575 13.6568542,5 12,5 Z" fill="#000000"/>
                </g>
            </svg>
        }
    }


    return <div className={`notice d-flex bg-light-${level} rounded border-${level} border border-dashed p-6`}>
        <span className={`svg-icon svg-icon-2tx svg-icon-${level} me-4`}>
											{getIcon()}
        </span>
        <div className="d-flex flex-stack flex-grow-1">
            <div className="fw-bold">
                <h4 className="text-gray-900 fw-bolder">{title}</h4>
                <div className="fs-6 text-gray-700">{message}
                </div>
            </div>
        </div>
    </div>
}

export default NotificationAlert;
