import {Link} from "react-router-dom";
import React from "react";
import {useTranslation} from "react-i18next";

export const MobileMenu = ({open, wrapperRef, activeLink}) => {
    const {t} = useTranslation("menu")
    return <div id="kt_aside" className={`aside pb-5 pt-5 pt-lg-0 drawer drawer-start ${open ? "drawer-on" : ""}`}
                style={{width: "100px !important"}} ref={wrapperRef}>
        <div className="className=u flex-column-fluid" id="kt_aside_menu">
            <div className="hover-scroll-overlay-y my-2 my-lg-5 pe-lg-n1" id="kt_aside_menu_wrapper"
                 data-kt-scroll="true" data-kt-scroll-height="auto"
                 data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
                 data-kt-scroll-wrappers="#kt_aside, #kt_aside_menu" data-kt-scroll-offset="5px" style={{
                height: "816px"
            }}>
                <div
                    className="menu menu-column menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500 fw-semibold"
                >
                    <div
                        data-kt-menu-placement="right-start"
                        className={`menu-item py-2`}>
                        <Link to={"/"}
                              className={`menu-link menu-center ${activeLink === "records" ? "bg-white" : ""}`}>
										<span className="menu-icon me-0 ">
											<i className={`fa-solid fa-file-waveform fs-1 ${activeLink === "records" ? "text-black" : "text-white"}`}></i>
										</span>
                        </Link>
                    </div>
                    <div
                        data-kt-menu-placement="right-start"
                        className={`menu-item py-2`}>
                        <Link to={"/alerts"}
                              className={`menu-link menu-center ${activeLink === "alerts" ? "bg-white" : ""}`}>
										<span className="menu-icon me-0 ">
											<i className={`fa-solid fa-bell fs-1 ${activeLink === "alerts" ? "text-black" : "text-white"}`}></i>
										</span>
                        </Link>
                    </div>
                    <div
                        data-kt-menu-placement="right-start"
                        className={`menu-item py-2`}>
                        <Link to={"/patients"}
                              className={`menu-link menu-center ${activeLink === "patients" ? "bg-white" : ""}`}>
										<span className="menu-icon me-0 ">
											<i className={`fa-solid fa-hospital-user fs-1 ${activeLink === "patients" ? "text-black" : "text-white"}`}></i>
										</span>
                        </Link>
                    </div>
                    <div
                        data-kt-menu-placement="right-start"
                        className={`menu-item py-2`}>
                        <Link to={"/settings"}
                              className={`menu-link menu-center ${activeLink === "settings" ? "bg-white" : ""}`}>
										<span className="menu-icon me-0 ">
											<i className={`fa-solid fa-wrench fs-1 ${activeLink === "settings" ? "text-black" : "text-white"}`}></i>
										</span>
                        </Link>
                    </div>
                    <div
                        data-kt-menu-placement="right-start"
                        className={`menu-item py-2`}>
                        <Link to={"/additional-analyzes"}
                              className={`menu-link menu-center ${activeLink === "additional-analyzes" ? "bg-white" : ""}`}>
										<span className="menu-icon me-0 ">
											<i className={`fa-solid fa-file-signature fs-1 ${activeLink === "additional-analyzes" ? "text-black" : "text-white"}`}></i>
										</span>
                        </Link>
                    </div>
                    <div
                        data-kt-menu-placement="right-start"
                        className={`menu-item py-2`}>
                        <Link to={"/logs"}
                              className={`menu-link menu-center ${activeLink === "logs" ? "bg-white" : ""}`}>
										<span className="menu-icon me-0 ">
											<i className={`fa-solid fa-file-lines fs-1 ${activeLink === "logs" ? "text-black" : "text-white"}`}></i>
										</span>
                        </Link>
                    </div>
                </div>

            </div>

        </div>
        <div className="aside-footer flex-column-auto" id="kt_aside_footer"></div>
    </div>

}
