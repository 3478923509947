import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {formatMessage} from "../../helpers/methods";
import UserAPI from "../../api/UserAPI";
import {Helmet, HelmetProvider} from 'react-helmet-async';


const ForgotPassword = ({}) => {
    const helmetContext = {};
    const [passwordRequest, setPasswordRequest] = useState({email: ''})

    const [errors, setErrors] = useState([])

    const [loading, setLoading] = useState(false)

    const {t} = useTranslation();

    const onFormChange = (event) => {
        const field = event.target.name;
        const data = passwordRequest;
        data[field] = event.target.value;
        setPasswordRequest(data)
    }

    const onSend = (event) => {
        event.preventDefault()
        setLoading(true)
        return UserAPI.requestPasswordModification(passwordRequest)
            .then(response => {
                setLoading(false)
                if (response.status === 200) {
                    Swal.fire({
                        icon: 'success',
                        title: "Demande envoyée",
                        text: "Si cette adresse email existe, un lien de réinitialisation du mot de passe vous sera envoyé",
                        showCancelButton: false,
                        confirmButtonText: "Ok"
                    }).then(() => window.location.href = "/auth/login")

                } else {
                    if (response.data.errors) {
                        setErrors(response.data.errors)
                    }
                }
            })
    }
    return (<HelmetProvider context={helmetContext}>
            <div className="d-flex flex-column flex-lg-row flex-column-fluid">
                <Helmet>
                    <body className="bg-white"/>
                </Helmet>
                <div
                    className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
                    <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
                        <div className="left-container">
                            <h1 className="text-black fs-2tx text-center">Lyve Medical Network</h1>
                        </div>
                        <div className="w-lg-550px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
                            <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
                                  onSubmit={e => { e.preventDefault() }}>
                                <div className="text-center mb-10">
                                    <h1 className="text-dark mb-3"></h1>
                                    <div className="text-gray-400 fw-bold fs-4">Saisissez votre email pour réinitialiser
                                        votre mot de passe
                                    </div>
                                </div>
                                <div className="mb-10 fv-row fv-plugins-icon-container">
                                    <div className="mb-1">
                                        <label className="form-label fw-bolder text-dark fs-6">Adresse e-mail</label>
                                        <div className="position-relative mb-3">
                                            <input className="form-control form-control-lg form-control-solid"
                                                   type="email"
                                                   onChange={onFormChange}
                                                   placeholder="" name="email" autoComplete="off"/>
                                            {(errors?.filter(e => e.fieldName === "email").shift()?.message) &&
                                                <div
                                                    className="fv-plugins-message-container invalid-feedback">{t("errors:" + formatMessage(errors?.filter(e => e.fieldName === "email").shift()?.message))}</div>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="text-center">
                                    <button type="button" onClick={onSend}
                                            data-kt-indicator={loading ? "on" : "off"}
                                            disabled={loading}
                                            className="btn btn-lg btn-primary fw-bolder">
                                        <span className="indicator-label">{t("common:submit_button")}</span>
                                        <span className="indicator-progress">{t("common:waiting_button")}
                                            <span
                                                className="spinner-border spinner-border-sm align-middle ms-2"/></span>
                                    </button>
                                </div>
                                <div className="text-center mt-3">
                                    <a href="/auth/login" className="link-primary fw-bolder"> Retour sur la page de
                                        connexion</a></div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </HelmetProvider>
    )
}

export default ForgotPassword;
