import axios from "axios";
import {requestHeaders} from "../helpers/constants";

class SessionAPI {
    static login(credentials) {
        return axios({
            method: 'post',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/auth/login`,
            data: credentials
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }
    static findMe() {
        return axios({
            method: 'get',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/auth/me`
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }

    static refreshToken() {
        return axios({
            method: 'post',
            url: `${process.env.API_BASE_URL}/oauth2/refresh`,
            data: {refreshToken: localStorage.refreshToken, grantType: "refresh_token"}
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }

    static getTemporaryAccount(uuid) {
        return axios({
            method: 'get',
            url: `${process.env.API_BASE_URL}/oauth2/ta/${uuid}`
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }

    static register(user) {
        return axios({
            method: 'post',
            url: `${process.env.API_BASE_URL}/oauth2/register`,
            data: user
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }
}

export default SessionAPI;
