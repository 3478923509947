import React, {useEffect, useRef, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {wrapRequest} from "../../helpers/auth";
import RecordAPI from "../../api/RecordAPI";
import {RecordTable} from "./RecordTable";

const FilteredResultPage = ({profile, searchRequest}) => {

    const effectRan = useRef(false);
    const dispatch = useDispatch()
    const [records, setRecords] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [totalResults, setTotalResults] = useState(0);
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const onPageChange = ({target}) => {
        const page = target.value;
        navigate({
            pathname: `/records`,
            search: `&page=${page}`,
        });
        getRecords(page, 10);
    }
    const getRecords = (page, size, withLoading = true) => {
        if (withLoading) {
            setLoading(true)
        }
        return wrapRequest(dispatch, RecordAPI.getRecords(page, size, searchRequest))
            .then(response => {
                setLoading(false)
                if (response?.status === 200) {
                    setRecords(response.data.content)
                    setTotalPages(response.data.totalPages)
                    setCurrentPage(response.data.number)
                    setTotalResults(response.data.totalElements)
                }
            })
    }

    useEffect(() => {
        if (!effectRan.current) {
            getRecords(0, 10)
            const interval = setInterval(() => {
                getRecords(0, 10, false)
            }, 10000);
            return () => clearInterval(interval);
        }
        return () => effectRan.current = true;
    }, []);

    return <>
        <div className="card">

            <div className="card-header border-0 pt-6">
                <div className="card-title">Résultats en attente de validation ({totalResults})
                </div>

            </div>
            <RecordTable profile={profile}
                         currentPage={currentPage}
                         totalPages={totalPages}
                         records={records}
                         loading={loading}
                         onPageChange={onPageChange}
                         getRecords={getRecords}/>
        </div>
    </>


}
const mapStateToProps = state => ({
    profile: state.profile
});

export default connect(mapStateToProps, null)(FilteredResultPage);