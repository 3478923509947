import React from "react";
import {useTranslation} from "react-i18next";

const SupportDetails = ({support}) => {

    const {t} = useTranslation()
    return <>
        <div className="fs-6">
            <div className="d-flex flex-stack">
                <div>
                    <div className="fw-bold">Pris en charge par : </div>
                    <div className="text-gray-600">{support?.name}</div>
                    <div className="fw-bold mt-5">Date et heure : </div>
                    <div className="text-gray-600">{moment.utc(support?.date).local().format(t("common:dateTimeFormat"))}</div>
                    {support?.email && <div>
                        <div className="fw-bold mt-5">Email</div>
                        <div className="text-gray-600">{support?.email}</div>
                    </div>}
                </div>
                {support?.phoneNumber && <div>
                    <a href={`tel:${support?.phoneNumber}`}
                       className="btn btn-light-primary btn-sm">
                        <span className="svg-icon svg-icon-3"><i className="fa-solid fa-phone"></i></span>
                        Appeler le {support?.phoneNumber}</a></div>
                }
            </div>
        </div>
    </>
}
export default SupportDetails
