import axios from "axios";
import {requestHeaders} from "../helpers/constants";

class AddressAPI {

    static fetchAddressFromSearchAPI(requestAsString) {
        return axios({
            method: 'get',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/addresses?search=${requestAsString}`
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }
}

export default AddressAPI
