export const namespaces = [
    "common",
    "header",
    "login",
    "register",
    "errors",
    "menu",
    "logs",
    "users"
];
export const defaultNamespace = "common";

export const fallback = "fr";

export const supportedLocales = {
    fr: {
        name: "French",
        translationFileLoader: () => require('../assets/translations/fr.json')
    }
};

