import React, {useEffect} from 'react';
import {useSearchParams} from 'react-router-dom'
import {ACCESS_TOKEN, REFRESH_TOKEN} from "../../helpers/constants";

const OAuth2RedirectHandler = () => {

    const [searchParams] = useSearchParams()
    const accessToken = searchParams.get("access_token")
    const refreshToken = searchParams.get("refresh_token");
    const error = searchParams.get("error");
    const from = searchParams.get("from");

    useEffect(() => {
        if (accessToken) {
            localStorage.setItem(ACCESS_TOKEN, accessToken);
            localStorage.setItem(REFRESH_TOKEN, refreshToken);
            console.log(accessToken + " " + refreshToken)
            window.location.href = from === null ? "/" : from
        } else {
            window.location.href = `?login=${error}`
        }
    })
    return <div/>
}

export default OAuth2RedirectHandler;
