import React from "react"
import {connect} from "react-redux";
import {MobileMenu as SuperAdminMobileMenu} from "./superAdmin/MobileMenu";
import {MobileMenu as AdminMobileMenu} from "./admin/MobileMenu";
import {MobileMenu as DoctorMobileMenu} from "./doctor/MobileMenu";
import {MobileMenu as UserMobileMenu} from "./user/MobileMenu";

const MobileSideMenu = ({open, wrapperRef, profile, activeLink}) => {

    const getMenu = (profile) => {
        if (profile?.roles?.includes("ROLE_SUPER_ADMIN")) {
            return <SuperAdminMobileMenu activeLink={activeLink} wrapperRef={wrapperRef} open={open}/>
        }
        if (profile?.roles?.includes("ROLE_ADMIN") || profile?.roles?.includes("ROLE_MANAGER")) {
            return <AdminMobileMenu activeLink={activeLink} wrapperRef={wrapperRef} open={open}/>
        }
        if (profile?.roles?.includes("ROLE_DOCTOR")) {
            return <DoctorMobileMenu activeLink={activeLink} wrapperRef={wrapperRef} open={open}/>
        }
        if (profile?.roles?.includes("ROLE_USER")) {
            return <UserMobileMenu activeLink={activeLink} wrapperRef={wrapperRef} open={open}/>
        }
    }
    return getMenu(profile)
}
const mapStateToProps = state => ({
    profile: state.profile
});

export default connect(mapStateToProps, null)(MobileSideMenu);
