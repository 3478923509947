export const LOG_IN_SUCCESS = "LOG_IN_SUCCESS"
export const LOG_IN_BAD_CREDENTIALS = "LOG_IN_BAD_CREDENTIALS"
export const LOG_IN_VALIDATION_ERROR = "LOG_IN_VALIDATION_ERROR"
export const LOG_OUT = "LOG_OUT"
export const LOAD_ACCOUNT_SUCCESS = "LOAD_ACCOUNT_SUCCESS"
export const STOP_LOADING = "STOP_LOADING"
export const START_LOADING = "START_LOADING"
export const RESET_ERRORS = "RESET_ERRORS"




