import React, {useEffect, useState} from "react";
import {wrapRequest} from "../../helpers/auth";
import RecommendationAPI from "../../api/RecommendationAPI";
import {useDispatch} from "react-redux";
import NotificationAlert from "../common/ui/NotificationAlert";
import Markdown from "react-markdown";

const RecommendationView = ({alertId}) => {
    const [currentTab, setCurrentTab] = useState("ETIOLOGY");
    const [recommendations, setRecommendations] = useState([])
    const [selectedRecommendation, setSelectedRecommendation] = useState({})
    const dispatch = useDispatch()
    const getCurrentTab = () => {
        switch (currentTab) {
            case "SEMIOLOGY":
                return selectedRecommendation.semiologyText ? <div className="mt-15">
                        <p><Markdown>{selectedRecommendation.semiologyText}</Markdown></p>
                    </div>
                    :
                    <div className="dataTables_wrapper dt-bootstrap4 no-footer mt-8 mb-8">
                        <NotificationAlert
                            title={`Aucune recommandation pour cette analyse dans cette catégorie`}
                            message={"Veuillez configurer une alerte et ajouter des recommandations"}
                        /></div>
            case "MEDICAL_CARE":
                return selectedRecommendation.medicalCareText ? <div className="mt-15">
                        <p><Markdown>{selectedRecommendation.medicalCareText}</Markdown></p>
                    </div> :
                    <div className="dataTables_wrapper dt-bootstrap4 no-footer mt-8 mb-8">
                        <NotificationAlert
                            title={`Aucune recommandation pour cette analyse dans cette catégorie`}
                            message={"Veuillez configurer une alerte et ajouter des recommandations"}
                        /></div>
            case "EXPLORATION":
                return selectedRecommendation.explorationText ? <div className="mt-15">
                        <p><Markdown>{selectedRecommendation.explorationText}</Markdown></p>
                    </div> :
                    <div className="dataTables_wrapper dt-bootstrap4 no-footer mt-8 mb-8">
                        <NotificationAlert
                            title={`Aucune recommandation pour cette analyse dans cette catégorie`}
                            message={"Veuillez configurer une alerte et ajouter des recommandations"}
                        /></div>
            case "ETIOLOGY":
            default:
                return selectedRecommendation.etiologyText ? <div className="mt-15">
                        <p><Markdown>{selectedRecommendation.etiologyText}</Markdown></p>
                    </div> :
                    <div className="dataTables_wrapper dt-bootstrap4 no-footer mt-8 mb-8">
                        <NotificationAlert
                            title={`Aucune recommandation pour cette analyse dans cette catégorie`}
                            message={"Veuillez configurer une alerte et ajouter des recommandations"}
                        /></div>
        }
    }

    const selectTab = (value) => {
        setCurrentTab(value)
    }


    useEffect(() => {
        if (alertId) {
            wrapRequest(dispatch, RecommendationAPI.getRecommendations(0, 100, alertId))
                .then(response => {
                    if (response.status === 200) {
                        setRecommendations(response.data.content)
                        if (response.data.content.length > 0) {
                            setSelectedRecommendation(response.data.content[0])
                        }

                    }
                })
        }
    }, [alertId]);
    return recommendations.length > 0 ? <>
        {recommendations.map((recommendation, index) => <span key={index}
                                                              onClick={() => setSelectedRecommendation(recommendation)}
                                                              className={`cursor-pointer badge py-3 px-4 fs-7 badge-${recommendation.id === selectedRecommendation.id ? "" : "light-"}primary me-3`}>{recommendation.title}</span>)}
            <div className="card card-bordered mt-10">
                <div className="card-body pt-0 pb-0">
                    <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
                        <li className="nav-item mt-2 cursor-pointer">
                            <a className={`nav-link text-active-primary ms-0 me-10 py-5 ${currentTab === "ETIOLOGY" ? "active" : ""}`}
                               onClick={e => selectTab("ETIOLOGY")}
                            >Etiologie</a>
                        </li>
                        <li className="nav-item mt-2 cursor-pointer">
                            <a className={`nav-link text-active-primary ms-0 me-10 py-5 ${currentTab === "SEMIOLOGY" ? "active" : ""}`}
                               onClick={e => selectTab("SEMIOLOGY")}
                            >Sémiologie</a>
                        </li>
                        <li className="nav-item mt-2 cursor-pointer">
                            <a className={`nav-link text-active-primary ms-0 me-10 py-5 ${currentTab === "MEDICAL_CARE" ? "active" : ""}`}
                               onClick={e => selectTab("MEDICAL_CARE")}
                            >Prise en charge</a>
                        </li>
                        <li className="nav-item mt-2 cursor-pointer">
                            <a className={`nav-link text-active-primary ms-0 me-10 py-5 ${currentTab === "EXPLORATION" ? "active" : ""}`}
                               onClick={e => selectTab("EXPLORATION")}
                            >Exploration</a>
                        </li>
                    </ul>
                    {getCurrentTab()}
                </div>
            </div>
    </> : <div className="dataTables_wrapper dt-bootstrap4 no-footer mt-8">
        <NotificationAlert
            title={`Aucune recommandation pour cette analyse`}
            message={"Veuillez configurer une alerte et ajouter des recommandations"}
        /></div>
}

export default RecommendationView
