import {LoadableComponent} from "../common/ui/LoadableComponent";
import NotificationAlert from "../common/ui/NotificationAlert";
import {ResultCell} from "./ResultCell";
import LaboratoryCell from "./LaboratoryCell";
import {PatientCell} from "./PatientCell";
import {DoctorCell} from "./DoctorCell";
import Pagination from "@vlsergey/react-bootstrap-pagination";
import React, {useEffect, useRef} from "react";
import {useTranslation} from "react-i18next";
import moment from "moment";

export const RecordTable = ({loading, records, profile, onPageChange, currentPage, totalPages, getRecords, currentTab}) => {
    const {t} = useTranslation()
    const effectRan = useRef(false);
    useEffect(()=> {
        if (!effectRan.current) {
            getRecords(currentPage, 10)
            const interval = setInterval(() => {
                getRecords(currentPage, 10, false)
            }, 10000);
            return () => clearInterval(interval);
        }
        return () => effectRan.current = true;
    }, [currentTab, currentPage])


    return <div className="card-body pt-0">
        <div id="kt_customers_table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer mt-8">
            <LoadableComponent loading={loading} component={<>{records?.length === 0 ? <NotificationAlert
                    title={"Aucun résultat trouvé"}
                    message={"Veuillez patienter pour l'obtention des résultats"}
                /> :
                <div className="table-responsive">
                    <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
                        <thead className="border-gray-200 fs-5 fw-semibold bg-lighten">
                        <tr>
                            <th className="min-w-375px sorting">Résultats</th>
                            <th className="min-w-80px sorting">Date</th>
                            <th className="min-w-100px sorting"><i
                                className="fa-solid fa-flask"></i> Laboratoire -
                                Numéro de dossier
                            </th>
                            <th className="min-w-125px sorting"><i
                                className="fa-solid fa-hospital-user"></i> Patient
                            </th>
                            <th className="min-w-125px sorting"><i
                                className="fa-solid fa-user-doctor"></i> Prescripteur
                            </th>
                        </tr>
                        </thead>
                        <tbody className="fw-semibold text-gray-600">
                        {records?.map((record, idx) => <tr key={idx}
                                                           className={idx % 2 === 0 ? "even" : "odd"}>
                                <ResultCell sourceRecord={record} profile={profile}
                                            refresh={() => getRecords(0, 10)}/>
                                <td>{moment.utc(record?.date).local().format(t("common:dateFormat"))}</td>
                                <LaboratoryCell profile={profile} record={record}/>
                                <PatientCell patient={record?.patient} record={record} getRecords={getRecords}/>
                                <DoctorCell doctor={record?.doctor} record={record}/>
                            </tr>
                        )}
                        </tbody>
                    </table>

                </div>}
            </>}/>
        </div>
        {records?.length > 0 && <div className="mt-3">
            <Pagination
                aroundCurrent={2}
                showFirstLast={true}
                showPrevNext={true}
                atBeginEnd={0}
                onChange={onPageChange}
                value={currentPage}
                totalPages={totalPages}
            />
        </div>}
    </div>
}
