import {ACCESS_TOKEN, REFRESH_TOKEN} from "./constants";
import {loginSuccess, logout} from "../redux/actions/sessionActions";
import axios from "axios";
import SessionAPI from "../api/SessionAPI";

export function loggedIn() {
    return !!localStorage.accessToken;
}

export function logOut() {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    window.location.reload()
}

export function wrapRequest(dispatch, call) {
    return call.then(response => {
        if (!response) {
            dispatch(logout())
        }
        if (response?.status <= 400
            || response?.status >= 403) {
            return response
        }
        const originalRequest = response?.config;
        if (!originalRequest._retry && localStorage.refreshToken) {
            originalRequest._retry = true;
            return SessionAPI.refreshToken().then(resp => {
                    if (resp.status === 200) {
                        dispatch(loginSuccess({
                            accessToken: resp.data.accessToken,
                            refreshToken: resp.data.refreshToken
                        }))
                        return axios({
                            method: originalRequest.method,
                            headers: {...originalRequest.headers, 'Authorization': `Bearer ${resp.data.accessToken}`},
                            url: originalRequest.url,
                            data: originalRequest.data
                        })
                    } else {
                        dispatch(logout())
                    }
                }
            )
        } else {
            dispatch(logout())
        }
    })
}
