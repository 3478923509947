import axios from "axios";
import {requestHeaders} from "../helpers/constants";

class PatientAPI {

    static create(patient) {
        return axios({
            method: 'post',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/physical-patients`,
            data: patient
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }

    static update(patient, id) {
        return axios({
            method: 'put',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/physical-patients/${id}`,
            data: patient
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }

    static get(id) {
        return axios({
            method: 'get',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/physical-patients/${id}`
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }

    static delete(id) {
        return axios({
            method: 'delete',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/physical-patients/${id}`
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }

    static getPatients(page, size, search) {
        let url = `${process.env.API_BASE_URL}/physical-patients?page=${page}&size=${size}`
        if (search) {
            url += `&search=${search}`
        }
        return axios({
            method: 'get',
            headers: requestHeaders(),
            url: url,
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }
}

export default PatientAPI
