import React from "react"
export const Footer = () => {
    return <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
        <div className="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between">
            <div className="text-dark order-2 order-md-1">
                <span className="text-muted fw-semibold me-1">2024©</span>
                <a href="https://lyvemedicalnetwork.com" target="_blank" className="text-gray-800 text-hover-primary">Lyve Medical Network</a>
            </div>
        </div>
    </div>
}
