import React from "react";
import {BrowserRouter, Outlet, Route, Routes} from "react-router-dom";
import Login from "./components/authentication/Login";
import {loggedIn} from "./helpers/auth";
import RedirectLogin from "./components/navigation/RedirectLogin";
import {Home} from "./components/Home";
import {PrivateRoute} from "./components/navigation/PrivateRoute";
import './i18n/i18n';
import {LogActions} from "./components/logActions/LogActions";
import Organizations from "./components/organizations/Organizations";
import {Doctors} from "./components/doctors/Doctors";
import OrganizationDetails from "./components/organizations/OrganizationDetails";
import Records from "./components/records/Records";
import ForgotPassword from "./components/authentication/ForgotPassword";
import ResetPassword from "./components/authentication/ResetPassword";
import SelfOrganization from "./components/organizations/SelfOrganization";
import LaboratoryDetails from "./components/laboratories/LaboratoryDetails";
import DoctorAssociationDetails from "./components/doctors/DoctorAssociationDetails";
import DoctorDetails from "./components/doctors/DoctorDetails";
import UserDetails from "./components/users/UserDetails";
import AlertConfigurationDetails from "./components/alertConfigurations/AlertConfigurationDetails";
import OAuth2RedirectHandler from "./components/authentication/OAuth2RedirectHandler";
import InitDoctorAccount from "./components/authentication/InitDoctorAccount";
import Validations from "./components/records/Validations";
import Alerts from "./components/records/Alerts";
import {Patients} from "./components/patients/Patients";
import PatientDetails from "./components/patients/PatientDetails";
import ConfigurationWrapper from "./components/configuration/ConfigurationWrapper";
import AdditionalAnalyzes from "./components/additionalAnalyzes/AdditionalAnalyzes";

export const App = () => {

    function PrivateOutlet() {
        if (loggedIn()) {
            return <Outlet/>
        } else {
            return <RedirectLogin/>
        }
    }

    function PublicOutlet() {
        return loggedIn() ? window.location.href = "/" : <Outlet/>;
    }

    return <BrowserRouter>
        <Routes>
            <Route exact path='/' element={<PrivateOutlet/>}>
                <Route exact path='/'
                       element={<PrivateRoute title="dashboard"
                                              activeLink={"results"}
                                              component={Home}/>}/>
                <Route exact path='/settings'
                       element={<PrivateRoute title="configuration"
                                              activeLink={"settings"}
                                              component={ConfigurationWrapper}/>}/>
                <Route exact path='/organizations'
                       element={<PrivateRoute title="organizations"
                                              activeLink={"organizations"}
                                              component={Organizations}/>}/>
                <Route exact path='/organizations/:id'
                       element={<PrivateRoute title="organizations"
                                              activeLink={"organizations"}
                                              component={OrganizationDetails}/>}/>
                <Route exact path='/organizations/:organizationId/laboratories/:id'
                       element={<PrivateRoute title="contacts"
                                              activeLink={"organizations"}
                                              component={LaboratoryDetails}/>}/>
                <Route exact path='/organizations/:organizationId/associations/:id'
                       element={<PrivateRoute title="contacts"
                                              activeLink={"organizations"}
                                              component={DoctorAssociationDetails}/>}/>
                <Route exact path='/organizations/:organizationId/users/:id'
                       element={<PrivateRoute title="contacts"
                                              activeLink={"organizations"}
                                              component={UserDetails}/>}/>
                <Route exact path='/organizations/:organizationId/alert-configurations/:id'
                       element={<PrivateRoute title="contacts"
                                              activeLink={"organizations"}
                                              component={AlertConfigurationDetails}/>}/>
                <Route exact path='/settings/alert-configurations/:id'
                       element={<PrivateRoute title="contacts"
                                              activeLink={"organizations"}
                                              component={AlertConfigurationDetails}/>}/>
                <Route exact path='/global-configuration'
                       element={<PrivateRoute title="organizations"
                                              activeLink={"settings"}
                                              component={SelfOrganization}/>}/>
                <Route exact path='/doctors'
                       element={<PrivateRoute title="doctors"
                                              activeLink={"doctors"}
                                              component={Doctors}/>}/>
                <Route exact path='/doctors/:id'
                       element={<PrivateRoute title="doctors"
                                              activeLink={"doctors"}
                                              component={DoctorDetails}/>}/>
                <Route exact path='/validations'
                       element={<PrivateRoute title="validations"
                                              activeLink={"validations"}
                                              component={Validations}/>}/>
                <Route exact path='/records'
                       element={<PrivateRoute title="records"
                                              activeLink={"records"}
                                              component={Records}/>}/>
                <Route exact path='/alerts'
                       element={<PrivateRoute title="alerts"
                                              activeLink={"alerts"}
                                              component={Alerts}/>}/>
                <Route exact path='/additional-analyzes'
                       element={<PrivateRoute title="additional-analyzes"
                                              activeLink={"additional-analyzes"}
                                              component={AdditionalAnalyzes}/>}/>
                <Route exact path='/logs'
                       element={<PrivateRoute title="logs"
                                              activeLink={"logs"}
                                              component={LogActions}/>}/>
                <Route exact path='/patients'
                       element={<PrivateRoute title="patients"
                                              activeLink={"patients"}
                                              component={Patients}/>}/>
                <Route exact path='/patients/:id'
                       element={<PrivateRoute title="patients"
                                              activeLink={"patients"}
                                              component={PatientDetails}/>}/>
            </Route>
            <Route exact path='/auth' element={<PublicOutlet/>}>
                <Route path={"/auth/login"} element={<Login/>}/>
                <Route path={"/auth/forgot-pwd"} element={<ForgotPassword/>}/>
                <Route path={"/auth/reset-pwd"} element={<ResetPassword/>}/>
                <Route path={"/auth/init-account"} element={<ResetPassword/>}/>
                <Route path="/auth/oauth2">
                    <Route exact path="/auth/oauth2/redirect" element={<OAuth2RedirectHandler/>}/>
                    <Route exact path="/auth/oauth2/register" element={<InitDoctorAccount/>}/>
                </Route>
            </Route>
        </Routes>
    </BrowserRouter>


}
