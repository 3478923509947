import React from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

export const LargeMenu = ({activeLink}) => {
    const {t} = useTranslation("menu")
    return <div id="kt_aside" className="aside pb-5 pt-5 pt-lg-0">
        <div className="aside-logo py-8" id="kt_aside_logo">
            <a href="#" className="d-flex text-center text-white fs-1">
                Lyve Medical Network
            </a>
        </div>
        <div className="aside-menu flex-column-fluid" id="kt_aside_menu">
            <div className="hover-scroll-overlay-y my-2 my-lg-5 pe-lg-n1" id="kt_aside_menu_wrapper"
                 data-kt-scroll="true" data-kt-scroll-height="auto"
                 data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
                 data-kt-scroll-wrappers="#kt_aside, #kt_aside_menu" data-kt-scroll-offset="5px"
                 style={{height: "692px"}}>
                <div
                    className="menu menu-column menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500 fw-semibold"
                >
                    <div data-kt-menu-placement="right-start"
                         className={`menu-item py-2 ${activeLink === "validations" ? "bg-white" : "here"}`}>
                        <Link to={"/validations"} className="menu-link menu-center">
										<span className="menu-icon me-0">
											<i className="fa-solid fa-clipboard-check"></i>
										</span>
                            <span
                                className={`menu-title ${activeLink === "validations" ? "text-black" : ""}`}>{t("validations")}</span>
                        </Link>
                    </div>
                    <div data-kt-menu-placement="right-start"
                         className={`menu-item py-2 ${activeLink === "records" ? "bg-white" : "here"}`}>
                        <Link to={"/records"} className="menu-link menu-center">
										<span className="menu-icon me-0">
											<i className="fa-solid fa-file-waveform"></i>
										</span>
                            <span
                                className={`menu-title ${activeLink === "records" ? "text-black" : ""}`}>{t("results")}</span>
                        </Link>
                    </div>
                    <div data-kt-menu-placement="right-start"
                         className={`menu-item py-2 ${activeLink === "settings" ? "bg-white" : "here"}`}>
                        <Link to={"/global-configuration"} className="menu-link menu-center">
										<span className="menu-icon me-0">
											<i className="fa-solid fa-wrench"></i>
										</span>
                            <span
                                className={`menu-title ${activeLink === "settings" ? "text-black" : ""}`}>{t("settings")}</span>
                        </Link></div>
                    <div data-kt-menu-placement="right-start"
                         className={`menu-item py-2 ${activeLink === "additional-analyzes" ? "bg-white" : "here"}`}>
                        <Link to={"/additional-analyzes"} className="menu-link menu-center">
										<span className="menu-icon me-0">
											<i className="fa-solid fa-file-signature"></i>
										</span>
                            <span
                                className={`menu-title ${activeLink === "additional-analyzes" ? "text-black" : ""}`}>{t("additional-analyzes")}</span>
                        </Link>
                    </div>
                    <div data-kt-menu-placement="right-start"
                         className={`menu-item py-2 ${activeLink === "logs" ? "bg-white" : "here"}`}>
                        <Link to={"/logs"} className="menu-link menu-center">
										<span className="menu-icon me-0">
											<i className="fa-solid fa-file-lines"></i>
										</span>
                            <span
                                className={`menu-title ${activeLink === "logs" ? "text-black" : ""}`}>{t("logs")}</span>
                        </Link>
                    </div>
                </div>

            </div>
        </div>
        <div className="aside-footer flex-column-auto" id="kt_aside_footer"/>
    </div>
}
