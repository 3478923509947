import axios from "axios";
import {requestHeaders} from "../helpers/constants";

class RecommandationAPI {
    static create(alertConfigurationId, contact) {
        return axios({
            method: 'post',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/alert-configurations/${alertConfigurationId}/recommendations`,
            data: contact
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }

    static update(alertConfigurationId, id, contact) {
        return axios({
            method: 'put',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/alert-configurations/${alertConfigurationId}/recommendations/${id}`,
            data: contact
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }

    static remove(alertConfigurationId, id) {
        return axios({
            method: 'delete',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/alert-configurations/${alertConfigurationId}/recommendations/${id}`
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }

    static get(alertConfigurationId, id) {
        return axios({
            method: 'get',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/alert-configurations/${alertConfigurationId}/recommendations/${id}`
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }

    static getRecommendations(page, size, alertConfigurationId) {
        let url = `${process.env.API_BASE_URL}/alert-configurations/${alertConfigurationId}/recommendations?page=${page}&size=${size}`
        return axios({
            method: 'get',
            headers: requestHeaders(),
            url: url,
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }
}

export default RecommandationAPI
