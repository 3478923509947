import axios from "axios";
import {requestHeaders} from "../helpers/constants";

class AcquisitionConnectorAPI {

    static create(connector) {
        return axios({
            method: 'post',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/acquisitions`,
            data: connector
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }

    static remove(id) {
        return axios({
            method: 'delete',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/acquisitions/${id}`
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }
    static getAll(organizationId, page, size) {
        return axios({
            method: 'get',
            headers: requestHeaders(),
            url: `${process.env.API_BASE_URL}/acquisitions?page=${page}&size=${size}&organizationId=${organizationId}`
        }).then(function (response) {
            return response
        }).catch(error => {
            return error.response;
        });
    }
}
export default AcquisitionConnectorAPI
